import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Row, Modal, Col, } from 'react-bootstrap'

import Container from 'reactstrap/lib/Container'
import ReactDatePicker from 'react-datepicker'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import './dashboard.css'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { isMobile } from 'mobile-device-detect';
import { HiRefresh } from 'react-icons/hi'
import { Table } from 'antd';
import { Select } from 'antd'
import Footer from '../footer/Footer'
const { Option } = Select;

const columnsSurvey = [
    {
        title: 'Name',
        dataIndex: "name",
    },
    {
        title: 'Place',
        dataIndex: "place",
    },

    {
        title: 'Date',
        dataIndex: "created_datetime",
        render: ( _, rec ) => {
            return  <p>{moment(rec.created_datetime).format("DD/MM/YYYY")}</p>
          }
    },
    {
        title: 'River',
        dataIndex: "river",
    },
    {
        title: 'River Type',
        dataIndex: "riverType",
    },


];


const SurveysPage = ( props ) => {
    const [supplier, setSuppliers] = useState( [] )
    const [supplierFull, setSuppliersFull] = useState( [] )
    const [modalShow, setModalShow] = useState( false );
    const [survey, setSurvey] = useState( [] )
    const [selectedData, setSelectedData] = useState( [] )

    const [dataa, setDataa] = useState( {} )
    const [search_date, setSearch_date] = useState( new Date() )
    const [filteredData, setFilteredData] = useState( [] )
    const [search_dateSurvey, setSearch_dateSurvey] = useState( new Date() )
    const [filteredDataSurvey, setFilteredDataSurvey] = useState( [] )
    
    const [printQuestion, setPrintQuestion] = useState( [{
        questionId: "Q1a",
        question: "What direct human disturbance are you aware of or have you seen in the upstream landscape?",
        answers: [],
    }, {
        questionId: "Q1b",
        question: "What direct human disturbance can you see at this section of the waterway?",
        answers: [],
    },
    {
        questionId: "Q1c",
        question: "What changes to the shape of the river can you see here?",
        answers: [],
    },
    {
        questionId: "Q1d",
        question: "What changes to the flow of water can you see here?",
        answers: [],
    }
        ,
    {
        questionId: "Q1e",
        question: "Describe the water quality. What can you see or smell?",
        answers: [],
    }
        ,
    {
        questionId: "Q1f",
        question: "What native vegetation can you see here/what condition is it in?",
        answers: [],
    }
        ,
    {
        questionId: "Q1g",
        question: "What introduced vegetation or weeds can you see here?",
        answers: [],
    }
        ,
    {
        questionId: "Q1h",
        question: "What habitats for native animals and birds can you see here?",
        answers: [],
    }
        ,
    {
        questionId: "Q1i",
        question: "What habitats for waterbirds can you see here?",
        answers: [],
    },
    {
        questionId: "Q1j",
        question: "What native fish would you expect to find here?",
        answers: [],
    },
    {
        questionId: "Q1k",
        question: "What exotic, non-native fish would you expect to fine here?",
        answers: [],
    },
    {
        questionId: "Q3a",
        question: "Overall, does the current waterway health at this place support the values and uses that you want to see here?",
        answers: [],
    },
    {
        questionId: "Q3b",
        question: "Overall, having spent time at this place today, what is your feeling about the waterway here?",
        answers: [],
    },
    {
        questionId: "Q3c",
        question: "Why is this place important to you and/or your community?",
        answers: [],
    },
    {
        questionId: "Q3d",
        question: "How does this place benefit you and/or your community?",
        answers: [],
    },
    {
        questionId: "Q3e",
        question: "Is this place easily accessible to you and/or your community?",
        answers: [],
    },
    {
        questionId: "Q3f",
        question: "What should this place look like?",
        answers: [],
    },
    {
        questionId: "Q3g",
        question: "What do you want to see happen at this place to ensure that it stays/becomes healthy?",
        answers: [],
    }
    ] )


    const componentRef1 = useRef()
    let [locations, setLocations] = useState( [] )
    const [location, setLocation] = useState( "" )
    const [temp, setTempSurvey] = useState( [] )
    const [scroll, setScroll] = useState( {} )
    const getLocations = () => {
        Axios.get( `${ process.env.REACT_APP_API_BASE }/location` ).then( res => {
            console.log( "body===>", res )
            if ( res.data ) {
                setLocations( res.data.body.map( rec => rec.code ) )
            }

        } )
    }

    const handleClick = ( e ) => {
        supplierFull.filter( rec => {


        } )
    }
    function MyVerticallyCenteredModal( props ) {
        console.log( "props", props )
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
                Image Preview
              </Modal.Title>
            </Modal.Header> */}
                <Modal.Body>
                    <div className="card" style={{ width: "100%", padding: "5%" }}>
                        <div className="wiki-list-modal">
                            <Row><h1 className="card-title">{props.title}</h1></Row>
                            <Row>
                                <table>
                                    <tr>
                                        <th>
                                            Question
                                        </th>
                                        <th>
                                            Answer
                                        </th>
                                    </tr>
                                    {props.selectedData.map( rec => {

                                        return <tr style={{ border: "1px solid grey !important" }}> <td style={{ border: "1px solid grey !important" }} className="modal-td">{rec.question}</td><td className="modal-td">{rec.ans}</td></tr>
                                    } )}
                                </table>
                                {/* <button className="wiki-item" >See More</button> */}
                            </Row>
                        </div>


                    </div>
                </Modal.Body >
            </Modal >
        );
    }

    const getSuverys = () => {

        Axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/survey",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                lengthOrder.map( rec => {
                    Array.isArray( rec.questions ) && rec.questions.map( recOne => {
                        for ( let i in printQuestion ) {
                            console.log( "printQuestion", printQuestion[i].question == recOne.question )
                            if ( printQuestion[i].question == recOne.question ) {
                                if ( !recOne.ans == "" ) {
                                    printQuestion[i].answers.push( recOne.ans )
                                }
                            }
                        }
                        return rec

                    }
                    )
                    return rec
                } )
                setPrintQuestion( [...printQuestion] )

                setSurvey( lengthOrder )
                setTempSurvey( lengthOrder )
                setFilteredDataSurvey( lengthOrder )

                //   },
            } )

    }


    useEffect( () => {

        getSuverys()
        if ( isMobile ) {
            setScroll({
                x:1200
            })
          
        }
        getLocations()
    }, [] )




    useEffect( () => {
        setLocations( locations )

    }, [locations] )
    const handleChangeSelectAWA = ( e ) => {
        for ( let i in printQuestion ) {
            printQuestion[i].answers = []
        }
        temp.filter( rec => rec.projectCode == e.target.value ).map( rec => {
            Array.isArray( rec.questions ) && rec.questions.map( recOne => {
                for ( let i in printQuestion ) {
                    console.log( "printQuestion   4", printQuestion[i].question == recOne.question )
                    if ( printQuestion[i].question == recOne.question ) {
                        if ( !recOne.ans == "" ) {
                            printQuestion[i].answers.push( recOne.ans )
                        }
                    }
                }
                return rec

            }
            )
            return rec
        } )
        setPrintQuestion( [...printQuestion] )
        setLocation( e.target.value )
        setFilteredDataSurvey( temp.filter( rec => rec.projectCode == e.target.value ) )
    }
    console.log( "Location,", locations )
    return (
        <>
        <Container style={{ background: "rgb(50, 50, 50)", marginTop: "20px",minHeight:"100vh" }}>
            {selectedData && selectedData.length ? <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow( false )}
                title={"Survey Data"}
                selectedData={selectedData}

            /> : null}
            <Container>


                <h1 style={{ color: "white" }}>Surveys</h1>
                <div style={{ marginBottom: "30px" }}>
                <Row>
                        <Col md="3" sm="12" className="d-1">
                    <div style={{ display: "inline-block" }}  className="date-picker-text-01">
                        {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                        <ReactDatePicker
                            selected={search_dateSurvey}
                            onChange={( date ) => {
                                console.log( "supplier,", survey.filter( rec => moment( new Date( rec.created_datetime ) ).format( "DD/MM/yyyy" ) == moment( new Date( date ) ).format( "DD/MM/yyyy" ) ) )
                                for ( let i in printQuestion ) {
                                    printQuestion[i].answers = []
                                }
                                survey.filter( rec => moment( new Date( rec.created_datetime ) ).format( "DD/MM/yyyy" ) == moment( new Date( date ) ).format( "DD/MM/yyyy" ) ).map( rec => {
                                    Array.isArray( rec.questions ) && rec.questions.map( recOne => {
                                        for ( let i in printQuestion ) {
                                            console.log( "printQuestion   4", printQuestion[i].question == recOne.question )
                                            if ( printQuestion[i].question == recOne.question ) {
                                                if ( !recOne.ans == "" ) {
                                                    printQuestion[i].answers.push( recOne.ans )
                                                }
                                            }
                                        }
                                        return rec

                                    }
                                    )
                                    return rec
                                } )

                                const childrenOne1 = [];
                                let loc = survey.filter( rec => moment( new Date( rec.created_datetime ) ).format( "DD/MM/yyyy" ) == moment( new Date( date ) ).format( "DD/MM/yyyy" ) )
                                for ( let i = 0; i < loc.length; i++ ) {
                                    console.log( "loc[i].projectCode", loc[i].projectCode )
                                    if ( !childrenOne1.find( rec => rec == loc[i].projectCode ) ) {
                                        if ( loc[i].projectCode != undefined ) {
                                            childrenOne1.push( loc[i].projectCode )
                                            // children.push( <Option key={datauser[i].eventTitle}>{datauser[i].eventTitle}</Option> );
                                        }
                                    }
                                }
                                console.log( "loc[i].projectCode 1", childrenOne1 )
                                locations = []
                                setLocations( [...childrenOne1] )
                                setPrintQuestion( [...printQuestion] )
                                setFilteredDataSurvey( survey.filter( rec => moment( new Date( rec.created_datetime ) ).format( "DD/MM/yyyy" ) == moment( new Date( date ) ).format( "DD/MM/yyyy" ) ) )
                                setTempSurvey( survey.filter( rec => moment( new Date( rec.created_datetime ) ).format( "DD/MM/yyyy" ) == moment( new Date( date ) ).format( "DD/MM/yyyy" ) ) )
                                setSearch_dateSurvey( date )

                            }}

                            dateFormat="Pp"
                            dateFormat="dd/MM/yyyy"
                        />

                    </div>
                    </Col>
                    <Col md="6" sm="12" className="d-1">
                    <div style={{ display: "inline-block" }}  className="date-picker-text-02">
                        <select
                            classname="form-control"
                            value={location}
                            style={{
                                display: "block",
                                width: "100%",
                                backgroundColor: "#E6E6E6",
                                height: "50px",
                                borderRadius: "5px",
                                color: "black",
                                fontFamily: "open sans",
                                fontSize: "14px",
                                borderRadius: "32px",
                            }}
                            onChange={( e ) => {
                                console.log( "evet 2", e )
                                handleChangeSelectAWA( e )
                            }}>
                            <option value="Select Location">Select Location</option>
                            {
                                locations.length ? locations.map( rec => {
                                    return <option value={rec}>{rec}</option>
                                } ) : null
                            }
                        </select></div>
                    <div style={{ display: "inline-block" }}>
                        <HiRefresh style={{ color: "white", fontSize: "30px", fill: "#4D6646" }} onClick={() => {
                            setFilteredDataSurvey( survey )
                            setSearch_dateSurvey( new Date() )
                            setTempSurvey( survey )
                            setLocation( "Select Location" )
                        }} /></div>
                        </Col>
                    <Col md="3" sm="12" className="d-1">
                    <div style={{ display: "inline-block", float: "right" }}>
                        <div ref={componentRef1} className='print-screen'>
                            {Array.isArray( printQuestion ) ? printQuestion.map( rec => {
                                return <div>
                                    <p style={{ fontSize: "20px", fontFamily: "open sans" }}>{rec.question}</p>
                                    <ul>
                                        {rec.answers.map( recOne => {
                                            return <li>{recOne}</li>
                                        } )}
                                    </ul>
                                </div>
                            } ) : null}


                        </div>
                        <ReactToPrint

                            trigger={() => <button size={36} style={{
                                float: "right", cursor: "pointer", color: "white",
                                borderRadius: "32px", fontFamily: "open sans", width: "165px"
                            }} >Export</button>}
                            content={() => componentRef1.current}

                            pageStyle={{ background: "" }}
                        />

                    </div>
                    </Col>
                    </Row>
                </div>

                <Table
                    columns={columnsSurvey}
                    dataSource={filteredDataSurvey}
                    // pagination
                    scroll={scroll}
                    onRow={( record, inde ) => {
                        return {
                            onClick: event => {
                                setSelectedData( record.questions )
                                setModalShow( true )
                                // console.log( "e", record )
                            },
                        }

                    }}

                />


            </Container>

           

        </Container>
        <Footer />
        </>
    )
}

export default SurveysPage