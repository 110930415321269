import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button, Form, Input } from 'reactstrap';
import EmailValidator from 'email-validator';
import './user.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Axios from 'axios';

import { Table } from 'antd'
import { Modal } from 'react-bootstrap';
import TextArea from 'antd/lib/input/TextArea';
import { isMobile } from 'mobile-device-detect';
import Footer from '../footer/Footer';


const UserManagement = () => {

    const [usersData, setUsersData] = React.useState( [] )
    const [usersCogData, setUsersCogData] = React.useState( [] )
    const [open, setOpen] = React.useState( false );
    const [openProdSub, setOpenProdSub] = React.useState( false );
    const [openProd, setOpenProd] = React.useState( false );
    const [updateOpen, setUpdateOpen] = React.useState( false );
    const [btnUpdate, setBtnUpdate] = React.useState( false );
    const [disabletext, setDisabletext] = React.useState( false );
    const [showFieldSupp, setShowFieldSupp] = React.useState( false );
    const [post, setPostArray] = React.useState( [] );
    const [value, setValue] = React.useState( 0 );
    const [locations, setLocations] = useState( [] )
    const scrollToRef = ( ref ) => window.scrollTo( 0, ref.current.offsetTop )
    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };
    const [modalShow, setModalShow] = useState( false );
    const [selectedRowsData, setSelectedRowsData] = useState( [] )
    const [selectedRowsData1, setSelectedRowsData1] = useState( [] )
    const [scroll, setScroll] = useState( {} )
    const [inputField, setInputField] = React.useState( {

        'role': "",
        'email': "",
        'name': "",
        location: "",
        code: "",


    } )
    const [inputFieldStatusSupplier, setInputFieldStatusSupplier] = useState( {
        contact_type: '',
        comment: ''
    } )
    const [deliveryAddresses, setDeliveryAddresses] = useState( [{
        street_number_and_name: '',
        suburb: '',
        business_state: '',
        postcode: '',
    }] )
    const [validateRole, setValidateRole] = React.useState( false );
    const [validate, setValidate] = React.useState( false );

    const editLocation = ( e, details ) => {
        e.preventDefault();

        console.log( details )
        setDisabletext( true );
        setInputField( {
            ...inputField,
            location: details.location,
            code: details.code
        } );
        setBtnUpdate( true )
        if ( !details.location && details.location == undefined ) {
            console.log( 'inside undefined role' )
            setInputField( { role: '' } )
        }
        if ( !details.code && details.code == undefined ) {
            console.log( 'inside undefined roleee' )
            setInputField( { name: '' } )
        }
    }
    const editCustomer = ( e, details ) => {
        e.preventDefault();

        console.log( details )
        setDisabletext( true );
        setInputField( details );
        setBtnUpdate( true )
        if ( !details.role && details.role == undefined ) {
            console.log( 'inside undefined role' )
            setInputField( { role: '' } )
        }
        if ( !details.name && details.name == undefined ) {
            console.log( 'inside undefined roleee' )
            setInputField( { name: '' } )
        }
    }
    const deleteCustomer = ( e, details ) => {
        e.preventDefault();
        console.log( details )
        // const businesName = details.business_name;
        confirmAlert( {
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios( {
                            "method": "POST",
                            "url": process.env.REACT_APP_API_BASE + "/user/delete",
                            data: details

                        } )
                            .then( ( response ) => {
                                console.log( response );
                                listcustomer();

                            } )
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        } );

    }

    const inputsHandler = ( e ) => {
        console.log( inputField );
        console.log( e.target );
        setInputField( { ...inputField, [e.target.name]: e.target.value } )
        console.log( e.target.value )
        setValidate( false )
        setValidateRole( false )

    }
    const getLocations = () => {
        Axios.get( `${ process.env.REACT_APP_API_BASE }/location` ).then( res => {
            console.log( "body===>", res )
            if ( res.data ) {
                setLocations( res.data.body )
            }

        } )
    }
    const listcustomer = () => {

        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",

        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const listdetails = response.data.body;
                setPostArray( listdetails );

            } )
        // })
    }
    const submitButton = () => {
        // console.log()
        setDisabletext( false );
        console.log( inputField.email )
        // const {email,password} = this.state
        // validate Email
        if ( !EmailValidator.validate( inputField.email ) ) {
            console.log( 'wrong emial' )
            setValidate( true );
            return;
        }
        if ( !inputField.role ) {
            console.log( 'inside empty' );
            setValidateRole( true );
            return;
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/create",
            "data": inputField
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    setOpen( true );
                    setTimeout( () => {
                        setOpen( false );
                    }, 1500 );
                    listcustomer();
                }
                setInputField( {
                    email: '',
                    name: '',
                    role: ''

                } )
            } )
            .catch( ( error ) => {
                console.log( error )
                if ( error.message == "Request failed with status code 401" ) {
                    alert( "User account already exists." )

                } else {
                    alert( error.message )
                }
            } )
    }

    const updateButton = () => {
        if ( !inputField.email ) {
            console.log( 'inside empty' );
            setValidate( true );

            return;
        }
        console.log( inputField )
        var data = {
            'role': inputField.role,
            'name': inputField.name,
            'email': inputField.email
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/update",
            "data": data
        } )
            .then( ( response ) => {
                console.log( response )
                if ( response.status == 200 ) {
                    setUpdateOpen( true );
                    setTimeout( () => {
                        setUpdateOpen( false );
                    }, 1500 );
                    listcustomer();
                    setBtnUpdate( false )
                    setDisabletext( false );
                    setInputField( {
                        email: '',
                        name: '',
                        role: ''
                    } )

                }
            } )
    }
    const myRef = useRef( null )
    const executeScroll = () => scrollToRef( myRef )
    const updateLocation = () => {
        if ( !inputField.location ) {
            console.log( 'inside empty' );
            setValidate( true );

            return;
        }
        console.log( inputField )
        var data = {
            'location': inputField.location,
            'code': inputField.code,
        }
        // return;
        axios.put( `${ process.env.REACT_APP_API_BASE }/location`, data )
            .then( ( response ) => {
                console.log( response )
                if ( response.status == 200 ) {
                    setUpdateOpen( true );
                    setTimeout( () => {
                        setUpdateOpen( false );
                    }, 1500 );
                    getLocations();
                    setBtnUpdate( false )
                    setDisabletext( false );
                    setInputField( {
                        code: '',
                        location: '',

                    } )

                }
            } )
    }
    const getUsersList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                setUsersCogData( lengthOrder )


                //   },
            } )
    }
    const getOrderList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user-entry/list-unconfirmed-users",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                setUsersData( lengthOrder )


                //   },
            } )
    }
    useEffect( () => {
        getOrderList();
        getUsersList()
        listcustomer()
        getLocations()
        if ( isMobile ) {
            setScroll( {
                x: 1200
            } )

        }
    }, [] )
    const ConfirmOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'confirmed' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // getOrderList();
                    // setStatusOrder( true );
                    // setOpenThnk( true );
                    // setTimeout( () => {
                    //     setOpenThnk( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }

    const AdminCanOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'rejected' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // setStatusOrder( true )
                    // setStatusCanOrder( true )
                    // setOpenThnkCanc( true );
                    // setTimeout( () => {
                    //     setOpenThnkCanc( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }
    let columns = [
        {
            title: "Role",
            dataIndex: "role"
        },
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: ( _, user ) => (

                sessionStorage.getItem( 'username' ) != user.user_name ? (
                    <>
                        < button onClick={( e ) => {
                            editCustomer( e, user )
                            window.scrollTo( 0, 0 )

                        }} style={{ marginRight: '0px', background: 'transparent' }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-pencil" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg> </button >
                        <button onClick={( e ) => deleteCustomer( e, user )} style={{ marginRight: '0', background: 'transparent' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg></button>
                    </>
                ) : null

            )
        }

    ]
    let data = post
    const rowSelection = {
        onChange: ( selectedRowKeys, selectedRows ) => {
            setSelectedRowsData1( selectedRows )
            selectedRows.map( rec => {
                if ( rec.email != null && rec.email != undefined ) {
                    selectedRowsData.push( rec.email )
                }
                return rec
            } )
            console.log( `selectedRowKeys: ${ selectedRowKeys }`, 'selectedRows: ', selectedRows );
        },
        // getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        //   // Column configuration not to be checked
        //   name: record.name,
        // }),
    };
    function MyVerticallyCenteredModal( props ) {
        const [message, setMessage] = useState( "" )
        console.log( "props", props )

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>


                    <TextArea
                        placeholder='Message to users'
                        value={message}
                        onChange={( e ) => {
                            setMessage( e.target.value )
                        }} />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Button
                            style={{
                                width: "135px", height: "40px", background: "#4D6646", borderRadius: "32px", border: "0px solid green"
                            }}
                            onClick={() => props.postMessage( message )}

                        >Send</Button>
                    </div>
                </Modal.Body >
            </Modal >
        );
    }
    const postMessage = ( message ) => {
        Axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry/contact-all",
            data: {
                // event_id: eventId,
                message: message,
                users: selectedRowsData
            }
        } )
            .then( ( response ) => {
                setModalShow( false )
                alert( "Successfully message sent to all  the selected users" )
            } ).catch( e => {
                setModalShow( false )
                alert( "Server is not responding" )

            } )
    }
    return (
        <>
            <div className="  d-flex  justify-content-center " style={{ background: "rgb(50, 50, 50)", marginTop: "20px", minHeight: "100vh", flexDirection: "row" }}>
                <div style={{ width: '90%' }}>
                    {modalShow ? <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow( false )}
                        title={"Survey Data"}

                        postMessage={postMessage}

                    // selectedData={selectedData}

                    /> : null}

                    <Col md={12}>
                        <Row>
                            <h1 style={{ color: "white" }} >User Management</h1>

                        </Row>
                        <Row>

                            <br />
                            <Form>
                                <FormGroup >
                                    <Row>
                                        <Col md="6">
                                            <Row style={{ marginBottom: "20px" }}>
                                                <Col md="4">
                                                    <label>Role</label>
                                                    <Input
                                                        type="select"
                                                        name="role" onChange={inputsHandler}
                                                        value={inputField.role}>
                                                        <option selected>Select Role</option>
                                                        <option value='admin'>Admin</option>
                                                        <option value='registered_user'>Registered User</option>

                                                    </Input>
                                                </Col>

                                                <Col md="4" className="name-um">
                                                    <label> Name</label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        onChange={inputsHandler}
                                                        value={inputField.name}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="8">
                                                    <label>Email</label>
                                                    <Input
                                                        type="email"
                                                        name="email" onChange={inputsHandler}
                                                        value={inputField.email}
                                                    />
                                                </Col>
                                                {btnUpdate == false ? (
                                                    <Col md={4} className="martop">
                                                        <label style={{ color: 'transparent' }}>Update button</label><br />
                                                        <Button variant="outlined" onClick={submitButton}
                                                            color="primary" style={{ background: '#4D6646', border: "0px solid grey", borderRadius: "32px" }}><span> <b> Add & Save</b> </span></Button>
                                                    </Col>
                                                )
                                                    :
                                                    (
                                                        <Col md={4} className="martop">
                                                            <label style={{ color: 'transparent' }}>Update button</label><br />
                                                            <Button variant="outlined" style={{ background: '#4D6646', border: "0px solid grey", borderRadius: "32px" }}
                                                                color="primary" onClick={updateButton}><span> <b> Update</b> </span></Button>
                                                        </Col>
                                                    )}



                                            </Row>
                                        </Col>





                                    </Row>

                                    {/* <Row style={{textAlign:'right'}}>
           
           <Col md={{size: '3',  offset: '8'}} sm={{size: '8',  offset: '3'}}  style={{paddingRight: '0', marginBottom:'0'}}>
             <Input
               type="text"
               name="business_search"  onChange={inputsHandler} 
            value={inputField.business_search} placeholder="Search by Business "  className="searchdiv"/></Col>
           <button onClick={searchBtn} className="searchicon"><i className="fa fa-search" aria-hidden="true"></i></button>
           </Row> */}
                                    <br /><br />
                                    {/* <Table responsive className='table-bordered boxShadow' style={{ border: '.01em solid #fff' }}>
                                    <thead>
                                        <tr>
                                            <th>Role</th>
                                            <th> Name</th>
                                            <th>Email</th>

                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {post.map( user =>
                                            <tr >
                                                <td style={{ textTransform: 'capitalize' }}>{user.role}</td>
                                                <td style={{ textTransform: 'capitalize' }}>{user.name}</td>
                                                <td style={{ textTransform: 'inherit' }}>{user.email}</td>

                                                <td colSpan='38'>
                                                    {sessionStorage.getItem( 'username' ) != user.user_name ? (
                                                        <>
                                                            <button onClick={( e ) => {
                                                                editCustomer( e, user )
                                                                window.scrollTo( 0, 0 )

                                                            }} style={{ marginRight: '0px', background: 'transparent' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg> </button>
                                                            <button onClick={( e ) => deleteCustomer( e, user )} style={{ marginRight: '0', background: 'transparent' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg></button>
                                                        </>
                                                    ) : null}

                                                </td>
                                            </tr>
                                        )}


                                    </tbody>
                                </Table> */}
                                    {selectedRowsData1.length ?
                                        <Button

                                            onClick={() => {
                                                setModalShow( true )
                                            }}
                                            style={{
                                                width: "200px",
                                                height: "40px",
                                                background: "#4D6646",
                                                borderRadius: "32px",
                                                border: "0px solid green",
                                                cursor: "pointer",
                                                float: "right",
                                                marginBottom: "20px",
                                                position: "relative",
                                                zIndex: "9"
                                            }}


                                        >Send Message</Button>
                                        : null}
                                    <Table
                                        dataSource={data}
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        scroll={scroll}
                                    />
                                </FormGroup>

                            </Form>

                        </Row>
                    </Col>


                </div>
            </div>
            <Footer />
        </>
    )
}

export default UserManagement