import React from 'react'
import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Home.css'
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer';


const data = [{
    name: "Margooya Lagoon Cultural Flows Management Plan",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/MLCCover.jpg',
    link: "/pdf/Margooya Lagoon Cultural Flows Management Plan",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

}, {
    name: "Establishing a Cultural Flows Model on Tati Tati Country",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/ECCover.jpg',
    link: "/pdf/Establishing a Cultural Flows Model on Tati Tati Country",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},

{
    name: "Cultural Water for Cultural Economies",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/CWCover.jpg',
    link: "/pdf/Cultural Water for Cultural Economies Report",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},
{
    name: "Research Into How much Water is Held by First Nations and Traditional Owner Organisations in the Murray-Darling Basin in 2020 A First Nation Summary",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/MILDRINCover.jpg',
    link: "/pdf/1342_MILDRIN 16pp Report (Lana)_v1pages",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},
{
    name: "Tyalingi - A Combined Western Kulin and Murray River Language Resource",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/TYCover.jpg',
    link: "/pdf/Tyalingi",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},
{
    name: "Cultural Flows A Guide for First Nations",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/CGCover.jpg',
    link: "/pdf/Community Guide",
    url: "https://online.fliphtml5.com/bguax/jdmn/"
},


{
    name: "Victorian Traditional Owner Cultural Fire Strategy",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/FSCover.png',
    link: "/pdf/Fire+strategy+final[43096]",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},

{
    name: "Tati Tati Wadi Wadi AWA Report 2017",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/TT2017Cover.jpg',
    link: "/pdf/2017 Tati Tati AWA Report",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},
{
    name: "Yakila Yarna Thalingi - Learning to Speak Language",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/YYCover.jpg',
    link: "/pdf/Yakila_Yarna_Thalingi_reduced_size",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},
{
    name: "Wangilatha wangu nga kiyawatha - Songs and Storytelling",
    imageUrl: 'https://cdn.dev.kaiejin.org.au/static-content/resources/images/WWcover.jpg',
    link: "/pdf/wangilatha wangu nga kiyawatha",
    url: "https://online.fliphtml5.com/bguax/jdmn/"

},



]

class Resources extends Component {
    componentDidMount() {
        setTimeout( () => {

            window.scroll( {
                top: 0,
                left: 0,
                behavior: 'smooth'
            } )
        }, 40 );

        window.scrollTo( 0, 0 )
    }
    render() {
        return (
            <>
                <div className="root-about">
                    <div >
                        <div style={{ background: "rgb(37,36,36)", overflow: "hidden" }}>

                            <Row>
                                <Row id="portfolio" className="portfolio explore imgPad">

                                    {data.map( rec => <Col md="3" className="text-item filter-app">
                                        <Link to={rec.link}> <img src={rec.imageUrl} className="img-fluid img-book" alt="" width="100%" />
                                            {/* <div className="book-info">
                                            Read More
                                        
                                        </div> */}
                                        </Link>
                                    </Col> )}


                                </Row>
                            </Row>




                        </div>
                    </div >
                </div >
                <Footer />
            </>
        )
    }
}
export default Resources;