import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Image,
  Modal,
  Form,
  TextArea,
  Input,
  ToggleButton,
} from "react-bootstrap";
import { AiOutlineStar, AiOutlineUpload } from "react-icons/ai";
import { RiTimeFill } from "react-icons/ri";
import { RiShareForwardFill } from "react-icons/ri";
import { Upload, message, Button, DatePicker } from "antd";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// import { UploadOutlined } from '@ant-design/icons'

import "./event.css";
import AWS from "aws-sdk";
import S3 from "react-aws-s3";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import ReactConfirmAlert from "react-confirm-alert";
import Footer from "../footer/Footer";
import { useHistory } from "react-router-dom";

const data = [
  {
    title: "Basic Wildfire Awareness training",
    date: "September",
    desc: "",
  },
  {
    title: "Restoration Project: 2 days",
    date: "October",
    desc: "Mapping workshop to record location of different plant species at Margooya The first step to creating a revegetation project for Margooya Lagoon.",
  },
  {
    title: "Cultural Flows Workshop: 2 days ",
    date: "October/November",
    desc: "Aboriginal Waterways Assessment: 5 day assessment of waterway health at 10 different locations",
  },
  {
    title: "Cultural Flows Workshop: 2 days",
    date: "February 2022",
    desc: "",
  },
  {
    title:
      "Kaiejin Cultural Festival: 3 days, cultural activities, art, music. Family event open to all ages",
    date: " March/April 2022",
    desc: "If anyone would like to include an activity, learn a skill set or have an idea to take a group session yourself (art class, weaving, artifact making for example) please to contact us! 😊 ",
  },
];

function MyVerticallyCenteredModal(props) {
  console.log("props", props);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card-event-form" style={{ padding: "5%" }}>
          <Row>
            <div className="align-center mrg-xl-bottom">
              <h2
                className=" underlined inline-block"
                style={{ color: "white" }}
              >
                {!props.isEdit ? "Add Event" : "Edit Event"}
              </h2>
            </div>
            <Form className="container-xs pad-md">
              <Form.Group>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Title
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={props.title}
                  onChange={(e) => {
                    props.setTitle(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Sub Title
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="subtitle"
                  value={props.subtitle}
                  onChange={(e) => {
                    props.setSubtitle(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "20px" }}>
                <Row>
                  <Col lg="4">
                    <Form.Label
                      className="label-clr"
                      style={{ fontSize: "20px", letterSpacing: "2px" }}
                    >
                      {" "}
                      Start Date
                    </Form.Label>
                    {/* <ReactDatePicker
                                    value={props.data.start_date}
                                    onChange={( date ) => { props.setstart_date( date ) }}
                                /> */}
                    <ReactDatePicker
                      selected={props.start_date}
                      onChange={(date) => {
                        props.setstart_date(date);
                        console.log("date", date);
                      }}
                      // dateFormat="Pp"
                      dateFormat="dd-MM-yyyy"
                    />
                  </Col>
                  <Col lg="4">
                    <Form.Label
                      className="label-clr"
                      style={{ fontSize: "20px", letterSpacing: "2px" }}
                    >
                      {" "}
                      Start Time
                    </Form.Label>
                    {/* <ReactDatePicker
                                    value={props.data.start_date}
                                    onChange={( date ) => { props.setstart_date( date ) }}
                                /> */}
                    <TimePicker
                      showSecond={false}
                      use12Hours
                      onChange={props.onChangeStart}
                      value={props.start_time}
                    />
                  </Col>
                  <Col lg="4">
                    <Form.Label
                      className="label-clr"
                      style={{ fontSize: "20px", letterSpacing: "2px" }}
                    >
                      {" "}
                      Duration
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="duration"
                      value={props.duration}
                      onChange={(e) => {
                        props.setDuration(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="form-control min-height-4xl"
                  rows="8"
                  maxLength={1500}
                  placeholder=""
                  value={props.description}
                  onChange={(e) => {
                    props.setDescription(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Image
                </Form.Label>
                <br />
                <Upload {...props.propsData}>
                  <Button icon={<AiOutlineUpload />}>Image</Button>
                </Upload>
              </Form.Group>
              <Form.Group style={{ marginTop: "20px" }}>
                <ToggleButton
                  className="mb-2 back"
                  id="toggle-check"
                  type="checkbox"
                  variant="outline-primary"
                  checked={props.isPrivate}
                  value="1"
                  onChange={(e) => props.setIsPrivate(e.currentTarget.checked)}
                  style={{
                    color: "white",
                    borderColor: "black",
                    border: "0px",
                    marginLeft: "-5px",
                  }}
                >
                  <span style={{ marginLeft: "5px" }}>Is Private</span>
                </ToggleButton>
              </Form.Group>

              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  URL (optional)
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={props.url}
                  onChange={(e) => {
                    props.setUrl(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "20px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Footer
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={props.footer}
                  onChange={(e) => {
                    props.setFooter(e.target.value);
                  }}
                />
              </Form.Group>

              <div className="mrg-3xl-top align-center">
                {!props.isEdit ? (
                  <Button
                    className="submit-btn btn-jumbo  inline-block"
                    variant="primary"
                    type="button"
                    style={{
                      fontFamily: "Creamy Coconut",
                      letterSpacing: "2px",
                      fontSize: "25px",
                      width: "40%",
                      height: "50px",
                      background: "rgb(197, 129, 81)",
                      border: "1px solid rgb(197, 129, 81)",
                      color: "white",
                    }}
                    onClick={() => props.handleSubmit()}
                  >
                    <h1> Add Event </h1>
                  </Button>
                ) : (
                  <Button
                    className="submit-btn btn-jumbo  inline-block"
                    variant="primary"
                    type="button"
                    style={{
                      fontFamily: "Creamy Coconut",
                      letterSpacing: "2px",
                      fontSize: "25px",
                      width: "40%",
                      height: "50px",
                      background: "rgb(197, 129, 81)",
                      border: "1px solid rgb(197, 129, 81)",
                      color: "white",
                    }}
                    onClick={() => props.handleEdit()}
                  >
                    <h1 style={{ color: "white" }}>Edit Event</h1>
                  </Button>
                )}
              </div>
            </Form>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const DetailModal = (props) => {
  console.log("props", props);

  return (
    <Modal
      style={{ marginTop: "60px" }}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header> */}
      <Modal.Body>
        <div className="card-land" style={{ padding: "5%" }}>
          {/* <Row> */}
          {/* <AliceCarousel > */}
          <div style={{ marginBottom: "60px", textAlign: "center" }}>
            <p style={{ textAlign: "start" }}>{props.desc}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const Events = () => {
  const history = useHistory();
  const [key, setKey] = useState("upcoming");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [start_date, setstart_date] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [footer, setFooter] = useState("");
  const [image_url, setimageURL] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [commingEvents, setCommingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [start_time, setstart_time] = useState();
  const [duration, setDuration] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [id, setId] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [desc, setDesc] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [value, setValue] = React.useState("1");
  let role = localStorage.getItem("userRole");
  const onChangeStart = (data) => {
    console.log(data);
    setstart_time(data);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event);
    console.log(newValue);
  };
  const getImageUrl = (fileName) => {
    let url = `images/${new Date().getUTCFullYear()}/${new Date().getUTCMonth()}/${new Date().getUTCDate()}/${new Date().getUTCHours()}/${fileName}`;
    console.log("url==>", process.env.REACT_APP_IMAGE_BASE_URL, url);
    setimageURL(`${process.env.REACT_APP_IMAGE_BASE_URL}${url}`);
    return url;
  };
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 40);

    window.scrollTo(0, 0);
  }, []);
  console.log("title", title);
  const uploadMedia = (componentData) => {
    AWS.config.update({
      accessKeyId: sessionStorage.getItem("AccessKeyId"),
      secretAccessKey: sessionStorage.getItem("AccessKeySec"),
      sessionToken: sessionStorage.getItem("sessionToken"),
    });

    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: "ap-southeast-2",
    });
    const params = {
      ACL: "bucket-owner-full-control",
      Body: componentData.file,
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: getImageUrl(componentData.file.name),
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
        console.log("evt", evt);
      })
      .send((err, data) => {
        if (err) {
          console.log("error===>", err);
          componentData.onError(err);
          alert("Session time out, please sign in again");
          localStorage.clear();
          sessionStorage.clear();
          history.push("/");
        } else {
          componentData.onSuccess();
        }
      });
  };
  const propsData = {
    customRequest: (e) => {
      uploadMedia(e);
    },

    onChange(info) {
      console.log("info", info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.success(`${info.file.name} file uploaded successfully`);
      }
    },
  };
  const handleSubmit = () => {
    let data = {
      title,
      subtitle,
      start_date,
      description,
      url,
      footer,
      image_url,
      start_time,
      duration,
      is_private: isPrivate,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE}/event-data`, data)
      .then((rec) => {
        console.log("data", rec);
        if (rec.status == 200) {
          alert("Event is successfully added.");
          setSubtitle("");
          setTitle("");
          setDescription("");
          setFooter("");
          setUrl("");
          setModalShow(false);
          getEvents();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleEdit = () => {
    let data = {
      title,
      subtitle,
      start_date,
      description,
      url,
      footer,
      image_url,
      start_time,
      duration,
      id,
      is_private: isPrivate,
    };
    axios
      .put(`${process.env.REACT_APP_API_BASE}/event-data`, data)
      .then((rec) => {
        console.log("data", rec);
        if (rec.status == 200) {
          alert("Event is successfully updated.");
          setSubtitle("");
          setTitle("");
          setDescription("");
          setFooter("");
          setUrl("");
          setModalShow(false);
          getEvents();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getEvents = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE}/event-data`)
      .then((rec) => {
        console.log("data===>", rec);
        if (rec.data.body.length) {
          let comEvent = [];
          let preEvent = [];
          rec.data.body.map((rec) => {
            let d1 = new Date();
            let d2 = new Date(rec.start_date);
            // new Date(rec.start_date).toLocaleString(undefined, {
            //   timeZone: "Australia/Sydney",
            // })
            // );
            console.log(d1);
            console.log(d2);
            if (d1.getTime() > d2.getTime()) {
              preEvent.push({
                ...rec,
                start_date: new Date(rec.start_date),
                // start_date: new Date(rec.start_date).toLocaleString(undefined, {
                //   timeZone: "Australia/Sydney",
                // }),
              });
            } else {
              comEvent.push({
                ...rec,
                start_date: new Date(rec.start_date),
                // start_date: new Date(rec.start_date).toLocaleString(undefined, {
                //   timeZone: "Australia/Sydney",
                // }),
              });
            }
          });
          console.log("comEvent", comEvent);
          comEvent = comEvent.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.start_date) - new Date(b.start_date);
          });

          preEvent = preEvent.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.start_date) - new Date(a.start_date);
          });
          console.log("preEvent", preEvent);
          setCommingEvents([...comEvent]);
          setPreviousEvents([...preEvent]);
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  useEffect(() => {
    getEvents();
  }, []);
  const handleClose = () => {
    setShowConfirm(false);
  };
  return (
    <React.Fragment>
      {showConfirm ? (
        <div className="modal-bo">
          <Modal show={showConfirm} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete this event?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                style={{ background: "red", color: "white" }}
                variant="danger"
                onClick={() => {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_BASE}/event-data?id=${selectedId}`
                    )
                    .then((rec) => {
                      alert("Successfully deleted");
                      handleClose();
                      getEvents();
                    });
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>{" "}
        </div>
      ) : null}
      {modalShow ? (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setTitle("");
            setSubtitle("");
            setUrl("");
            setstart_date("");
            setimageURL("");
            setFooter("");
            setDescription("");
            setIsEdit(false);
            setIsPrivate(false);
          }}
          title={title}
          data={
            (title, subtitle, start_date, description, url, footer, image_url)
          }
          start_date={start_date}
          start_time={start_time}
          duration={duration}
          setDuration={setDuration}
          title={title}
          subtitle={subtitle}
          description={description}
          url={url}
          footer={footer}
          image_url={image_url}
          setTitle={setTitle}
          setSubtitle={setSubtitle}
          setstart_date={setstart_date}
          setDescription={setDescription}
          setUrl={setUrl}
          setFooter={setFooter}
          onChangeStart={onChangeStart}
          propsData={propsData}
          handleSubmit={handleSubmit}
          handleEdit={handleEdit}
          isEdit={isEdit}
          isPrivate={isPrivate}
          setIsPrivate={setIsPrivate}
        />
      ) : null}
      {showDetail ? (
        <DetailModal
          show={showDetail}
          onHide={() => {
            setShowDetail(false);
          }}
          desc={desc}
        />
      ) : null}

      <div>
        <div
          className="justify-content-center"
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            minHeight: "100vh",
          }}
        >
          <div>
            <TabContext value={value} style={{}}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  TabIndicatorProps={{
                    style: { background: "#fff", color: "#4D6646" },
                  }}
                  className="tabb"
                >
                  <Tab
                    label={
                      <div style={{ width: "100%" }}>
                        <div class="overflow-x-scroll-no-scrollbar">
                          <div>
                            {" "}
                            <div>Upcoming</div>
                          </div>
                        </div>
                      </div>
                    }
                    value="1"
                    style={{
                      padding: "12px 16px",
                      // color: 'rgba(0, 0, 0, 0.6)',
                      fontFamily: "creamy coconut",
                      color: "#4D6646",
                      fontSize: "65px",
                    }}
                  />
                  <Tab
                    label={
                      <div style={{ width: "100%" }}>
                        <div class="overflow-x-scroll-no-scrollbar">
                          <div>
                            {" "}
                            <div>Previous</div>
                          </div>
                        </div>
                      </div>
                    }
                    value="2"
                    style={{
                      padding: "12px 16px",
                      fontFamily: "creamy coconut",
                      color: "#4D6646",
                      fontSize: "65px",
                    }}
                  />
                  {role === "admin" ? (
                    <Tab
                      label={
                        <div style={{ width: "100%" }}>
                          <div class="overflow-x-scroll-no-scrollbar">
                            <div>
                              {" "}
                              <div>Add Event</div>
                            </div>
                          </div>
                        </div>
                      }
                      value="3"
                      style={{
                        padding: "12px 16px",
                        fontFamily: "creamy coconut",
                        color: "#4D6646",
                        fontSize: "65px",
                      }}
                    />
                  ) : null}
                </TabList>
              </Box>
              <br />
              <TabPanel value="1" style={{ padding: "0px" }}>
                <Row>
                  {commingEvents.length ? (
                    role == "admin" || role === "registered_user" ? (
                      commingEvents.map((rec) => {
                        return (
                          <Col sm={12} md={6} lg={4}>
                            <div
                              className="card-events"
                              style={{ padding: "5%" }}
                            >
                              <div
                                className="card-body"
                                style={{ width: "100% !important" }}
                              >
                                <img
                                  src={rec.image_url}
                                  className="card-img-top img-curve"
                                  alt="..."
                                />
                                {/* <span>{moment(`07-${rec}-2021`).format('LLLL')}</span>
                                 */}
                                {/* <span>{rec.date}</span> */}

                                <div style={{ padding: "10px" }}>
                                  <h2 className="card-title">{rec.title}</h2>
                                  <h2 className="card-title">{rec.subtitle}</h2>
                                  <RiTimeFill
                                    fontSize={30}
                                    style={{
                                      marginLeft: "-5px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      marginRight: "10px",
                                      fontFamily: "creamy coconut",
                                      fontSize: "20px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    Date:{" "}
                                    {moment(
                                      rec.start_date,
                                      "DD-MM-YYYY"
                                    ).format("DD-MM-YYYY")}
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: "creamy coconut",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Time:{" "}
                                    {moment(rec.start_time).format("hh:mm A")}
                                  </span>

                                  <p
                                    style={{
                                      fontFamily: "creamy coconut",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Duration: {rec.duration}
                                  </p>
                                  {/* <div>
                                        <span style={{ marginRight: "13px", cursor: "pointer"}} ></span>

                                        </div> */}
                                  <div>
                                    <Button
                                      className="btn-com"
                                      style={{
                                        color: "white",
                                        display: "inline",
                                        marginBottom: "10px",
                                        marginRight: "5px",
                                      }}
                                      onClick={() => {
                                        setDesc(rec.description);
                                        setShowDetail(true);
                                      }}
                                    >
                                      Details
                                    </Button>

                                    <Link
                                      to={{
                                        pathname: "/events-registeration",
                                        state: {
                                          title: rec.title,
                                          id: rec.id,
                                        },
                                      }}
                                    >
                                      <Button
                                        className="btn-com"
                                        style={{
                                          color: "white",
                                          display: "inline",
                                          marginBottom: "10px",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Register
                                      </Button>
                                    </Link>
                                    <Link
                                      to={{
                                        pathname: "/forum",
                                        state: { id: rec.id, name: rec.title },
                                      }}
                                    >
                                      <Button
                                        style={{
                                          color: "white",
                                          display: "inline",
                                          marginBottom: "10px",
                                          marginLeft: "10px",
                                        }}
                                        className="btn-com"
                                        onClick={() => {
                                          setTitle(rec.title);
                                        }}
                                      >
                                        Discussion
                                      </Button>
                                    </Link>
                                    {role === "admin" ? (
                                      <Button
                                        // className='btn-com'
                                        // style={{ color: "white", float: "right" }}
                                        className="btn-com1"
                                        style={{
                                          color: "white",
                                          background: "rgba(0,0,0,0)",
                                          border: "0px solid white",
                                        }}
                                        onClick={() => {
                                          console.log(rec);
                                          setTitle(rec.title);
                                          setSubtitle(rec.subtitle);
                                          setUrl(rec.url);
                                          rec.start_date
                                            ? setstart_date(
                                                new Date(rec.start_date)
                                              )
                                            : setstart_date();
                                          setimageURL(rec.image_url);
                                          setDuration(rec.duration);
                                          rec.start_time
                                            ? setstart_time(
                                                moment(rec.start_time)
                                              )
                                            : setstart_time();
                                          setFooter(rec.footer);
                                          setDescription(rec.description);
                                          rec.is_private &&
                                            setIsPrivate(rec.is_private);
                                          setModalShow(true);
                                          setIsEdit(true);
                                          setId(rec.id);
                                        }}
                                      >
                                        Edit{" "}
                                      </Button>
                                    ) : null}
                                    {role === "admin" ? (
                                      <Button
                                        className="btn-com1"
                                        style={{
                                          color: "white",
                                          background: "rgba(0,0,0,0)",
                                          border: "0px solid white",
                                        }}
                                        onClick={() => {
                                          setShowConfirm(true);
                                          setSelectedId(rec.id);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    ) : null}
                                  </div>
                                  {/* <p>{rec.description}</p> */}
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    ) : (
                      commingEvents.map((rec) => {
                        if (!rec.is_private) {
                          return (
                            <Col sm={12} md={6} lg={4}>
                              <div
                                className="card-events"
                                style={{ padding: "5%" }}
                              >
                                <div
                                  className="card-body"
                                  style={{ width: "100% !important" }}
                                >
                                  <img
                                    src={rec.image_url}
                                    className="card-img-top img-curve"
                                    alt="..."
                                  />
                                  {/* <span>{moment(`07-${rec}-2021`).format('LLLL')}</span>
                                   */}
                                  {/* <span>{rec.date}</span> */}
                                  <div style={{ padding: "10px" }}>
                                    <h2 className="card-title">{rec.title}</h2>
                                    <h2 className="card-title">
                                      {rec.subtitle}
                                    </h2>
                                    <RiTimeFill
                                      fontSize={30}
                                      fill="white"
                                      style={{
                                        marginLeft: "-5px",
                                        marginTop: "-10px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        fontFamily: "creamy coconut",
                                        fontSize: "20px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      Date:{" "}
                                      {moment(rec.start_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "creamy coconut",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Time:{" "}
                                      {moment(
                                        rec.start_date,
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")}
                                    </span>

                                    <p
                                      style={{
                                        fontFamily: "creamy coconut",
                                        fontSize: "25px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      Duration: {rec.duration}
                                    </p>
                                    {/* <div>
                                    <span style={{ marginRight: "13px", cursor: "pointer"}} ></span>

                                    </div> */}

                                    <div>
                                      <Button
                                        className="btn-com"
                                        style={{
                                          color: "white",
                                          display: "inline",
                                          marginBottom: "10px",
                                          marginRight: "5px",
                                        }}
                                        onClick={() => {
                                          setDesc(rec.description);
                                          setShowDetail(true);
                                        }}
                                      >
                                        Details
                                      </Button>
                                      <Link
                                        to={{
                                          pathname: "/forum",
                                          state: {
                                            id: rec.id,
                                            name: rec.title,
                                          },
                                        }}
                                      >
                                        <Button
                                          className="btn-com"
                                          style={{
                                            color: "white",
                                            display: "inline",
                                            marginBottom: "10px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            setTitle(rec.title);
                                          }}
                                        >
                                          Discussion
                                        </Button>
                                      </Link>

                                      <Link
                                        to={{
                                          pathname: "/events-registeration",
                                          state: {
                                            title: rec.title,
                                            id: rec.id,
                                          },
                                        }}
                                      >
                                        <Button
                                          className="btn-com"
                                          style={{
                                            color: "white",
                                            display: "inline",
                                            marginBottom: "10px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          Register
                                        </Button>
                                      </Link>
                                      {/* <p>{rec.description}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })
                    )
                  ) : (
                    <h1 style={{ color: "white" }}>No upcoming events</h1>
                  )}
                </Row>
              </TabPanel>
              <TabPanel value="2" style={{ padding: "0px" }}>
                <Row>
                  {role == "admin" || role == "registered_user"
                    ? previousEvents.map((rec) => {
                        return (
                          <Col sm={12} md={6} lg={4}>
                            <div
                              className="card-events"
                              style={{ padding: "5%" }}
                            >
                              <div
                                className="card-body"
                                style={{ width: "100% !important" }}
                              >
                                <img
                                  src={rec.image_url}
                                  className="card-img-top img-curve"
                                  alt="..."
                                />
                                {/* <span>{moment(`07-${rec}-2021`).format('LLLL')}</span>
                                 */}
                                {/* <span>{rec.date}</span> */}
                                <div style={{ padding: "10px" }}>
                                  <h2 className="card-title">{rec.title}</h2>
                                  <h2 className="card-title">{rec.subtitle}</h2>
                                  <RiTimeFill
                                    fontSize={30}
                                    fill="white"
                                    style={{
                                      marginLeft: "-5px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      marginRight: "10px",
                                      fontFamily: "creamy coconut",
                                      fontSize: "20px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    Date:{" "}
                                    {moment(
                                      rec.start_date,
                                      "DD-MM-YYYY"
                                    ).format("DD-MM-YYYY")}
                                  </span>
                                  <span
                                    style={{
                                      fontFamily: "creamy coconut",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Time:{" "}
                                    {moment(rec.start_time).format("hh:mm A")}
                                  </span>
                                  {rec.duration ? (
                                    <p
                                      style={{
                                        fontFamily: "creamy coconut",
                                        fontSize: "20px",
                                      }}
                                    >
                                      Duration: {rec.duration}
                                    </p>
                                  ) : null}
                                  <div>
                                    <div>
                                      <Button
                                        className="btn-com"
                                        style={{
                                          color: "white",
                                          display: "inline",
                                          marginBottom: "10px",
                                          marginRight: "5px",
                                        }}
                                        onClick={() => {
                                          setDesc(rec.description);
                                          setShowDetail(true);
                                        }}
                                      >
                                        Details
                                      </Button>
                                      <Link
                                        to={{
                                          pathname: "/forum",
                                          state: {
                                            id: rec.id,
                                            name: rec.title,
                                          },
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: "white",
                                            display: "inline",
                                            marginBottom: "10px",
                                            marginLeft: "10px",
                                          }}
                                          className="btn-com"
                                          onClick={() => {
                                            setTitle(rec.title);
                                          }}
                                        >
                                          Discussion
                                        </Button>
                                      </Link>
                                      {role === "admin" ? (
                                        <Button
                                          style={{
                                            color: "white",
                                            background: "rgba(0,0,0,0)",
                                            border: "0px solid white",
                                          }}
                                          className="btn-com1"
                                          onClick={() => {
                                            console.log(rec);
                                            setTitle(rec.title);
                                            setSubtitle(rec.subtitle);
                                            setUrl(rec.url);
                                            rec.start_date
                                              ? setstart_date(
                                                  new Date(rec.start_date)
                                                )
                                              : setstart_date();
                                            setimageURL(rec.image_url);
                                            setFooter(rec.footer);

                                            rec.start_time
                                              ? setstart_time(
                                                  moment(rec.start_time)
                                                )
                                              : setstart_time();
                                            setDescription(rec.description);
                                            setModalShow(true);
                                            setIsEdit(true);
                                            setId(rec.id);
                                            setDuration(rec.duration);
                                            rec.is_private &&
                                              setIsPrivate(rec.is_private);
                                          }}
                                        >
                                          Edit{" "}
                                        </Button>
                                      ) : null}
                                      {role === "admin" ? (
                                        <Button
                                          style={{
                                            color: "white",
                                            background: "rgba(0,0,0,0)",
                                            border: "0px solid white",
                                          }}
                                          className="btn-com1"
                                          onClick={() => {
                                            setShowConfirm(true);
                                            setSelectedId(rec.id);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    : previousEvents.map((rec) => {
                        if (!rec.is_private) {
                          return (
                            <Col sm={12} md={6} lg={4}>
                              <div
                                className="card-events"
                                style={{ padding: "5%" }}
                              >
                                <div
                                  className="card-body"
                                  style={{ width: "100% !important" }}
                                >
                                  <img
                                    src={rec.image_url}
                                    className="card-img-top img-curve"
                                    alt="..."
                                  />

                                  {/* <span>{moment(`07-${rec}-2021`).format('LLLL')}</span>
                                   */}
                                  {/* <span>{rec.date}</span> */}
                                  <div style={{ padding: "10px" }}>
                                    <h2 className="card-title">{rec.title}</h2>
                                    <h2 className="card-title">
                                      {rec.subtitle}
                                    </h2>
                                    <RiTimeFill
                                      fontSize={30}
                                      fill="white"
                                      style={{
                                        marginLeft: "-5px",
                                        marginTop: "-10px",
                                      }}
                                    />
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        fontFamily: "creamy coconut",
                                        fontSize: "25px",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      Date:{" "}
                                      {moment(
                                        rec.start_date,
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")}
                                    </span>
                                    <span
                                      style={{
                                        fontFamily: "creamy coconut",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {" "}
                                      Time:{" "}
                                      {moment(rec.start_time).format("hh:mm A")}
                                    </span>
                                    {rec.duration ? (
                                      <p
                                        style={{
                                          fontFamily: "creamy coconut",
                                          fontSize: "20px",
                                        }}
                                      >
                                        Duration: {rec.duration}
                                      </p>
                                    ) : null}

                                    <div>
                                      <Button
                                        className="btn-com"
                                        style={{
                                          color: "white",
                                          display: "inline",
                                          marginBottom: "10px",
                                          marginRight: "5px",
                                        }}
                                        onClick={() => {
                                          setDesc(rec.description);
                                          setShowDetail(true);
                                        }}
                                      >
                                        Details
                                      </Button>
                                      <Link
                                        to={{
                                          pathname: "/forum",
                                          state: {
                                            id: rec.id,
                                            name: rec.title,
                                          },
                                        }}
                                      >
                                        <Button
                                          className="btn-com"
                                          style={{
                                            color: "white",
                                            display: "inline",
                                            marginBottom: "10px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            setTitle(rec.title);
                                          }}
                                        >
                                          Discussion
                                        </Button>
                                      </Link>
                                      {role === "admin" ? (
                                        <Button
                                          className="btn-com"
                                          style={{
                                            color: "white",
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            setTitle(rec.title);
                                            setSubtitle(rec.subtitle);
                                            setUrl(rec.url);
                                            setDuration(rec.duration);
                                            rec.start_date
                                              ? setstart_date()
                                              : setstart_date();
                                            setimageURL(rec.image_url);
                                            setFooter(rec.footer);
                                            rec.start_time
                                              ? onChangeStart()
                                              : onChangeStart();
                                            setDescription(rec.description);
                                            setModalShow(true);
                                            setIsEdit(true);
                                            setId(rec.id);
                                            rec.is_private &&
                                              setIsPrivate(rec.is_private);
                                          }}
                                        >
                                          Edit Event
                                        </Button>
                                      ) : null}
                                      {role === "admin" ? (
                                        <Button
                                          className="btn-com"
                                          style={{
                                            color: "white",
                                            marginRight: "10px",
                                            float: "right",
                                          }}
                                          onClick={() => {
                                            setShowConfirm(true);
                                            setSelectedId(rec.id);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                </Row>
              </TabPanel>
              <TabPanel value="3" style={{ padding: "0px" }}>
                <Row>
                  <Col md="6" lg="6">
                    <Form className="container-xs pad-md">
                      <Form.Group>
                        <Form.Label
                          className="label-clr"
                          style={{ fontSize: "20px", letterSpacing: "2px" }}
                        >
                          Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group style={{ marginTop: "20px" }}>
                        <Form.Label
                          className="label-clr"
                          style={{ fontSize: "20px", letterSpacing: "2px" }}
                        >
                          Sub Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          name="subtitle"
                          value={subtitle}
                          onChange={(e) => {
                            setSubtitle(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group style={{ marginTop: "20px" }}>
                        <Row>
                          <Col lg="4">
                            <Form.Label
                              className="label-clr"
                              style={{ fontSize: "20px", letterSpacing: "2px" }}
                            >
                              {" "}
                              Start Date
                            </Form.Label>
                            {/* <ReactDatePicker
            value={props.data.start_date}
            onChange={( date ) => { props.setstart_date( date ) }}
        /> */}
                            <ReactDatePicker
                              selected={start_date}
                              onChange={(date) => {
                                setstart_date(date);
                                console.log("date", date);
                              }}
                              dateFormat="Pp"
                              dateFormat="dd-MM-yyyy"
                            />
                          </Col>
                          <Col lg="4">
                            <Form.Label
                              className="label-clr"
                              style={{ fontSize: "20px", letterSpacing: "2px" }}
                            >
                              {" "}
                              Start Time
                            </Form.Label>
                            {/* <ReactDatePicker
            value={props.data.start_date}
            onChange={( date ) => { props.setstart_date( date ) }}
        /> */}
                            <TimePicker
                              onChange={onChangeStart}
                              showSecond={false}
                              use12Hours
                              value={start_time}
                            />
                          </Col>
                          <Col lg="4">
                            <Form.Label
                              className="label-clr"
                              style={{ fontSize: "20px", letterSpacing: "2px" }}
                            >
                              {" "}
                              Duration
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              name="duration"
                              value={duration}
                              onChange={(e) => {
                                setDuration(e.target.value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group style={{ marginTop: "20px" }}>
                        <Form.Label
                          className="label-clr"
                          style={{ fontSize: "20px", letterSpacing: "2px" }}
                        >
                          URL (optional)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={url}
                          onChange={(e) => {
                            setUrl(e.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group style={{ marginTop: "20px" }}>
                        <Form.Label
                          className="label-clr"
                          style={{ fontSize: "20px", letterSpacing: "2px" }}
                        >
                          Footer
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={footer}
                          onChange={(e) => {
                            setFooter(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group style={{ marginTop: "20px" }}>
                        <Form.Label
                          className="label-clr"
                          style={{ fontSize: "20px", letterSpacing: "2px" }}
                        >
                          Description
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          className="form-control min-height-4xl"
                          style={{
                            background: "#E6E6E6 !important",
                            borderRadius: "32px",
                          }}
                          rows="8"
                          maxLength={1500}
                          placeholder=""
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Row>
                        <Col md="4">
                          <Form.Group style={{ marginTop: "20px" }}>
                            <Form.Label
                              className="label-clr"
                              style={{ fontSize: "20px", letterSpacing: "2px" }}
                            >
                              Image
                            </Form.Label>
                            <br />
                            <Upload {...propsData}>
                              <Button
                                icon={<AiOutlineUpload />}
                                className="btn-up"
                              >
                                Upload Image
                              </Button>
                            </Upload>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group style={{ marginTop: "75px" }}>
                            <ToggleButton
                              className="mb-2 back "
                              id="toggle-check"
                              type="checkbox"
                              variant="outline-primary"
                              checked={isPrivate}
                              value="1"
                              onChange={(e) =>
                                setIsPrivate(e.currentTarget.checked)
                              }
                              style={{
                                color: "white",
                                borderColor: "black",
                                border: "0px",
                              }}
                            >
                              <span style={{ marginLeft: "10px" }}>
                                {" "}
                                Private Event{" "}
                              </span>
                            </ToggleButton>
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <div className="mrg-3xl-top align-center">
                            <Button
                              className="submit-btn btn-jumbo  inline-block"
                              variant="primary"
                              type="button"
                              style={{
                                fontFamily: "Creamy Coconut",
                                // letterSpacing: '2px',
                                fontSize: "16px",
                                // width: '40%',
                                height: "55px",
                                background: "#4D6646",
                                border: "1px solid #4D6646",
                                borderRadius: "32px",
                                width: "165px",
                              }}
                              onClick={() => handleSubmit()}
                            >
                              <h1
                                style={{
                                  fontSize: "22px",
                                  marginTop: "10px",
                                  color: "white",
                                }}
                              >
                                {" "}
                                Add & Save{" "}
                              </h1>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Events;
