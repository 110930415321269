
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { RiFacebookCircleFill, RiLinkedinFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { Col, Row } from 'reactstrap';
import './footer.css'
const FooterWater = () => {
    const [heightforFooter, setheightGorFooter] = useState( "" )
    useEffect( () => {
        console.log("path", window.location.href )
    } )
    return (
        <Row style={{ paddingTop: "20px", background: "#515050",minHeight:"310px" }}>
            <Col md="3">

                <div><h1 style={{ color: "white" }}>Proudly supported by</h1></div>
                <div className='d-flex justify-content-center'>
                    <img className="max-width-4xl mrg-lg-top" height={"75px"} src={'https://cdn.dev.kaiejin.org.au/static-content/Victoria-State-Government.png'} alt="Australian Government" style={{ marginBottom: "46px" }} />
                </div>
            </Col>
            <Col md="3">
                <div className='d-flex justify-content-center'>
                    <div style={{ width: "200px", float: "right", textDecoration: "none", color: "white", paddingLeft: '50px', paddingBottom: "20px" }}>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/login">
                                Login/Register
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/resources">
                                Resources
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/events">
                                Events
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/contact">
                                Contact Us
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/donations">
                                Donations
                            </Link>
                        </div>
                        <div classname='links-div d-flex justify-content-center'>
                            <Link to="/help">
                                Help
                            </Link>
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="3">
                <div className='d-flex justify-content-center'>
                    <h1 style={{ color: "white" }}>Follow us</h1>
                </div>
                <div className="social-media-nav d-flex  justify-content-center">
                    <span className="social-media-nav container-xxs pad-3xl-v justify-content-center">
                        <a href="https://www.linkedin.com/company/tati-tati-kaiejin/" target="_blank"><RiLinkedinFill size={30} /></a>
                    </span>
                    <span className="social-media-nav container-xxs pad-3xl-v justify-content-center">

                        <a href="https://www.instagram.com/tati_tati_kaiejin" target="_blank"><AiFillInstagram size={30} /></a>

                    </span>
                    <span className="social-media-nav container-xxs pad-3xl-v justify-content-center">
                        <a href="https://www.facebook.com/TatiTatiKaiejin" target="_blank"><RiFacebookCircleFill size={30} /></a>

                    </span>


                </div>
            </Col>
            <Col md="3">
                <div >
                    <h2 className="mrg-0" ><Link to="/contact" ><h1 className="contact-us-text"
                        style={{}}>Contact Us</h1></Link></h2>
                </div>
                <div className="contact-details pad-md-v contact-details-page" >
                    <p className="mr-0"><div className="" ></div><span className="email-address"><a className="color-9" style={{ fontFamily: "open sans" }} href="mailto:info@kaiejin.org.au">info@kaiejin.org.au</a></span></p>
                    <p className="mr-0" ><div className="" ></div><span className="physical-address" style={{ color: "white", fontFamily: "open sans" }}>Tati Tati Country</span></p>
                </div>
                <div className="copyright-notice  pad-xs text-smaller text-end copy-right">
                    © 2022 Kaiejin
                </div>

            </Col>


        </Row>

    );

}

export default FooterWater;