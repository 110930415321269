import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Popover, Row, Col, Image, Modal, ToggleButton, Form, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Container from 'reactstrap/lib/Container'
import ReactDatePicker from 'react-datepicker'
import DataTable from 'react-data-table-component';
import { Table } from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import './dashboard.css'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'

import { HiRefresh } from 'react-icons/hi'
import { isMobile } from 'mobile-device-detect';
import { Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import './table-border.css'
import Footer from '../footer/Footer'
const { Option } = Select;

let columnsUser = [
    {
        title: 'First Name',
        dataIndex: "first_name",

    },
    {
        title: 'Last Name',
        dataIndex: "last_name",
    },
    {
        title: 'Event',
        dataIndex: "eventTitle",
    },
    {
        title: 'Date',
        dataIndex: "created_datetime",
        render: ( _, rec ) => {
            return <p>{moment( rec.created_datetime ).format( "DD/MM/YYYY" )}</p>
        }
    },
    {
        title: 'Address',
        dataIndex: "address",
    },
    {
        title: 'Email',
        dataIndex: "email",
    },
    {
        title: 'Phone',
        dataIndex: "phone",

    }


];
columnsUser = columnsUser.map( ( rec, index ) => {
    return {
        ...rec,
        key: rec.dataIndex
    }
} )
const EventUsers = ( props ) => {
    const [supplier, setSuppliers] = useState( [] )
    const [supplierFull, setSuppliersFull] = useState( [] )
    const [modalShow, setModalShow] = useState( false );

    const [selectedData, setSelectedData] = useState( [] )
    const [scroll, setScroll] = useState( {} )

    const [state, setState] = useState( [
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ] );
    const [filteredDataUser, setFilteredDataUser] = useState( [] )
    const [datauser, setDataUser] = useState( [] )

    const componentRef1 = useRef()
    let [locations, setLocations] = useState( [] )
    const [location, setLocation] = useState( "" )
    const [temp, setTempSurvey] = useState( [] )
    const [value, setValue] = React.useState( '1' );
    const [selectedRowKeys, setSelectedRowKeys] = useState( [] );
    const [selectedRowsData, setSelectedRowsData] = useState( [] )
    const [eventId, setEventId] = useState( "" )


    const onSelectChange = ( newSelectedRowKeys ) => {
        console.log( 'selectedRowKeys changed: ', selectedRowKeys );
        setSelectedRowKeys( newSelectedRowKeys );
    };

    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     selections: [
    //         Table.SELECTION_ALL,
    //         Table.SELECTION_INVERT,
    //         Table.SELECTION_NONE,
    //         {
    //             key: 'odd',
    //             text: 'Select Odd Row',
    //             onSelect: ( changableRowKeys ) => {
    //                 let newSelectedRowKeys = [];
    //                 newSelectedRowKeys = changableRowKeys.filter( ( _, index ) => {
    //                     if ( index % 2 !== 0 ) {
    //                         return false;
    //                     }

    //                     return true;
    //                 } );
    //                 setSelectedRowKeys( newSelectedRowKeys );
    //             },
    //         },
    //         {
    //             key: 'even',
    //             text: 'Select Even Row',
    //             onSelect: ( changableRowKeys ) => {
    //                 let newSelectedRowKeys = [];
    //                 newSelectedRowKeys = changableRowKeys.filter( ( _, index ) => {
    //                     if ( index % 2 !== 0 ) {
    //                         return true;
    //                     }

    //                     return false;
    //                 } );
    //                 setSelectedRowKeys( newSelectedRowKeys );
    //             },
    //         },
    //     ],
    // };
    const rowSelection = {
        onChange: ( selectedRowKeys, selectedRows ) => {
            setSelectedRowsData( selectedRows )
            console.log( `selectedRowKeys: ${ selectedRowKeys }`, 'selectedRows: ', selectedRows );
        },
        // getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        //   // Column configuration not to be checked
        //   name: record.name,
        // }),
    };
    const componentRef = useRef();
    const getEvents = () => {

        Axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/event-registeration",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                let lengthOrder = response.data.body;
                lengthOrder = lengthOrder.map( ( rec, index ) => {
                    return {
                        ...rec,
                        key: index
                    }
                } )
                setFilteredDataUser( lengthOrder )
                setDataUser( lengthOrder )
                // setFilteredDataUser( lengthOrder )

                //   },
            } )

    }
    const postMessage = ( message ) => {
        Axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/event-registeration/contact-all",
            data: {
                event_id: eventId,
                message: message
            }
        } )
            .then( ( response ) => {
                setModalShow( false )
                alert( "Successfully Message Sent to All the Users" )
            } ).catch( e => {
                setModalShow( false )
                alert( "Server is not responding" )

            } )
    }

    useEffect( () => {
        getEvents()
        if ( isMobile ) {
            setScroll( {
                x: 1200
            } )

        }

    }, [] )
    const children = [];
    const childrenOne = [];

    children.push( <Option key={"Select Event"}>Select Event</Option> )
    for ( let i = 0; i < datauser.length; i++ ) {
        console.log( "Data User", datauser )

        if ( !childrenOne.find( rec => rec == datauser[i].eventTitle ) ) {
            if ( datauser[i].eventTitle != undefined ) {
                console.log( "filteredDataUser[i].eventTitle", datauser[i].eventTitle )
                childrenOne.push( datauser[i].eventTitle )
                children.push( <Option key={datauser[i].eventTitle}>{datauser[i].eventTitle}</Option> );
            }
        }
    }
    function handleChangeSelect( value ) {
        console.log( `selected ${ datauser }` );
        let selectedEvent = datauser.find( rec => rec.eventTitle == value )
        setEventId( selectedEvent ? selectedEvent.eventId : selectedEvent )
        console.log( "Select", selectedEvent );
        setFilteredDataUser( datauser.filter( ( rec, index ) => {
            if ( rec.eventTitle == value ) {
                return {
                    ...rec,
                    key: index
                }
            }
        }
        ) )
    }
    function MyVerticallyCenteredModal( props ) {
        const [message, setMessage] = useState( "" )
        console.log( "props", props )

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>


                    <TextArea
                        placeholder='Message to users'
                        value={message}
                        onChange={( e ) => {
                            setMessage( e.target.value )
                        }} />
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Button
                            style={{
                                width: "135px", height: "40px", background: "#4D6646", borderRadius: "32px", border: "0px solid green"
                            }}
                            onClick={() => props.postMessage( message )}

                        >Send</Button>
                    </div>
                </Modal.Body >
            </Modal >
        );
    }
    console.log( "filt", filteredDataUser, columnsUser )
    return (
        <>
            <Container style={{ background: "rgb(50, 50, 50)", marginTop: "20px", minHeight: "100vh" }}>
                {modalShow ? <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow( false )}
                    title={"Survey Data"}

                    postMessage={postMessage}

                // selectedData={selectedData}

                /> : null}
                <h1 style={{ color: "white" }}>Events Registered Users</h1>
                <div style={{ marginBottom: "30px" }} >
                    <Row>
                        <Col md="8" sm="12" className="d-1">
                            <div style={{ display: "inline-block" }} className="date-picker-text">
                                {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                                <Select
                                    style={{ width: '100%', borderRadius: "32px" }}
                                    placeholder="Select Event "
                                    onChange={handleChangeSelect}>
                                    {children}
                                </Select>

                            </div>
                            <div style={{  display: "inline-block" }}>
                                <HiRefresh style={{ color: "white", fontSize: "30px", fill: "#4D6646" }} onClick={() => {
                                    setFilteredDataUser( datauser )
                                    // setSearch_dateSurvey( new Date() )
                                }} /></div>
                        </Col>
                        <Col md="4" sm="12">
                            {eventId ? <div className="btn-ex">
                                <Button

                                    onClick={() => {
                                        setModalShow( true )
                                    }}
                                    style={{ width: "200px", height: "40px", background: "#4D6646", borderRadius: "32px", border: "0px solid green", cursor: "pointer" }}


                                >Send Message</Button>
                            </div> : null}
                        </Col>
                    </Row>

                </div>
                {/* <div style={{marginTop:"80px",height:"10px"}}>

            </div> */}
                {/* <div style={{width:"80%",overflowX:"scroll"}}> */}
                {filteredDataUser.length ? <Table
                    columns={columnsUser}
                    dataSource={filteredDataUser}
                    rowSelection={rowSelection}
                    scroll={scroll}
                /> : null}
                {/* </div> */}

            </Container>
            <Footer />
        </>
    )
}
export default EventUsers