import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import "./auth.css";
import { Button, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import EmailValidator from "email-validator";
import Amplify, { Auth } from "aws-amplify";
import axios from "axios";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import { Input as InputAnt } from "antd";
const amplifyConfig = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: `${process.env.REACT_APP_COGNITO_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_KEY}`,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};
class AuthForm extends React.Component {
  componentDidMount() {
    // this.checkStorage();
    Amplify.configure(amplifyConfig);
  }
  constructor() {
    super();

    this.state = {
      age: "",
      phone: "",
      gender: "",
      title: "",
      checked: false,
      email: "",
      openThnk: false,
      isLogin: true,
      usernamee: "",
      code: "",
      newPassword: "",
      password: "",
      auth: "",
      firstName: "",
      lastName: "",
      emailSign: "",
      streetNumName: "",
      statea: "",
      postcode: "",
      businessName: "",
      suburb: "",
      passwordSign: "",
      username: "",
      passwordSignCo: "",
      isNewPassword: false,
      isForgotPwd: false,
      ismessage: false,
      forgotMsgCode: false,
      isForgotPwdSub: false,
      isSignup: false,
      codesignup: "",
      isConfirm: false,
    };

    this.handleChange = this.handleChange.bind(this);
    // this.state = {email: '',password :''};
    // this.handleChange1 = this.handleChange1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    // this.state = {
    //   auth: any,
    // }
  }

  handleChange(event) {
    if (event.target.name != "postcode") {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
    this.textvalue = event.target.value;
    this.textname = event.target.name;
    if (event.target.name == "usernamee") {
      this.uservalue = event.target.value;
      this.username = event.target.name;
    }
    if (event.target.name == "code") {
      this.code = event.target.value;
    }
    if (event.target.name == "newpasswordd") {
      this.newPassword = event.target.value;
    }
    if (event.target.name == "Fname") {
      this.firstName = event.target.value;
    }
    if (event.target.name == "Lname") {
      this.lastName = event.target.value;
    }
    if (event.target.name == "emailSign") {
      this.emailSign = event.target.value;
    }
    if (event.target.name == "street_name") {
      this.streetNumName = event.target.value;
      this.state.streetNumName = event.target.value;
    }
    if (event.target.name == "suburb") {
      this.suburb = event.target.value;
    }
    if (event.target.name == "statea") {
      this.statea = event.target.value;
    }
    if (event.target.name == "postcode") {
      if (event.target.value.length > 4) {
        alert("You can't enter more than 4 digits");
      } else {
        this.setState({
          ...this.state,
          postcode: event.target.value,
        });
        this.postcode = event.target.value;
      }
    }
    if (event.target.name == "codeSign") {
      this.codesignup = event.target.value;
    }
    if (event.target.name == "Uname") {
      this.businessName = event.target.value;
    }
    // event.target
    // this.setState({
    // 	checked: !this.state.checked
    // })
  }
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  // get isSignup() {
  //   return this.props.authState === STATE_SIGNUP;
  // }

  // changeAuthState = authState => event => {
  //   event.preventDefault();

  //   this.props.onChangeAuthState(authState);
  // };

  handleSubmit = (event) => {
    if (!this.state.isNewPassword) {
      const { email, password } = this.state;
      // validate Email
      if (!email) {
        return;
      }

      // validate password
      if (!password) {
        return;
      }

      this.amplifySignIn(email, password);
    } else {
      console.log("reset");
      const { auth } = this.state;
      console.log(auth);
      console.log(this.textname);
      const passwvalue = this.textvalue;
      this.setState({ isLogin: false });
      // console.log({...auth,email:"test@example.com"})
      Auth.completeNewPassword(auth, passwvalue).then((response) => {
        console.log("ResetPassword", response);
        sessionStorage.setItem(
          "idtoken",
          response.signInUserSession.idToken.jwtToken
        );
        localStorage.setItem("username", response.username);

        localStorage.setItem(
          "userRole",
          response.signInUserSession.idToken.payload["cognito:groups"][0]
        );
        this.props.history.push("/");
        // else if(localStorage.getItem('userRole') == 'operation'){
        //   this.props.history.push('/operations');

        // }

        // this.props.history.push('/dashboard');
      });
    }
  };

  clear = () => {
    this.title = "";
    this.lastName = "";
    this.firstName = "";
    this.streetNumName = "";
    this.statea = "";
    this.suburb = "";
    this.postcode = "";
    this.setState({
      postcode: "",
      statea: "",
    });
    return;
  };
  handleSignUp = (event) => {
    console.log(this.firstName, this.lastName, this.emailSign);

    // const {email,password} = this.state
    const username = this.username;
    const address =
      this.streetNumName +
      ", " +
      this.suburb +
      ", " +
      this.statea +
      " " +
      this.postcode;
    const business_name = this.businessName;
    const name = this.firstName + this.lastName;
    const phone = this.state.phone;

    let email = this.emailSign;
    console.log(address);
    // return;
    if (
      phone != "" &&
      phone != undefined &&
      name != "" &&
      name != undefined &&
      this.suburb != "" &&
      this.suburb != undefined &&
      this.streetNumName != "" &&
      this.streetNumName != undefined &&
      this.statea != "" &&
      this.statea != undefined
    ) {
      let phonenumber = 0;
      if (this.state.phone.charAt(0) != 0) {
        phonenumber = "+61" + this.state.phone;
      } else {
        phonenumber = "+61" + this.state.phone.slice(1);
      }
      if (email == "" || email == undefined) {
        email = phonenumber;
      }
      console.log(phonenumber);
      // return;
      axios({
        method: "POST",
        url: process.env.REACT_APP_API_BASE + "/user-entry",
        data: {
          name: name,
          status: "unconfirmed",
          title: this.title,
          email: email,
          last_name: this.lastName,
          first_name: this.firstName,
          street: this.streetNumName,
          state: this.statea,
          suburb: this.suburb,
          post_code: this.postcode,
          gender: this.state.gender,
          age: this.state.age,
          phone: phonenumber,
        },
      })
        .then((response) => {
          //  if(response.session){
          console.log(this.state.openThnk);
          this.setState({ openThnk: true });
          console.log(this.state.openThnk);

          // this.openThnk:true;
          setTimeout(() => {
            this.setState({
              openThnk: false,
              age: "",
              gender: "",
              statea: "",
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
            });
          }, 3000);
          this.firstName = "";
          this.lastName = "";
          this.setState({
            Tname: "",
            Uname: "",
            Fname: "",
            Lname: "",
            title: "",
            street_name: "",
            statea: "",
            postcode: "",
            emailSign: "",
            suburb: "",
            abn: "",
            telephone: "",
          });
          // this.setState({firstName : '', lastName:'',businessName:'',streetNumName:'',suburb:'',statea:'',postcode:'',email:''})

          // this.props.history.push('/customerOrder');
        })
        .catch((error) => {
          console.log(error);
          if (error.message == "Request failed with status code 401") {
            alert("User already exists");
          }
        });
    } else {
      alert("Please Fill all the inputs");
    }
  };
  // configAxio = ( jwtToken ) => {
  //   axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  // };
  automaticTimeout = (time) => {
    console.log("Time==>", time);
    this.timer = setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert("Session time out, please sign in again");
      sessionStorage.clear();
      localStorage.clear();
      // localStorage.removeItem( "accessLevel" );
      // reset page location
      console.log("logout succesfully");
      document.location.replace("/");
    }, time);
  };
  onReady = (response) => {
    const { signInUserSession, username } = response;
    const { idToken } = signInUserSession;
    const { jwtToken, payload } = idToken;
    const { exp } = payload;
    const difference = exp * 1000 - new Date().getTime();
    console.log(difference);
    this.automaticTimeout(difference);

    // this.configAxio( jwtToken );
    sessionStorage.setItem(
      "idtoken",
      response.signInUserSession.idToken.jwtToken
    );
    sessionStorage.setItem(
      "accesstoken",
      response.signInUserSession.accessToken.jwtToken
    );
    console.log(
      response.signInUserSession.idToken.payload["cognito:groups"][0]
    );
    console.log(response.username);
    sessionStorage.setItem("username", response.username);
    localStorage.setItem(
      "idtoken",
      response.signInUserSession.idToken.jwtToken
    );
    // await localStorage.SetItemAsync<string>('userRole', response.signInUserSession.idToken.payload["cognito:groups"][0] ?? String.Empty);
    localStorage.setItem(
      "userRole",
      response.signInUserSession.idToken.payload["cognito:groups"][0]
    );
    localStorage.setItem("email", response.attributes.email);
    if (localStorage.getItem("userRole") == "customer") {
      this.props.history.push("/");
    } else if (localStorage.getItem("userRole") == "manager") {
      this.props.history.push("/");
    }
    // else if(localStorage.getItem('userRole') == 'operation'){
    //   this.props.history.push('/operations');
    // }
    else {
      this.props.history.push("/");
    }
  };
  amplifySignIn = (email, password) => {
    Auth.signIn(email, password)
      .then((response) => {
        //  if(response.session){
        console.log(response);
        // console.log( response.signInUserSession.idToken.jwtToken);
        //  }
        this.setState({
          auth: response,
        });
        // switch ( response.challengeName ) {
        //   case  response.challengeName: {
        //     this.setState({isNewPassword: true})
        //     break;
        //   }
        //   case undefined: {
        //     this.onReady( response );
        //     break;
        //   }
        //   default: {
        //     console.log( 'Challenge', response.challengeName );
        //   }
        // }
        // switch ( response.challengeName ) {
        //   case undefined: {
        //     const { signInUserSession, username } = response;
        //     sessionStorage.setItem('idtoken', response.signInUserSession.idToken.jwtToken)

        //     const { idToken } = signInUserSession;
        //     const { jwtToken, payload } = idToken;
        //     // console.log("urllll",`${this.state.redirectUrl}?state=${this.state.stat}&access_token=${jwtToken}`)
        //     this.props.history.push('/dashboard');
        //     break;
        //   }
        //   default: {
        //     console.log( 'Challenge', response.challengeName );
        //   }
        // }
        if (response.challengeName) {
          console.log("inside reset");

          this.setState({ isNewPassword: true });
        } else {
          console.log("inside Dashboard");
          this.onReady(response);
          // sessionStorage.setItem('idtoken', response.signInUserSession.idToken.jwtToken)
          // this.props.history.push('/dashboard');
        }
      })
      .catch((e) => {
        console.log(e);
        this.messagee = e.message;
        this.setState({ showResults: e.message });
      });
  };
  forgotPwd = () => {
    this.setState({ isForgotPwd: true, isNewPassword: false, isLogin: false });
    console.log("in forgot");
  };
  forgotPassword = () => {
    const passwvalue11 = this.uservalue;

    // const passusername1 = this.username;
    if (passwvalue11 == undefined) {
      return;
    }
    if (!EmailValidator.validate(passwvalue11)) {
      this.setState({ ismessage: true });
      console.log("in user name");
      return;
    } else {
      this.setState({ isForgotPwdSub: true });

      this.setState({ ismessage: false, forgotMsgCode: true });

      // return;
    }
    console.log(passwvalue11);
    // return;
    Auth.forgotPassword(passwvalue11)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };
  signupBtn = () => {
    console.log("in Signup");
    this.setState({
      isForgotPwd: false,
      isNewPassword: false,
      isLogin: false,
      isSignup: true,
    });
  };
  isLoginBtn = () => {
    this.setState({
      isForgotPwd: false,
      isNewPassword: false,
      isSignup: false,
      isLogin: true,
    });
  };
  forgotPasswordSub = () => {
    const code = this.code;
    const newPwd = this.newPassword;
    const useremail = this.uservalue;
    console.log(useremail);
    console.log(code);
    console.log(newPwd);
    // return;
    Auth.forgotPasswordSubmit(useremail, code, newPwd)
      .then((data1) =>
        this.setState({
          isLogin: true,
          isForgotPwd: false,
          isForgotPwdSub: false,
          forgotMsgCode: false,
          isNewPassword: false,
        })
      )
      .catch((err) => console.log(err));
  };
  submitCodeSign = () => {
    const username = this.username;
    const code = this.codesignup;
    Auth.confirmSignUp(username, code)
      .then((response) => {
        console.log(response);
        if (response) {
          this.setState({
            isForgotPwd: false,
            isNewPassword: false,
            isSignup: false,
            isLogin: true,
          });
        }
      })
      .catch((error) => {
        console.log("error confirming sign up", error);
      });
  };
  // }

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return "Login";
    }

    // if (!buttonText && this.isSignup) {
    //   return 'Signup';
    // }
    if (!buttonText && this.isResetPwd) {
      return "Reset Password";
    }
    console.log("button", buttonText);
    return buttonText;
  }

  render() {
    console.log("state", this.state);
    const {
      showLogo,
      usernameLabel,
      usernameLabelUI,
      usernameLabelFN,
      usernameLabelLN,
      usernameInputProps,
      usernameInputPropsUI,
      usernameInputFNProps,
      usernameInputLNProps,
      passwordLabel,
      titleInputProps,
      postInputProps,
      postcodeLabel,
      cityLabel,
      cityInputProps,
      streetLabel,
      streetInputProps,
      titleLabel,
      memhsipLabel,
      membshipInputProps,
      phoneLabel,
      phoneInputProps,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      countryLabel,
      countryInputProps,
      children,
      onLogoClick,
    } = this.props;

    const hidden = this.state.checked ? "" : "hidden";

    return (
      <div>
        <Dialog open={this.state.openThnk}>
          <DialogContent>
            <DialogContentText>
              <FormGroup className="">
                <Row>
                  <Col md={12}>
                    <b style={{ color: "red" }}>Thank you</b>
                    <br />
                    <br />
                    <label style={{ color: "red" }}>
                      Thank you for the Registration. Admin will send you
                      confirmation Email with Login details.
                    </label>
                  </Col>
                </Row>
              </FormGroup>
              <br />

              <br />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* ):null} */}
        <div style={{ color: "red", paddingBottom: "4%", textAlign: "center" }}>
          {this.state.showResults ? <span>{this.messagee}</span> : null}
          {this.state.ismessage ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please enter a valid email address{" "}
            </p>
          ) : null}
        </div>
        <Form className="form-log" style={{ minHeight: "100vh" }}>
          {this.state.isSignup && (
            <>
              <h1
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontFamily: "creamy coconut",
                }}
              >
                REGISTER{" "}
              </h1>
              <br />
              <FormGroup className="Colbl">
                <Row>
                  <Col md={4} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="Tname" className="label-wht label-clr">
                      Title
                    </Label>
                    <Input
                      className="form-log"
                      type="select"
                      name="title"
                      onChange={this.handleChange}
                      required
                    >
                      <option
                        style={{ color: "gray" }}
                        disabled
                        selected
                        value=""
                      >
                        Select Title
                      </option>
                      <option>Mr</option>
                      <option>Miss</option>
                      <option>Mrs</option>
                    </Input>
                  </Col>
                  <Col md={4} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="name" className="label-wht label-clr">
                      First Name
                    </Label>
                    <Input
                      type="text"
                      name="Fname"
                      value={this.state.Fname}
                      placeholder="First Name"
                      onChange={this.handleChange}
                      className="form-log"
                    />
                  </Col>
                  <Col md={4} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="lname" className="label-wht label-clr">
                      Last Name
                    </Label>
                    <Input
                      className="form-log"
                      type="text"
                      name="Lname"
                      value={this.state.Lname}
                      placeholder="Last Name"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col sm={12} md={6} style={{ marginLeft: "0px" }}>
                    <Label for="name" className="label-wht label-clr">
                      Age
                    </Label>
                    <Input
                      className="form-log"
                      type="text"
                      name="age"
                      value={this.state.age}
                      placeholder="Age"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col sm={12} md={6} style={{ paddingRight: "0" }}>
                    <Label
                      for="confirmPassword"
                      className="label-wht label-clr"
                    >
                      Gender
                    </Label>
                    <Input
                      className="form-log"
                      required
                      type="select"
                      name="gender"
                      value={this.state.gender}
                      onChange={this.handleChange}
                    >
                      <option disabled selected value="">
                        Select Gender
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="Other">Other</option>
                    </Input>
                  </Col>
                  <Col md={12} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="email" className="label-wht label-clr">
                      {" "}
                      Phone Number<span style={{ color: "red" }}> *</span>
                    </Label>
                    <InputAnt
                      addonBefore="+61"
                      value={this.state.phone}
                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          alert("You could not add more then 10 digits");
                        } else {
                          this.setState({
                            ...this.state,
                            phone: e.target.value,
                          });
                        }
                      }}
                    />
                  </Col>
                  <Col md={12} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="email" className="label-wht label-clr">
                      Email (Optional)
                    </Label>
                    <Input
                      className="form-log"
                      type="email"
                      value={this.state.emailSign}
                      name="emailSign"
                      placeholder="Email "
                      onChange={this.handleChange}
                    />
                  </Col>

                  <Col md={6} sm={12} style={{ paddingRight: "0" }}>
                    <Label for="password" className="label-wht label-clr">
                      Street
                    </Label>
                    <Input
                      className="form-log"
                      type="text"
                      value={this.state.streetNumName}
                      name="street_name"
                      placeholder="Street Number & Name"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col md={6} sm={12} style={{ paddingRight: "0" }}>
                    <Label
                      for="confirmPassword"
                      className="label-wht label-clr"
                    >
                      Suburb
                    </Label>
                    <Input
                      className="form-log"
                      type="text"
                      name="suburb"
                      value={this.state.suburb}
                      placeholder="Suburb"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col sm={12} md={6} style={{ paddingRight: "0" }}>
                    <Label
                      for="confirmPassword"
                      className="label-wht label-clr"
                    >
                      Postcode
                    </Label>
                    <Input
                      className="form-log"
                      type="text"
                      value={this.state.postcode}
                      name="postcode"
                      placeholder="Postcode"
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col sm={12} md={6} style={{ paddingRight: "0" }}>
                    <Label for="State" className="label-wht label-clr">
                      State
                    </Label>
                    <Input
                      className="form-log"
                      type="select"
                      value={this.statea}
                      name="statea"
                      onChange={this.handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        Select State
                      </option>
                      <option value="QLD">QLD</option>
                      <option value="NT">NT</option>
                      <option value="NSW">NSW</option>
                      <option value="SA">SA</option>
                      <option value="VIC">VIC</option>
                      <option value="WA">WA</option>
                    </Input>
                  </Col>
                </Row>
                <div style={{ clear: "both" }}></div>
              </FormGroup>
            </>
          )}

          {this.state.isSignup && (
            <FormGroup className="Colbl">
              <Row></Row>
              <div style={{ clear: "both" }}></div>
            </FormGroup>
          )}
          {this.state.isConfirm && (
            <FormGroup className="Colbl">
              {/* <Label for='email'>Email / Username</Label> */}
              <Input
                className="form-log"
                type="email"
                name="emailSign"
                placeholder="Email / Username"
                onChange={this.handleChange}
              />
            </FormGroup>
          )}
          {this.state.isConfirm && (
            <FormGroup className="Colbl">
              {/* <Label for='code'>Code</Label> */}
              <Input
                type="text"
                name="codeSign"
                placeholder="Code"
                onChange={this.handleChange}
              />
            </FormGroup>
          )}
          {this.state.isSignup && (
            <>
              <FormGroup className="Colbl">
                <Row></Row>
                <div style={{ clear: "both" }}></div>
              </FormGroup>
              <FormGroup className="Colbl">
                <Row></Row>
                <div style={{ clear: "both" }}></div>
                <br />
              </FormGroup>
            </>
          )}

          {this.state.isLogin && !this.state.isNewPassword && (
            <Form className="container-xs pad-md log">
              <Form.Group>
                <Form.Label className="label-clr">User Name</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Username"
                  onChange={this.handleChange}
                  className="form-control"
                />
                <br />
                <Form.Label
                  className="label-clr"
                  // style={{ fontSize: '20px', letterSpacing: '2px' }}
                >
                  Password
                </Form.Label>
                {this.state.isLogin && !this.state.isNewPassword && (
                  <Form.Control
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder={passwordLabel}
                    onChange={this.handleChange}
                  />
                )}
                {/* <br/> */}
                {!this.state.isNewPassword &&
                  !this.state.isForgotPwd &&
                  !this.state.isSignup &&
                  !this.state.isConfirm && (
                    <>
                      <FormGroup className="Colbl" check>
                        <Label
                          check
                          style={{ color: "#fff", fontSize: "12px" }}
                        >
                          <Input type="checkbox" style={{ paddingTop: "0" }} />
                          {"Remember me"}
                        </Label>
                        <a
                          onClick={this.forgotPwd}
                          className="Forpas"
                          style={{
                            color: "#fff",
                            fontSize: "12px",
                            cursor: "pointer",
                            float: "right",
                          }}
                        >
                          Forgot Password?
                        </a>
                      </FormGroup>
                      <br />
                    </>
                  )}
              </Form.Group>
            </Form>
            // <FormGroup className="Colbl">
            //   {/* <Label for={usernameLabelUI}>Email / Username</Label> */}
            //   <Input type='email' name='email' placeholder="Email / Username" onChange={this.handleChange} />
            // </FormGroup>
          )}
          {/* <br />


          <br /> */}
          {this.state.forgotMsgCode && (
            <>
              <FormGroup className="Colbl">
                {/* <Label >Enter Securtiy Code</Label> */}
                <Input
                  type="text"
                  name="code"
                  placeholder="Enter Securtiy Code"
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className="Colbl">
                {/* <Label>Enter New Password</Label> */}
                <Input
                  type="password"
                  name="newpasswordd"
                  placeholder="Enter New Password"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </>
          )}
          {/* <hr /> */}
          {!this.state.isNewPassword &&
            !this.state.isForgotPwd &&
            !this.state.isSignup &&
            !this.state.isConfirm && (
              <Form className="container-xs log" style={{ paddingTop: "0" }}>
                <Button
                  size="lg"
                  className="btn btn-danger btn-rounded btn-sm my-0"
                  block
                  style={{
                    fontFamily: "creamy coconut",
                    paddingRight: "0",
                    width: "102%",
                    fontFamily: "Creamy Coconut",
                    letterSpacing: "2px",
                    fontSize: "25px",
                    background: "#C58151",
                    border: "1px solid #C58151",
                  }}
                  onClick={this.handleSubmit}
                >
                  {this.renderButtonText()}
                </Button>
                <p
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                >
                  <span>or</span>
                  <br />
                  <a onClick={this.signupBtn} style={{ cursor: "pointer" }}>
                    <u>Register</u>
                  </a>
                </p>
              </Form>
            )}
          {this.state.isSignup && (
            <>
              <Row>
                <Col md={12} sm={12} style={{ paddingRight: "0" }}>
                  <Button
                    size="lg"
                    className="bg-gradient-theme-left border-0"
                    style={{
                      fontFamily: "creamy coconut",
                      paddingRight: "0",
                      width: "102%",
                      fontFamily: "Creamy Coconut",
                      letterSpacing: "2px",
                      fontSize: "25px",
                      background: "#C58151",
                      border: "1px solid #C58151",
                    }}
                    block
                    onClick={this.handleSignUp}
                  >
                    Register
                  </Button>
                </Col>
              </Row>

              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
              >
                <span>or</span>
                <br />
                <a onClick={this.isLoginBtn} style={{ cursor: "pointer" }}>
                  <u>Login</u>
                </a>
              </p>
            </>
          )}
          {this.state.isNewPassword && !this.state.isForgotPwd && (
            <React.Fragment>
              <h5 style={{ color: "#fff", textAlign: "center" }}>
                Reset Password
              </h5>
              <FormGroup className="Colbl">
                {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
                <Input
                  type="password"
                  name="resetpassword"
                  placeholder={passwordLabel}
                  onChange={this.handleChange}
                />
                <p
                  style={{
                    marginTop: "10px",
                    fontFamily: "open sans",
                    color: "red",
                  }}
                >
                  The minimum password length should be 8 characters
                </p>
                <Button
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  style={{ marginTop: "40px", fontFamily: "open sans" }}
                  block
                  onClick={this.handleSubmit}
                >
                  Reset Password
                </Button>
              </FormGroup>
            </React.Fragment>
          )}
          {this.state.isForgotPwd && !this.state.isForgotPwdSub && (
            <FormGroup className="Colbl">
              <h2 style={{ color: "#fff", fontSize: "20px" }}>
                {" "}
                A passcode will be sent to your email to reset password{" "}
              </h2>
              <Input
                type="email"
                name="usernamee"
                placeholder={usernameLabelUI}
                onChange={this.handleChange}
              />
              <Button
                size="lg"
                className="bg-gradient-theme-left border-0"
                style={{ marginTop: "40px" }}
                block
                onClick={this.forgotPassword}
              >
                Submit
              </Button>
            </FormGroup>
          )}
          {this.state.isConfirm && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.submitCodeSign}
            >
              Submit
            </Button>
          )}
          {this.state.isForgotPwdSub && (
            <Button
              size="lg"
              className="bg-gradient-theme-left border-0"
              block
              onClick={this.forgotPasswordSub}
            >
              Submit code
            </Button>
          )}

          {children}
        </Form>
      </div>
    );
  }
}

export const STATE_LOGIN = "LOGIN";
// export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameLabelUI: PropTypes.string,
  usernameInputProps: PropTypes.object,
  usernameInputFNProps: PropTypes.object,
  usernameInputLNProps: PropTypes.object,
  passwordLabel: PropTypes.string,

  passwordInputProps: PropTypes.object,

  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: "/",
  showLogo: true,
  usernameLabelUI: "Email",
  usernameInputPropsUI: {
    type: "email",
    name: "userid",
    value: "userid",
  },

  passwordLabel: "Password",
  passwordInputProps: {
    type: "password",
    name: "userpasswrd",
    value: "userpwd",
  },
  onLogoClick: () => {},
};

export default AuthForm;
