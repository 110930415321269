import React from 'react'
const Member = ( props ) => {
    const { name, designation, contact, description,image } = props
    return (
        <div className="member">
            <figure>
                <div className='imgDiv'>
                    <img src={image} className="img-responsive" alt={name} />
                </div>
            </figure>
            <h1
                style={{
                    marginTop: "150px",
                    color: "white",
                }}

            >{name}</h1>
            <div>{designation}</div>
            <strong>{contact}</strong>
            {description.map(rec=> <p style={{
               
                marginTop:"20px"
            }}>
                {rec}

            </p>
)}
        </div>
    )
}
export default Member