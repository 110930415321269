import React from 'react'
const Table = ( props ) => {
    const name = props.headings[0] + props.headings[1]
    return ( <ul style={{ listStyle: "none" }}>
        {props.headings.map( rec => {
            return (
                <li>
                    <label>
                        <div style={{ width: "20px", display: "inline" }} className="radio-item">
                            <input type="radio"
                                value={rec}
                                name={name}
                                style={{ opacity: 1, position: "unset", width: '18px', height: '18px', marginTop: '7px' }}
                                onChange={( e ) => {
                                    props.handleChange( e )
                                }}
                            />

                            <p style={{ display: "inline" }}> {rec}</p>

                        </div>
                        {/* <div >
                                {rec}
                            </div> */}
                    </label>
                    {/* </div>
                    {/* <div
                     onClick={( ) => {
                        props.handleChange( {target:{value:rec}} )
                    }}
                    >
                        <p style={{ marginRight: "10px" }}>{rec}</p>
                    </div> */}

                    {/* </div> */}
                </li>

            )
        } )}

    </ul>
    )
}

export default Table