import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Icon, Button, Checkbox, Spin } from 'antd';
import { connect } from 'react-redux'
import UserMiddleware from '../store/Middleware/UserMiddleWare';
import Swal from 'sweetalert2';
class NormalRegisterForm extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			fullname: '',
			email: '',
			password: ''
		};

		this.update = this.update.bind( this );

		this.displayLogin = this.displayLogin.bind( this );
	}

	update( e ) {
		let name = e.target.name;
		let value = e.target.value;
		this.setState( {
			[name]: value
		} );
	}


	displayLogin( e ) {
		e.preventDefault();
		console.log( 'You have successfully registered' );
		console.log( this.state );
		this.setState( {
			fullname: '',
			email: '',
			password: ''
		} );
	}
	compareToFirstPassword = ( rule, value, callback ) => {
		const { form } = this.props;
		if ( value && value !== form.getFieldValue( 'password' ) ) {
			callback( 'Two passwords that you enter is inconsistent!' );
		} else {
			callback();
		}
	};

	validateToNextPassword = ( rule, value, callback ) => {
		const { form } = this.props;
		if ( value && this.state.confirmDirty ) {
			form.validateFields( ['confirm'], { force: true } );
		}
		callback();
	};
	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields( ( err, values ) => {
			if ( !err ) {
				const { email, password, mobile } = values
				this.props.registerUser( { email, password, mobile } );
				console.log( 'Received values of form: ', values );
			}
		} );
	};
	showErrorMessage = () => {
		Swal.fire(
			'Email Already Exist!',
			"you cant't register with this email",
			'error'
		)
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		const { isLoading, errorMessage, } = this.props
		return (
			<div className="container">
				<div className="register">
					<Spin spinning={isLoading}>
						{errorMessage !== null && this.showErrorMessage()}
						<Form onSubmit={this.handleSubmit} className="login-form">
							<div class="logo">
								{/* <img src={require( "./../images/logo.svg" )} width="120px" height="40px" /> */}
							</div>
							<Form.Item label="E-mail">
								{getFieldDecorator( 'email', {
									rules: [
										{
											type: 'email',
											message: 'The input is not valid E-mail!',
										},
										{
											required: true,
											message: 'Please input your E-mail!',
										},
									],
								} )( <Input /> )}
							</Form.Item>
							<Form.Item label="Mobile Number">
								{getFieldDecorator( 'mobile', {
									rules: [{ required: true, message: 'Please input your Mobile!' }],
								} )(
									<Input
										placeholder="Mobile Number"
									/>,
								)}
							</Form.Item>
							<Form.Item label="Password" hasFeedback>
								{getFieldDecorator( 'password', {
									rules: [
										{
											required: true,
											message: 'Please input your password!',
										},
										{
											validator: this.validateToNextPassword,
										},
									],
								} )( <Input.Password /> )}
							</Form.Item>
							<Form.Item label="Confirm Password" hasFeedback>
								{getFieldDecorator( 'confirm', {
									rules: [
										{
											required: true,
											message: 'Please confirm your password!',
										},
										{
											validator: this.compareToFirstPassword,
										},
									],
								} )( <Input.Password onBlur={this.handleConfirmBlur} /> )}
							</Form.Item>
							<Form.Item>

								<Button type="primary" htmlType="submit" className="login-form-button">
									Sign Up
          				</Button>

							</Form.Item>
						</Form>

						<Link to="/">Login</Link>
					</Spin>
				</div>
			</div>
		);
	}
}
const Register = Form.create( { name: 'normal_register' } )( NormalRegisterForm );
const mapStateToPeops = ( state ) => {
	return {
		isLoading: state.userReducer.isLoading,
		errorMessage: state.userReducer.emailErrorMessage,


	}
}
const mapDispatchToProps = ( dispatch ) => {
	return {
		registerUser: ( data ) => {
			return dispatch( UserMiddleware.registerUser( data ) )
		}

	}
}

export default connect( mapStateToPeops, mapDispatchToProps )( Register );
