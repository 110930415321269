import React, { Component, useEffect, useState, useRef } from "react";

import "./App.css";
import "./menu.css";
import "antd/dist/antd.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, Redirect, Switch } from "react-router-dom";
import Login from "./component/Login";
import Register from "./component/Register";
import { connect } from "react-redux";
import Navbar from "./component/navBar/Navbar";
import Footer from "./component/footer/Footer";
import About from "./component/about/About";
import Events from "./component/events/Events";
import Contact from "./component/contact/Contact";
import SupplierDetails from "./component/supplierDetails/SupplierDetails";
import EventRegisteration from "./component/eventRegisterartion/EventRegisteration";
import Survey from "./component/survey/Survey";
import PDFViewer from "./component/pdfViewer/PDFViewer";
import Water from "./component/water/Water";
import Community from "./component/community/Community";
import Land from "./component/land/Land";
import Fire from "./component/fire/Fire";
import Language from "./component/language/Language";
import Resources from "./component/resources/Resources";
import AuthForm, { STATE_LOGIN } from "./component/Auth/AuthForm";
import AuthPage from "./component/Auth/AuthPage";
import Users from "./component/usermanagement/Users";
import Dashboard from "./component/dashborad/Dashboard";
import Donation from "./component/donation/Donation";
import Help from "./component/help/Help";
import Forum from "./component/forum/Forum";
import SuppliersPage from "./component/dashborad/SuppliersPage";
import Awa from "./component/dashborad/Awa";
import EventUsers from "./component/dashborad/EventUsers";
import SurveysPage from "./component/dashborad/SurveysPage";
import NewUsers from "./component/usermanagement/NewUsers";
import UserManagement from "./component/usermanagement/UserManagement";
import Location from "./component/usermanagement/Location";
export default function App() {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [timerId, setTimerId] = useState(false);

  //   useEffect(() => {
  //     const autoLogout = () => {
  //       if (sessionStorage.getItem("username")) {
  //         if (document.visibilityState === "hidden") {
  //           // set timer to log user out
  //           const id = window.setTimeout(() => setIsLoggedOut(true), 10 * 1000);
  //           setTimerId(id);
  //         } else {
  //           // clear existing timer
  //           window.clearTimeout(timerId);
  //         }
  //       }

  //       document.addEventListener("visibilitychange", autoLogout);

  //       return () => document.removeEventListener("visibilitychange", autoLogout);
  //     };
  //   }, [timerId]);
  //   useEffect(() => {
  //     if (isLoggedOut == true) {
  //       alert("Session time out, please sign in again");
  //       sessionStorage.clear();
  //       localStorage.clear();
  //       console.log("logout succesfully");
  //       // navigate("/login");
  //       document.location.replace("/login");
  //     }
  //   });
  //   console.log(isLoggedOut);
  //   render() {
  const token = localStorage.getItem("token");
  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/aboutus" component={About} />
        <Route exact path="/" component={About} />

        <Route exact path="/events" component={Events} />
        <Route exact path="/contact" component={Contact} />

        <Route exact path="/suppliers" component={SupplierDetails} />
        <Route
          exact
          path="/events-registeration"
          component={EventRegisteration}
        />
        <Route exact path="/survey-form" component={Survey} />
        <Route exact path="/water" component={Water} />
        <Route exact path="/community" component={Community} />
        <Route exact path="/land" component={Land} />
        <Route exact path="/fire" component={Fire} />
        <Route exact path="/language" component={Language} />
        <Route exact path="/resources" component={Resources} />
        <Route exact path="/users" component={NewUsers} />
        <Route exact path="/dashboard" component={Awa} />
        <Route exact path="/donations" component={Donation} />
        <Route exact path="/pdf/:name" component={PDFViewer} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/forum" component={Forum} />

        <Route
          exact
          path="/login"
          component={(props) => <AuthPage {...props} authState={STATE_LOGIN} />}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/supplierspage" component={SuppliersPage} />
        <Route exact path="/awa" component={Awa} />
        <Route exact path="/registeredUsers" component={EventUsers} />

        <Route exact path="/surveys" component={SurveysPage} />
        <Route exact path="/newusers" component={NewUsers} />
        <Route exact path="/user-management" component={UserManagement} />
        <Route exact path="/location" component={Location} />

        {/* <PrivateRoute exact path="/home" component={GameList}
						token={token}
					/>
					<PrivateRoute exact path="/create-game" component={CreateGame}
						token={token}
					/>
					<PrivateRoute exact path="/my-games" component={MyGames}
						token={token} /> */}
      </Switch>
      {/* {window.location.href.includes( "water" ) ?null: } */}
    </React.Fragment>
  );
  //   }
}
const PrivateRoute = ({ component: Component, token, ...rest }) => {
  console.log("current user===>", token);
  console.log(this);
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticate(token) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const authenticate = (token) => {
  console.log("current user===>", token);
  if (token !== null) {
    return true;
  }
  return false;
};
const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
  };
};
// export default connect(mapStateToProps, null)(App);
// export default componentQueries( query )( App );
