export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const REGISTER_USER_SUCCESSFULL = "REGISTER_USER_SUCCESSFULL";



export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_SUCCESSFULL = "LOGIN_USER_SUCCESSFULL"
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED"

// static LOGOUT = "LOGOUT";

export const registerUser = ( data ) => {

    console.log( "action ===>", REGISTER_USER_FAILED )
    return {
        type: REGISTER_USER,
        data,
    }
}
export const registerUserSuccessfull = ( data ) => {
    return {
        type: REGISTER_USER_SUCCESSFULL,
        data,
        success: true,
    }
}
export const registerUserFailed = ( data ) => {
    return {
        type: REGISTER_USER_FAILED,
        data,
        success: false
    }
}
export const loginUser = ( data ) => {
    return {
        type: LOGIN_USER,
        data,
    }
}
export const loginUserSuccessfull = ( data ) => {
    return {
        type: LOGIN_USER_SUCCESSFULL,
        data,
        success: true
    }
}
export const loginUserFailed = ( data ) => {
    return {
        type: LOGIN_USER_FAILED,
        data,
        success: false,
    }
}

