import React, { useState } from 'react'
import { OverlayTrigger, Popover, Row, Col, Image, Modal } from 'react-bootstrap'
import { GiSpeaker } from 'react-icons/gi'
import { MdTranslate } from 'react-icons/md'
import { animalFullData, countryFullData, cultureFullData, familyFullData, weatherFullData } from './data'
function MyVerticallyCenteredModal( props ) {
    console.log( "props", props )
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header> */}
            <Modal.Body>
                <div className="card" style={{ width: "100%", padding: "60px" }}>
                    <div className="wiki-list-modal">
                        <Row><h1 className="card-title">{props.title}</h1></Row>
                        <Row>

                            {props.selectedData.map( rec => {

                                return <Col sm={12} md={4} lg={4} className="wiki-item">
                                    {rec.lang}
                                    <div style={{ display: "inline", float: "right" }}>
                                        <OverlayTrigger
                                            placement="left"
                                            trigger="click"
                                            rootClose
                                            overlay={(
                                                <Popover>
                                                    <Popover.Title as="h1">
                                                        {rec.lang}
                                                    </Popover.Title>
                                                    <Popover.Content>
                                                        {rec.English}
                                                    </Popover.Content>
                                                </Popover>
                                            )}>
                                            <span style={{ marginRight: "13px" }} ><MdTranslate /></span>
                                        </OverlayTrigger>

                                        <span style={{ float: "right", marginRight: "13px" }}><GiSpeaker /></span>

                                    </div>

                                </Col>
                            } )}
                            {/* <button className="wiki-item" >See More</button> */}
                        </Row>
                    </div>


                </div>
            </Modal.Body >
        </Modal >
    );
}
const WikiList = ( props ) => {
    // const [selectedImage, setSelectedImage] = useState( "" )
    const [selectedData, setSelectedData] = useState( [] )
    const [modalShow, setModalShow] = useState( false );
    const [title, setTitle] = useState( "" );

    const { data, id } = props
    const handleClick = ( clickedId ) => {
        switch ( clickedId ) {
            case "animal":
                setSelectedData( animalFullData )
                setModalShow( true )
                setTitle( clickedId )
                break;
            case "country":
                setSelectedData( countryFullData )
                setModalShow( true )
                setTitle( clickedId )

                break;
            case "culture":
                setSelectedData( cultureFullData )
                setModalShow( true )
                setTitle( clickedId )
                break;
            case "family":
                setSelectedData( familyFullData )
                setModalShow( true )
                setTitle( clickedId )

                break;
            case "weather":
                setSelectedData( weatherFullData )
                setTitle( clickedId )
                setModalShow( true )
                break;
            default:
                break;
        }
    }
    return <React.Fragment>{selectedData.length ? <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow( false )}
        title={title}
        selectedData={selectedData}

    /> : null}<div className="wiki-list">
            <ul style={{listStyle: "none"}}>
                {data.map( rec => {

                    return <li className="wiki-item">
                        {rec.lang}
                        <div style={{ display: "inline", float: "right" }}>
                            <OverlayTrigger
                                placement="left"
                                trigger="click"
                                rootClose
                                overlay={(
                                    <Popover>
                                        <Popover.Title as="h1">
                                            {rec.lang}
                                        </Popover.Title>
                                        <Popover.Content>
                                            {rec.English}
                                        </Popover.Content>
                                    </Popover>
                                )}>
                                <span style={{ marginRight: "13px" }} ><MdTranslate /></span>
                            </OverlayTrigger>

                            <span style={{ float: "right", marginRight: "13px" }}><GiSpeaker /></span>

                        </div>

                    </li>
                } )}
                <button style={{ color:"#5E8564" }} className="wiki-item" onClick={() => { handleClick( id ) }}>See More</button>
            </ul>

        </div></React.Fragment>
}
export default WikiList