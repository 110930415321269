import * as actions from "../actions/userActions";

function userReducer( state = {
    isLoading: false,
    success: false,
    isError: false,
    user: null,
    edit: false,
    currentUser: null,
    emailErrorMessage: null,
}, action ) {
    switch ( action.type ) {

        case actions.REGISTER_USER:
            console.log( "reducer===>", action.type );
            return {
                ...state,
                isLoading: true,
            }
        case actions.REGISTER_USER_SUCCESSFULL:
            return {
                ...state,
                success: action.success,
                isLoading: false,
            }
        case actions.REGISTER_USER_FAILED:
            console.log( "error", action.data )
            return {
                ...state,
                isError: true,
                isLoading: false,
                emailErrorMessage: action.data.error,

            }
        case actions.LOGIN_USER:
            return {
                ...state,
                isLoading: true,
            }

        case actions.LOGIN_USER_SUCCESSFULL:
            return {
                ...state,
                isLoading: false,
                user: action.data.user
            }
        case actions.REGISTER_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state
    }
}
export default userReducer;