import AuthForm, { STATE_LOGIN } from './AuthForm';
// import '../components/Layout/sidebar.js'
// import Page from 'components/Page';
// import logo from '../assets/img/logo/logo.png';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import Footer from '../footer/Footer';

class AuthPage extends React.Component {
  componentDidMount(){
    setTimeout(() => {
            
      window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
  }, 40);

window.scrollTo( 0, 0 )
  }

  handleAuthState = authState => {
    if ( authState === STATE_LOGIN ) {
      this.props.history.push( '/login' );

    } else {
      this.props.history.push( '/signup' );
    }
  };

  handleLogoClick = () => {
    this.props.history.push( '/' );
  };


  render() {
    console.log( this.props )
    const pathname = window.location.pathname;
    console.log( pathname );
    if ( this.props.authState == 'LOGIN' ) {
      return (
        // <Page title="Log In">
        <Row
          style={{
            backgroundColor: "rgb(37,36,36)",
            width: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            margin: "0px"
          }}>
          {/**/}
          {/* <Col md={2}> <img style={{width:'100%'}} src={logo}/> </Col> */}
          <Col md={7} lg={5} className='loginSign'>

            <div style={{ backgroundColor: 'rgb(37,36,36)' }}>

              <AuthForm
                authState={this.props.authState}
                history={this.props.history}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
              />
            </div>
          </Col>
          <Footer />

        </Row>
        // </Page>
      )
    }
    else {
      return (
        // <Page title="Register / Sign Up">
        <Row
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: "0px",
            width: "100%",


          }}>
          <Col md={8} lg={8} style={{ padding: '1.25rem' }} >
            <Card body style={{ backgroundColor: "rgb(50,50,50)", padding: '1.25rem' }}>
              <AuthForm
                authState={this.props.authState}
                historypage={this.props.history}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
              />
            </Card>
          </Col>
          <Footer />

        </Row>
        // </Page>
      )
    }



  }
}

export default AuthPage;
