import React from 'react'
import { Component } from 'react';
import { Carousel, Col, Container, Navbar, NavbarBrand, Row } from 'react-bootstrap';
import './Home.css'
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
// import WECover from "../../images/testflow/WECover.png"
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import FooterWater from '../footer/FooterWater';


const imageData = [
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/TFOne.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/TFTwo.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/TFThree.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/TFFour.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/CFFive.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/CFSix.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/CFSeven.PNG',
    'https://cdn.dev.kaiejin.org.au/static-content/testflow/CFEight.PNG'
]

class Water extends Component {
    componentDidMount = () => {
        window.scrollTo( 0, 0 )
    }
    render() {
        return (
            <>
                <div className="root-water">
                    <section id="heroWater">
                        <div className="heroWater-container" data-aos="zoom-in" data-aos-delay="100">
                            <div className="exploreOne">
                                <p className="typoOne">Water</p>
                                <h4 className="explore-typo">KAIEJIN</h4>
                            </div>
                        </div>
                    </section>
                    <div className="fixed-bottom">
                        <Navbar dark>
                            <Container className='nav-bot'>
                                <a href="#cflow" style={{ textDecoration: "none", fontFamily: "Creamy Coconut",  color: "#95cced" }} className="bottom-nav">Cultural Flows</a>
                                <a href="#margooya" style={{ textDecoration: "none", fontFamily: "Creamy Coconut",  color: "rgb(59,94,63)" }} className="bottom-nav">Margooya Lagoon</a>
                                <a href="#testflow" style={{ textDecoration: "none", fontFamily: "Creamy Coconut", color: "rgb(169,134,108)" }} className="bottom-nav">2020 Test Flow</a>
                                <a href="#awa" style={{ textDecoration: "none", fontFamily: "Creamy Coconut", color: "rgb(210,142,97)" }} className="bottom-nav">Aboriginal Waterways Assessment</a>

                            </Container>
                        </Navbar>
                    </div>
                    <section id="about">
                        <div style={{ overflow: "hidden" }}>
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" id="cflow">Cultural Flows</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%" }}>
                                <div className=" start ">

                                    <p className="" style={{ opacity: ".99" }}>
                                        Cultural Flows are water entitlements that are legally and beneficially owned by Indigenous Nations of a sufficient and adequate quantity and quality to improve the spiritual, cultural, environmental, social and economic conditions of those Indigenous Nations. This is our inherent right.
                                    </p>
                                    <blockquote style={{ padding: "15px 100px" }} className='blackQuoteClass'>
                                        <h1 className="headWaterPage" style={{ opacity: ".99" }}><q>
                                            We need to be responsible for the entire process. From site selection and water management, all the way through to the project monitoring. <br />
                                            This way we can care for Country using traditional practices so that our cultural and ecological knowledge is not lost.
                                        </q></h1>

                                        <footer className="flt-rt">
                                            <span style={{ opacity: ".99" }}>― Brendan Kennedy,
                                                <cite> Tati Tati Kaiejin Director & Tati Tati Elder.</cite></span>
                                        </footer>
                                    </blockquote>

                                </div>
                            </Row>
                            <Row id="portfolio" className="portfolio explore cultralClass" style={{ padding: "0px 7%", marginTop: "20px" }}>

                                <Carousel variant="dark" controls={false} >
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={"https://cdn.dev.kaiejin.org.au/static-content/culturalFlow/cflowThree.jpg"}
                                            alt="First slide"

                                        />
                                        <Carousel.Caption>

                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            </Row>
                            <Row id="portfolio" className="portfolio explore culturalTxt" style={{ padding: "0px 7%", marginTop: "20px" }}>
                                <div className="card-body start " style={{ opacity: ".99" }}>
                                    <p>
                                        The Cultural Flows methodology and state-wide project comes after the release of the Echuca Declaration in 2007; a ground-breaking statement that outlined First Nations' rights and aspirations in water management. The declaration states that each of the Indigenous Nations represented within Murray and Lower Darling Rivers Indigenous Nations is and has been, since time immemorial, sovereign over its own lands and waters and that the people of each Indigenous Nation obtain and maintain their spiritual and cultural identity, life and livelihood from their lands and waters. More information on Cultural Flows can be found in our Resources tab.
                                    </p>
                                    <h1 className="headWaterPage" style={{ margin: "25px 25px 15px" }}>
                                        Water is central to the cultural, social and spiritual identity of First Nations people..</h1>
                                    <p>
                                        It further asserts that water has a right to be recognised as an ecological entity, a being, and a spirit, and must be treated accordingly. For the Indigenous Nations water is essential to creation and Dreaming, with many ancestral beings created by and dwelling within water.
                                    </p>
                                </div>

                            </Row>
                            <Row id="portfolio" className="portfolio explore waterAss" style={{ padding: "0px 10%", marginTop: "40px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" >Cultural Flows at Margooya Lagoon </h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ padding: "0px 7%", marginTop: "20px" }}>
                                <div className="d-flex justify-content-center" style={{ zIndex: "999" }}>
                                    <ReactPlayer
                                        light={"https://cdn.dev.kaiejin.org.au/static-content/eja/CFthumb.JPG"}
                                        controls={true}
                                        url="https://cdn.dev.kaiejin.org.au/video/Cultural%20Flows%20at%20Margooya%20Lagoon.mp4"
                                    />
                                </div>
                            </Row>
                            {/* Margooya lagoona */}
                            <Row id="portfolio" className="portfolio explore flowsPlan" style={{ padding: "0px 10%", marginTop: "0px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" id="margooya">Cultural Flows Management Plan - Margooya Lagoon</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="portfolio" className="portfolio explore start" style={{ padding: "0px 7%", marginTop: "0px", opacity: "0.99", }}>

                                <p>In 2020-21 Tati Tati First Nations, in partnership with MLDRIN and Tati Tati Kaiejin, developed the first ever Cultural Flows Management Plan (CFMP) for Margooya Lagoon, Vic. </p>
                            </Row>
                            <Row style={{ padding: "0px 7%", }}>

                                <Col sm="12" md="6" xs="12">

                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/about/aboutOne.jpg"}
                                                alt="First slide"

                                            />
                                            <Carousel.Caption>

                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                                <Col sm="12" md="6" xs="12" >
                                    <div className="card-body-water" style={{ textAlign: "start", color: "white", marginTop: '60px' }} >

                                        <p className="" style={{ paddingTop: '15px' }}>
                                            Cultural Flows is a lifelong concept. As such, the CFMP for Margooya Lagoon is an ongoing and live document that will grow and adapt as the people and Country that it concerns grow and change. The plan outlines Tati Tati values, foundational needs, short and long-term outcomes, and nation aspirations for mob and Country. It also details a pathway for First Nations’ cultural water uses and management for the Lagoon, with recommendations and next steps for water rights and allocations.
                                        </p>


                                    </div>
                                </Col>

                            </Row>

                            <Row style={{ padding: "0px 7%", }}>
                                <Col sm="12" md="8" xs="12" style={{ marginTop: "15%" }}>
                                    <div className="card-body-water-two PadImg" style={{ textAlign: "start", color: "white" }}>

                                        <AliceCarousel autoPlay={false} >
                                            <div>

                                                <p style={{ paddingTop: '15px' }}>
                                                    Through the continuation and attainment of Cultural Flows, Tati Tati demand and aspire to achieve sovereign control of water management on Country to uphold responsibilities of caring for Country and protect and nurture Tati Tati culture.
                                                </p>
                                                <p>
                                                    Click the image (right) to view the full Cultural Flows Management Plan for Margooya Lagoon.
                                                </p>
                                                <p>
                                                    The results were symbolic of the sense of powerlessness Tati Tati Wadi Wadi expressed during aspects of the water delivery processes; from the planning and proposal stages to the delivery of water on Country.
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    Added to that was the resulting black water event and the feeling of ‘doing things the wrong way’ that impacted negatively to the overall well-being of the Traditional Owners involved.
                                                </p>
                                                <p style={{ color: "#95cced" }}>
                                                    The following documentary was created by Tati Tati Kaiejin to document the journey, learnings, outcomes and experiences of their first water allocation on Tati Tati Country.
                                                </p>
                                                <p style={{ color: "#95cced" }}>
                                                    We also share the knowledge for the health and well-being of waterways, culture, traditional practices and peoples in the hopes of connecting with others to further First Nations goals and aspirations. •
                                                </p>
                                            </div>
                                        </AliceCarousel>

                                    </div>
                                </Col>
                                <Col sm="12" md="4" xs="12">

                                    <Carousel variant="dark" controls={false} style={{ marginTop: "80px" }} >
                                        <Carousel.Item>
                                            <Link to="/pdf/Margooya Lagoon Cultural Flows Management Plan"> <img
                                                className="d-block w-100"
                                                src={'https://cdn.dev.kaiejin.org.au/static-content/resources/images/MLCCover.jpg'}
                                                alt="First slide"
                                            // style={{ height: "550px" }}

                                            />
                                            </Link>

                                        </Carousel.Item>



                                    </Carousel>
                                </Col>



                            </Row>
                            {/* Establishing a Cultural */}
                            <Row id="portfolio" className="portfolio explore bookCover" style={{ padding: "0px 10%" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage">Establishing a Cultural Flows model on Tati Tati Country</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%", marginTop: "20px" }}>
                                <Col sm="12" md="4" xs="12">
                                    <Carousel variant="dark" controls={false}>

                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={'https://cdn.dev.kaiejin.org.au/static-content/resources/images/ECCover.jpg'}
                                                alt="Third slide"
                                            // height="500px"
                                            />

                                        </Carousel.Item>

                                    </Carousel>
                                </Col>

                                <Col sm="12" md="8" xs="12">
                                    <div className="card-body-water" style={{ marginTop: '0px' }}>
                                        <p className="jt-init">
                                            For Margooya Lagoon, there are range of legal and policy responses available to design and advance Cultural Flows. This report compiled by Environmental Justice Australia summarises key legal and policy matters requiring consideration of a Cultural Flows program for Margooya Lagoon.
                                        </p>
                                        <p>
                                            The report also proposes three sets of ‘strategic opportunities’ relevant to using law and policy to advance Cultural Flows at Margooya Lagoon to achieve revitalisation of Country and connection to Country on this part of the Murray River floodplain.
                                        </p>
                                        <p className="ma-t-30">
                                            Environmental Justice Australia is a not-for-profit public interest legal practice who pursue new and innovative solutions to fill the gaps and fix the failures in our legal system to clear a path for a more just and sustainable world.
                                        </p>

                                    </div>

                                </Col>
                            </Row>
                            {/*Cultural Water for Cultural Economies     */}
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%", marginTop: "20px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" id="cflow">Cultural Water for Cultural Economies   </h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ padding: "0px 7%", marginTop: "-73px" }}>
                                <Col sm="12" md="8" xs="12" style={{ marginTop: "80px" }}>
                                    <div className=" PadImg" style={{ textAlign: "start", color: "white" }}>
                                        <AliceCarousel autoPlay={false}>
                                            {/* <div> */}
                                            <p className="" style={{ opacity: ".99", fontSize: "16px", marginTop: "5%", }}>
                                                The Cultural Water for Cultural Economies project is a partnership between the Murray Lower Darling Rivers Indigenous Nations (MLDRIN) and University of Melbourne, as well as representatives from 20 Traditional Owner organisations and First Nations across Victoria. This project is an important step towards water justice for Aboriginal people, who currently own less than 1% of water rights in Australia’s Murray-Darling Basin. Lack of access to water deprives Aboriginal people of opportunities to exercise self-determination, care for Country, and generate wealth from agricultural production.
                                            </p>
                                            <div>
                                                <blockquote style={{ padding: "15px 100px" }}>
                                                    <h3 className="headWaterPage" style={{ opacity: ".99" }}><q>
                                                        Water is a living being and should be treated accordingly. Many of our ancestral beings are created by and live in water..
                                                    </q></h3>

                                                    <footer className="flt-rt">
                                                        <span style={{ opacity: ".99" }}>– Echuca Declaration, 2010
                                                        </span>
                                                    </footer>
                                                </blockquote>
                                                <p className="" style={{ opacity: ".99" }}>
                                                    As part of the Cultural Water for Cultural Economies project, representatives from 20 Traditional Owners and First Nations were resourced to attend over 40 workshops and meetings from December 2018 to March 2021.
                                                </p>
                                            </div>
                                        </AliceCarousel>

                                    </div>
                                </Col>
                                <Col sm="12" md="4" xs="12">

                                    <Carousel variant="dark" controls={false} style={{ marginTop: "80px" }} >
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={'https://cdn.dev.kaiejin.org.au/static-content/resources/images/CWCover.jpg'}
                                                alt="First slide"


                                            />


                                        </Carousel.Item>



                                    </Carousel>
                                </Col>

                            </Row>
                            <Row id="portfolio" className="portfolio exploreOne" style={{ padding: "0px 7%", marginTop: "25px" }}>
                                <Col sm="12" md="6" xs="12" >
                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img

                                                className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/testflow/WE.JPG"}
                                                alt="Second slide"
                                            // style={{ height: "600px" }}

                                            />
                                        </Carousel.Item>


                                    </Carousel>
                                </Col>
                                <Col sm="12" md="6" xs="12">
                                    <div className="card-body-water" style={{ marginTop: "3px" }}>
                                        <AliceCarousel autoPlay={false}>
                                            <div>
                                                <h3 className="card-title" style={{ color: "white" }}>Key Questions: </h3>
                                                <p>

                                                    <ul style={{ listStylePosition: "outside" }}>
                                                        <li style={{
                                                            // textIndent: "-2.5em",
                                                            // paddingLeft: "2.5em"
                                                        }}>How can Traditional Owners access water?</li>
                                                        <li style={{
                                                            // textIndent: "-2.5em",
                                                            // paddingLeft: "2.5em"
                                                        }}>How can water be acquired or transferred to Traditional Owner organisations?</li>
                                                        <li style={{
                                                            // textIndent: "-2.5em",
                                                            // paddingLeft: "2.5em"
                                                        }}>How can Traditional Owners engage in water trade and benefit from water markets?</li>
                                                        <li style={{
                                                            // textIndent: "-2.5em",
                                                            // paddingLeft: "2.5em"
                                                        }}>What are the appropriate governance frameworks for Traditional Owners to hold and manage water?</li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <div className="respOut">
                                                <h3 className="card-title " style={{ color: "white" }}>  Outcomes: </h3>
                                                <p>
                                                    The project has identified four main pathways to increase water access for Traditional Owners and First Nations in Victoria:
                                                </p>
                                                <p>

                                                    <ol style={{}}>
                                                        <li style={{
                                                            textIndent: "-2.5em",
                                                            paddingLeft: "2.5em"
                                                        }}>Increasing use of existing rights to water</li>
                                                        <li style={{
                                                            textIndent: "-2.5em",
                                                            paddingLeft: "2.5em"
                                                        }}>The use of unallocated water.  </li>
                                                        <li style={{
                                                            textIndent: "-2.5em",
                                                            paddingLeft: "2.5em"
                                                        }}>Treated, fit-for-purpose recycled water.  </li>
                                                        <li style={{
                                                            textIndent: "-2.5em",
                                                            paddingLeft: "2.5em"
                                                        }}>Water reallocation via purchase or other agreement. </li>
                                                    </ol>
                                                </p>
                                            </div>


                                        </AliceCarousel>



                                    </div>

                                </Col>

                            </Row>
                            {/* Cultural flow */}
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "25px 10% 0px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start" id="testflow">
                                        <h1 className="jt-init headWaterPage">2020 Test Flow</h1>

                                    </div>

                                </Col>

                            </Row>
                            <Row style={{ padding: "0px 7%", }}>
                                <Col sm="12" md="6" xs="12">

                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/testflow/testFlowOne.jpg"}
                                                alt="First slide"
                                            // style={{ height: "600px" }}
                                            />
                                            <Carousel.Caption>

                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                                <Col sm="12" md="6" xs="12" >
                                    <div className="card-body-water" style={{ textAlign: "start", color: "white" }} >
                                        <h3 className="card-titleOne" style={{ color: "white" }}>Overview</h3>
                                        <AliceCarousel autoPlay={false}>
                                            <p className="" style={{ paddingTop: '15px' }}>
                                                In November 2020, a 15 million litres allocation of environmental water was committed and delivered to a floodplain creek at the Murray River side of the Margooya Lagoon wetland. The creek was previously one of three main outlets connected to and fed the Lagoon; however, this no longer occurs due to strict river and lagoon regulations of recent decades. The watering event was a way to simulate this original inundation of the creek and help revive the biodiversity of the surrounding area.
                                            </p>
                                            <div>

                                                <p>
                                                    A diesel pump was installed for the water to pe pumped over 10 days at a rate of 1.5ML per day over the raised riverbanks, nearby dirt road, and into what is now the beginning of the creek bed.
                                                </p>
                                                <p>
                                                    A monitoring and evaluation survey, created by the Tati Tati Aboriginal Water Officers, was used over five weeks to survey and record the outcomes of the watering event, now referred to as “Test Flow”, on various key indicators, as determined based on nation values, goals, and outcomes.
                                                </p>
                                            </div>
                                        </AliceCarousel>

                                    </div>
                                </Col>

                            </Row>
                            <Row style={{ padding: "0px 7%", }}>
                                <Col sm="12" md="6" xs="12" style={{ marginTop: "60px" }}>
                                    <div className="card-body-water-two" style={{ textAlign: "start", color: "white", padding: '40px 30px 27px' }}>
                                        <h3 className="card-titleOne" style={{ color: "white" }}>Results</h3>
                                        <AliceCarousel autoPlay={false}>
                                            <div>
                                                <p style={{ paddingTop: '15px' }}>
                                                    The Test Flow was a great opportunity for Tati Tati peoples to learn how water moves on Country, implement traditional practices, as well as gaining experience for the group with monitoring and evaluation surveying.
                                                </p>
                                                <p>
                                                    Results from the surveys demonstrate increases in the overall health of culturally significant plants and overall abundance of culturally significant animals, however results indicated there was an overall decrease in values of cultural use and nation well-being.
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    The results were symbolic of the sense of powerlessness Tati Tati Wadi Wadi expressed during aspects of the water delivery processes; from the planning and proposal stages to the delivery of water on Country. Added to that was the resulting black water event and the feeling of ‘doing things the wrong way’ that impacted negatively to the overall well-being of the Traditional Owners involved.
                                                </p>
                                                <p>
                                                    The following documentary was created by Tati Tati Kaiejin to document the journey, learnings, outcomes and experiences of their first water allocation on Tati Tati Country.
                                                </p>
                                            </div>

                                        </AliceCarousel>
                                    </div>
                                </Col>
                                <Col sm="12" md="6" xs="12">

                                    <Carousel variant="dark" style={{ marginTop: "80px" }} >
                                        {imageData.map( rec => <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={rec}
                                                alt="First slide"


                                            />

                                        </Carousel.Item>

                                        )}




                                    </Carousel>
                                </Col>



                            </Row>
                            <Row id="portfolio" className="portfolio exploreOne" style={{ padding: "0px 7%", marginTop: "25px" }}>
                                <Col sm="12" md="6" xs="12" >
                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img

                                                className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/testflow/testFlowTwo.jpg"}
                                                alt="Second slide"
                                            // style={{ height: "600px" }}

                                            />
                                        </Carousel.Item>


                                    </Carousel>
                                </Col>
                                <Col sm="12" md="6" xs="12">
                                    <div className="card-body-water" style={{ marginTop: "0px", padding: '40px 30px 0px' }}>
                                        <h3 className="card-title" style={{ color: "white" }}>Concerning issue – First Nations exclusion from decision making processes. </h3>
                                        <AliceCarousel autoPlay={false}>
                                            <div>
                                                <p className="">
                                                    The 2020 Test Flow came about due to discussions with Tati Tati Wadi Wadi (TTWW) and a Mallee CMA representative at TTWW's March 2020 Cultural Flows workshop. Following on from these discussions, a proposal from the Mallee CMA was put forward to, and approved by, the Victorian Environmental Water Holder (VEWH).
                                                </p>
                                                <p>
                                                    The Test Flow was a chance for Tati Tati Wadi Wadi to act on ensuring water is delivered on Country in culturally appropriate ways. While the project was a positive way to promote cultural practices, come together on Country, and improve environmental outcomes, there were many key aspects of the planning that the Traditional Owners were not involved in. These include, and are not limited to, decisions surrounding:

                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <ul style={{ listStyle: "inside" }}>
                                                        <li>The volume of the water allocation, </li>
                                                        <li>The location of the water delivery event, </li>
                                                        <li>How water was to be delivered (i.e. the use of the pump), </li>
                                                        <li>And the reporting and promotion of the watering event, prior to Traditional Owners being informed of it. </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    TTWW feels it is important to state here that they were only enquiring about the process of environmental watering and were not giving any organisation permission to act on their behalf. In doing so, TTWW were explicitly left out of the proposal, planning, and approval stages of the watering event.
                                                </p>
                                            </div>
                                            <div>
                                                <h2 style={{ color: "#95cced" }}><q>These are fundamental components of water delivery that must have Traditional Owner involvement.</q></h2>
                                                <p>While Tati Tati Kaiejin’s relationship with the VEWH is building, with commitments from them to come to the table and support our goals of caring for waterways, all stakeholder and government efforts and commitments must become genuine and consistent throughout all steps of watering events and projects on Tati Tati Country – for the benefit to all. </p>

                                            </div>


                                        </AliceCarousel>



                                    </div>

                                </Col>

                            </Row>
                            <Row id="portfolio" className="portfolio explore waterAss" style={{ padding: "0px 10%", marginTop: "40px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" >Margooya Lagoon Test Flow 2020 </h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ padding: "0px 7%", marginTop: "50px" }}>

                                <div className="d-flex justify-content-center" style={{ zIndex: "999" }}>
                                    <ReactPlayer
                                        light={"https://cdn.dev.kaiejin.org.au/static-content/eja/CFthumbtwo.JPG"}
                                        controls={true}
                                        url="https://cdn.dev.kaiejin.org.au/video/Margooya Lagoon Test Flow 2020.mp4"
                                    />
                                </div>
                            </Row>

                            <Row id="portfolio" className="portfolio explore waterAss" style={{ padding: "0px 10%", marginTop: "40px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body start">
                                        <h1 className="jt-init headWaterPage" id="awa">Aboriginal Waterways Assessment (AWA)</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%" }}>
                                <Col sm="12" md="8" xs="12">
                                    <div className="card-body-water-two" style={{ textAlign: "start" }} >
                                        <AliceCarousel autoPlay={false}>
                                            <div>
                                                <p className="">
                                                    The Aboriginal Waterways Assessment (AWA) is a tool for Aboriginal communities to consistently measure and prioritise river and wetland health to better negotiate for the needs of water on Country. developed collaboratively by the Murray Lower Darling Rivers Indigenous Nations (MLDRIN), it is used to capture and record information about the cultural values, uses, and health of waterways and wetlands to assist Aboriginal people to be more meaningfully involved in water planning processes.
                                                </p>
                                                <p>
                                                    AWA’s are held on Country, where information is shared and captured through yarning, in-depth discussions, observations of sites and surveys. The aim is to build on the collective data of each site with a focus on values and aspects of the waterway condition based on First Nations priorities.
                                                </p>
                                            </div>
                                            <div>

                                                <blockquote>
                                                    <h2 style={{ color: "#95cced" }}><q>It is our human right – the rights of the Indigenous people of Australia – to have access to water and be participating players in the decisions made regarding water. On our Country.</q></h2>

                                                    <footer>
                                                        <p>― Brendan Kennedy,
                                                            <cite> Tati Tati Kaiejin Director & Aboriginal Water Officer.</cite></p>
                                                    </footer>
                                                </blockquote>
                                                <div>
                                                    <p>
                                                        Tati Tati received funding through Victoria’s water resource plans to conduct an Aboriginal Waterway Assessment at several sites of cultural significance. This is a project that continues to be developed and implemented by Tati Tati Kaiejin.
                                                    </p>
                                                    <p>
                                                        The Tati Tati Traditional Owners maintain intellectual copyright over the Aboriginal Waterway Assessment report.
                                                    </p>
                                                </div>
                                            </div>

                                        </AliceCarousel>
                                    </div>

                                </Col>
                                <Col sm="12" md="4" xs="12" style={{paddingBottom:"20px"}}>
                                    <Carousel variant="dark" style={{overflow:"hidden"}} >
                                        <Carousel.Item  style={{overflow:"hidden"}} >
                                            <img
                                                // className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/awa/Awaone.png"}
                                                alt="First slide"
                                                // height="500px"
                                            />

                                        </Carousel.Item>
                                        <Carousel.Item  style={{overflow:"hidden"}} >
                                            <img
                                                // className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/awa/awaTwooo.jpg"}
                                                alt="Second slide"
                                                // height="500px"
                                            />
                                        </Carousel.Item>


                                    </Carousel>

                                </Col>

                            </Row>

                        </div >
                    </section >
                </div >
                <FooterWater />
            </>
        )
    }
}
export default Water;