import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import SimpleTable from '../simpleTable/SimpleTable'
import Table from '../table/Table'
import { isMobile } from 'react-device-detect';
import SurveyMobile from './SurveyMobile';
import { useHistory } from 'react-router-dom';
import Footer from '../footer/Footer';

const formData = [
    {
        id: "Q2a",
        desc: "Gathering wood (such as for fires or making tools and/or other things)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2b",
        desc: "Harvesting plants (such as for medicines, food, weaving and/or ceremony)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2c",
        desc: "Fishing",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2d",
        desc: "Hunting and gathering (such as kangaroos, turtles, goanna and/or birds from around the river, eggs and/or grubs)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2e",
        desc: " Paid employment (such as working as rangers or other positions, tourism, heritage surveying and/or monitoring)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2f",
        desc: "Swimming",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2g",
        desc: "Canoeing",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2h",
        desc: "Camping",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2i",
        desc: "Recreation (such as social gatherings and/or sporting events)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },

]
const formDataTwo = [
    {
        id: "Q2j",
        desc: "Stories,history,family connection (such as oral history and/or family use of places)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2k",
        desc: "Teaching and sharing knowledge",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2l",
        desc: "Cultural heritage places (such as scar trees, middens and/or artifact scatters)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2m",
        desc: "Hunting and gathering (such as kangaroos, turtles, goanna and/or birds from around the river, eggs and/or grubs) ",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2n",
        desc: "Spiritual wellbeing",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2o",
        desc: "Ceremony (such as singing and/or dancing)",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },
    {
        id: "Q2p",
        desc: "Other category (only if agreed to by all assessment team members) (Describe):",
        valueOne: "1",
        valueTwo: "2",
        valueThree: "3"
    },


]
const Survey = () => {
    const history = useHistory()
    const [pageOne, setPageOne] = useState( {
        name: "",
        place: "",
        river: "",
        riverType: "",
        projectCode: "",
        questions: [
            {
                questionId: "Q1a",
                question: "How much direct human disturbance has there been in the upstream landscape?(river catchment)",
                ans: ""
            },
            {
                questionId: "Q1b",
                question: "What direct human disturbance are you aware of or have you seen in the upstream landscape?",
                ans: ""
            },
            {
                questionId: "Q1c",
                question: "How much direct human disturbance has there been at this section of the waterway? (river reach)",
                ans: ""
            },
            {
                questionId: "Q1d",
                question: "What direct human disturbance can you see at this section of the waterway?",
                ans: ""
            },
            {
                questionId: "Q1e",
                question: "Have there been any changes to the shape of the river’s channel and the river bed at this place?",
                ans: ""
            },
            {
                questionId: "Q1f",
                question: "What changes to the shape of the river can you see here?",
                ans: ""
            },
            {
                questionId: "Q1g",
                question: "How much has the flow of water changed at this place?",
                ans: ""
            },
            {
                questionId: "Q1h",
                question: "What changes to the flow of water can you see here?",
                ans: ""
            },
            {
                questionId: "Q1i",
                question: "How good is the water quality at this place?",
                ans: ""
            }
            , {
                questionId: "Q1j",
                question: "Describe the water quality. What can you see or smell?",
                ans: ""
            }, {
                questionId: "Q1k",
                question: "How  much  native  vegetation  can  you  see  at  this  place?",
                ans: ""
            }, {
                questionId: "Q1l",
                question: "What  native  vegetation can  you  see here/what  condition  is  it  in?",
                ans: ""
            }
            , {
                questionId: "Q1m",
                question: "How  much  introduced  vegetation  or  weeds  can  you  see  at  this  place?",
                ans: ""
            }
            , {
                questionId: "Q1n",
                question: "What  introduced  vegetation  or  weeds  can  you  see here?",
                ans: ""
            }
            , {
                questionId: "Q1o",
                question: "Is there habitat for native animals and birds at this place?",
                ans: ""
            }
            , {
                questionId: "Q1p",
                question: "What habitats for native animals and birds can you see here?",
                ans: ""
            }
            , {
                questionId: "Q1q",
                question: "Is there habitat for waterbirds at this place?",
                ans: ""
            }
            , {
                questionId: "Q1r",
                question: "What habitats for waterbirds can you see here?",
                ans: ""
            }
            , {
                questionId: "Q1s",
                question: "How good is the native fish population at this place?",
                ans: ""
            }
            , {
                questionId: "Q1t",
                question: "What native fish would you expect to find here?",
                ans: ""
            }
            , {
                questionId: "Q1u",
                question: "Are there exotic, non-native fish here?",
                ans: ""
            }
            , {
                questionId: "Q2a",
                question: "Gathering wood (such as for fires or making tools and/or other things)",
                ans: ""
            }
            , {
                questionId: "Q2b",
                question: "Harvesting plants (such as for medicines, food, weaving and/or ceremony)",
                ans: ""
            }
            , {
                questionId: "Q2c",
                question: "Fishing",
                ans: ""
            }
            , {
                questionId: "Q2d",
                question: "Hunting and gathering (such as kangaroos, turtles, goanna and/or birds from around the river, eggs and/or grubs)",
                ans: ""
            }

            , {
                questionId: "Q2e",
                question: "Paid employment (such as working as rangers or other positions, tourism, heritage surveying and/or monitoring)",
                ans: ""
            }
            , {
                questionId: "Q2f",
                question: "Swimming",
                ans: ""
            }
            ,
            {
                questionId: "Q2g",
                question: "Canoeing",
                ans: ""
            }
            ,
            {
                questionId: "Q1b",
                question: "",
                ans: ""
            }
            ,
            {
                questionId: "Q2h",
                question: "Camping",
                ans: ""
            }
            ,
            {
                questionId: "Q2i",
                question: "Recreation (such as social gatherings and/or sporting events)",
                ans: ""
            }
            ,
            {
                questionId: "Q2j",
                question: "Stories,history,family connection (such as oral history and/or family use of places)",
                ans: ""
            }
            ,
            {
                questionId: "Q2k",
                question: "Teaching and sharing knowledge",
                ans: ""
            }
            ,
            {
                questionId: "Q2l",
                question: "Cultural heritage places (such as scar trees, middens and/or artifact scatters)",
                ans: ""
            }
            ,
            {
                questionId: "Q2m",
                question: "Hunting and gathering (such as kangaroos, turtles, goanna and/or birds from around the river, eggs and/or grubs) ",
                ans: ""
            }
            ,
            {
                questionId: "Q2n",
                question: "Spiritual wellbeing",
                ans: ""
            }
            ,
            {
                questionId: "Q2o",
                question: "Ceremony (such as singing and/or dancing)",
                ans: ""
            }
            ,

            {
                questionId: "Q2p",
                question: "Other category (only if agreed to by all assessment team members) (Describe):",
                ans: ""
            }
            ,
            {
                questionId: "Q3a",
                question: "Overall, does the current waterway health at this place support the values and uses that you want to see here?",
                ans: ""
            }
            ,

            {
                questionId: "Q3b",
                question: " Overall, having spent time at this place today, what is your feeling about the waterway here?",
                ans: ""
            }
            ,
            {
                questionId: "Q3c",
                question: "Why is this place important to you and/or your community?",
                ans: ""
            }
            ,
            {
                questionId: "Q3d",
                question: "How does this place benefit you and/or your community?",
                ans: ""
            }
            ,
            {
                questionId: "Q3e",
                question: "Is this place easily accessible to you and/or your community?",
                ans: ""
            }
            ,
            {
                questionId: "Q3f",
                question: "What should this place look like?",
                ans: ""
            }
            ,
            {
                questionId: "Q3g",
                question: "What do you want to see happen at this place to ensure that it stays/becomes healthy?",
                ans: ""
            }


        ]
    } )

    const [partOneQuestions, setPartOneQuestions] = useState( [{
        question: "",
        answer: ""
    }] )
    const [data, setData] = useState( {
        firstName: "",
        lastName: "",
        address: "",
        suburb: "",
        state: "",
        postalCode: 0,
        supplierStatement: false
    } )
    const [tab, setTab] = useState( {
        tabOne: "tab",
        tabTwo: "tab",
        tabThree: "tab",
        tabFour: "tab",
        tabFive: "tab",
        tabSix: "tab",
        tabSeven: "tab",
        tabEight: "tab",
        tabNine: "tab"

    } )
    const [step, setStep] = useState( {
        stepOne: "step",
        stepTwo: "step",
        stepThree: "step",
        stepFour: "step",
        stepFive: "step",
        stepSix: "step",
        stepSeven: "step",
        stepEight: "step",
        stepNine: "step"

    } )
    const [preTab, setPreTab] = useState()
    const [locations, setLocations] = useState( [] )
    const getLocations = () => {
        Axios.get( `${ process.env.REACT_APP_API_BASE }/location` ).then( res => {
            console.log( "body===>", res )
            if ( res.data ) {
                setLocations( res.data.body )
            }

        } )
    }
    useEffect( () => {
        getLocations()
    }, [] )

    useEffect( () => {
        showTab( 0 )
    }, [] )

    // const setValuesToArray = ( questionId, question, ans ) => {
    //     let findQuestion = pageOne.questions.find( rec => {
    //         if ( rec ) {

    //             return rec.questionId == questionId
    //         }
    //         else { return false }
    //     } )
    //     let answerArray = []
    //     if ( findQuestion ) {
    //         answerArray = pageOne.questions.map( rec => {
    //             if ( rec && rec.questionId == questionId ) {
    //                 return {
    //                     questionId,
    //                     question,
    //                     ans
    //                 }
    //             } else {
    //                 return rec
    //             }
    //         } )
    //     } else {
    //         answerArray = [...pageOne.questions, {
    //             questionId,
    //             question,
    //             ans
    //         }]
    //     }
    //     setPageOne( {
    //         ...pageOne,
    //         questions: [...answerArray]
    //     } )

    // }

    // new array arrangment 

    const setValuesToArray = ( questionId, question, ans ) => {


        for ( var i in pageOne.questions ) {
            if ( pageOne.questions[i].questionId == questionId ) {
                pageOne.questions[i].ans = ans;
                break; //Stop this loop, we found it!
            }
        }

    }
    const handleSubmit = ( props ) => {
        // console.log("props.history",history)
        // let MyFile = await writeJsonFile( `${ pageOne.name }"_"${ new Date() }.json`, JSON.stringify( pageOne ) );
        //    let myFile = fs.appendFile(`${pageOne.name}"_"${new Date()}`,JSON.stringify(pageOne))
        // console.log( MyFile );
        Axios.post( process.env.REACT_APP_API_BASE + '/survey', pageOne ).then( rec => {
            console.log( 'data', rec )
            if ( rec.status == 200 ) {
                alert( 'Survey submitted successfully' );
                setPageOne( {
                    name: "",
                    place: "",
                    river: "",
                    riverType: "",
                    projectCode: "",
                    questions: []
                } )
                history.push( "/" );
            }
        } ).catch( e => {
            console.log( e )
        } )
        // console.log( "e", data )
    }

    const showTab = ( id ) => {
        let tabName = Object.keys( tab )[id]
        let stepName = Object.keys( step )[id]

        console.log( "tab", tabName, id, !preTab )
        let preTabName = Object.keys( tab )[preTab]
        let preStepName = Object.keys( step )[preTab]

        if ( preTabName == "tabOne" && id == 0 ) {

        } else if ( ( preTabName != "tabOne" || preTab != undefined ) && ( id != 0 || preTab != undefined ) ) {
            console.log( "id====", preTabName, id )
            setTab( {
                ...tab,
                [tabName]: "tab show-tab",
                [preTabName]: "tab"
            } )
            setStep( {
                ...step,
                [stepName]: "step active",
                [preStepName]: "step"
            } )
            setPreTab( id )

        } else if ( preTabName != "tabOne" ) {
            console.log( "id", preTabName, id )
            setTab( {
                ...tab,
                [tabName]: "tab show-tab",
                [preTabName]: "tab"
            } )
            setStep( {
                ...step,
                [stepName]: "step active",
                [preStepName]: "step"
            } )
            setPreTab( id )
        }

    }

    const handleChangePageOne = ( e ) => {
        console.log( "evet 3", e.target.value )


        const name = e.target.name
        setPageOne( {
            ...pageOne,
            [name]: e.target.value
        } )
    }
    const handleChangeSelect = ( e ) => {
        console.log( "evet 3", e.target.value )


        const name = e.target.name
        setPageOne( {
            ...pageOne,
            projectCode: e.target.value
        } )
    }

    if ( isMobile ) {
        return <SurveyMobile />
    } else {
        console.log( "val,", locations )
        return (
            <>
                <div className="page-section container contact-root" style={{ maxWidth: "100%", paddingBottom: "1px" }}>

                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div class="col-md-8">
                                <form id="regForm">
                                    <h2 className=" underlined inline-block" style={{ color: "white" }}>ABORIGINAL WATERWAYS ASSESSMENT (RIVERS)</h2>


                                    <div className="all-steps" id="all-steps">
                                        <span className={step.stepOne} onClick={() => { showTab( 0 ) }}></span>
                                        <span className={step.stepTwo} onClick={() => { showTab( 1 ) }}> </span>
                                        <span className={step.stepThree} onClick={() => { showTab( 2 ) }}></span>
                                        <span className={step.stepFour} onClick={() => { showTab( 3 ) }}></span>
                                        <span className={step.stepFive} onClick={() => { showTab( 4 ) }}></span>
                                        <span className={step.stepSix} onClick={() => { showTab( 5 ) }}> </span>
                                        <span className={step.stepSeven} onClick={() => { showTab( 6 ) }}></span>
                                        <span className={step.stepEight} onClick={() => { showTab( 7 ) }}></span>
                                    </div>
                                    <div className={tab.tabOne}>
                                        <span className="head-bold-one">Name (Optional)</span>

                                        <p><input classname="form-control"
                                            type="text"

                                            // placeholder="Enter Name"
                                            name="name"
                                            value={pageOne.name}
                                            onChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    {
                                                        if ( e.target.value.split( " " ).length <= 500 ) {
                                                            handleChangePageOne( e )
                                                        } else {
                                                            alert( "You can only write 500 words" )
                                                        }
                                                    }
                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }
                                            }} /></p>

                                        <p>
                                            <span className="head-bold-one">What do you call this place?</span>
                                            <input classname="form-control"
                                                type="text"

                                                // placeholder="Enter place"
                                                name="place"
                                                value={pageOne.place}
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        handleChangePageOne( e )
                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>
                                        <p>
                                            <span className="head-bold-one">What river are we on?</span>

                                            <input classname="form-control"
                                                type="text"

                                                // placeholder="Enter River"
                                                name="river"
                                                value={pageOne.river}
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        handleChangePageOne( e )
                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}
                                            />
                                        </p>
                                        <p>
                                            <span
                                                className="head-bold-one">
                                                What type of river, or section of river, is this? (e.g. main river, flood
                                                channel, tributary, stream etc.)</span>
                                            <input classname="form-control"
                                                type="text"

                                                placeholder=""
                                                name="riverType"
                                                value={pageOne.riverType}
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        handleChangePageOne( e )
                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />

                                        </p>
                                        <p>
                                            <span className="head-bold-one"> AWA project place code?</span>
                                            {/* <input
                                         classname="form-control"
                                            type="text"

                                            // placeholder="Enter Project Place Code"
                                            name="projectCode"
                                            value={pageOne.projectCode}
                                            onChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    handleChangePageOne( e )
                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }
                                            }}

                                        /> */}
                                            <select
                                                classname="form-control"
                                                value={pageOne.projectCode}
                                                style={{
                                                    display: "block",
                                                    width: "100%",
                                                    background: "grey",
                                                    height: "45px",
                                                    borderRadius: "5px"
                                                }}
                                                onChange={( e ) => {
                                                    console.log( "evet 2", e )
                                                    handleChangeSelect( e )
                                                }}>
                                                <option value="Select Location">Select Location</option>
                                                {
                                                    locations.length ? locations.map( rec => {
                                                        return <option value={rec.code}>{rec.code}</option>
                                                    } ) : null
                                                }
                                            </select>
                                        </p>
                                        <p style={{ color: "white", fontSize: '17px' }}>
                                            <b>Points to remember:</b>
                                            <ul>
                                                <li>This is your assessment, from your own perspective, as a local Traditional Owner</li>
                                                <li>The intellectual property from this project belongs to you and your community</li>

                                            </ul>
                                        </p>
                                        <p style={{ color: "white", fontSize: '16px', fontFamily: 'Open Sans' }}>The Aboriginal Waterway Assessment was developed by the Murray Lower Darling Rivers Indigenous
                                            Nations (MLDRIN) and the Northern Basin Aboriginal Nations (NBAN), with support from the MurrayDarling Basin Authority(MDBA).</p>

                                    </div>
                                    <div className={tab.tabTwo}>
                                        <p><h3 className="head-bold" style={{ letterSpacing: '2px', fontSize: '22px' }}>Part 1: Waterway Health</h3></p>
                                        <p style={{ letterSpacing: '1px' }}><h4>Let’s assess the health of the waterway at this place.</h4></p>
                                        <p>Thinking about the physical shape of the upstream landscape and of the
                                            section of the waterway we’re interested in:</p>

                                        <p className="head-bold" style={{ fontSize: '18px' }}>Q1.1 How much direct human disturbance has there been in the upstream landscape?
                                            (river catchment)</p>
                                        <p>
                                            <Table headings={[
                                                "The upstream landscape has had a lot of direct human disturbance",
                                                "The upstream landscape has had some direct human disturbance",
                                                "The upstream landscape has had no direct human disturbance"
                                            ]}
                                                handleChange={( e ) => {
                                                    setPartOneQuestions( [...partOneQuestions, {
                                                        question: "Q1.1 How much direct human disturbance has there been in the upstream landscape?(river catchment)",
                                                        answer: e.target.value
                                                    }] )
                                                    // setPageOne( { ...pageOne, ["How much direct human disturbance has there been in the upstream landscape?(river catchment)"]: e.target.value } )
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1a", "How much direct human disturbance has there been in the upstream landscape?(river catchment)", e.target.value )
                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />
                                        </p>

                                        <p>
                                            <span className="head-bold-one">
                                                What direct human disturbance are you aware of or have you seen in the upstream landscape?
                                            </span>
                                            <input classname="form-control"
                                                type="text"

                                                placeholder=""
                                                name="upstream"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1b", "What direct human disturbance are you aware of or have you seen in the upstream landscape?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}
                                            /></p>

                                        <p
                                            className="head-bold" style={{ fontSize: '18px' }}>
                                            Q1.2 How much direct human disturbance has there been at this section of the
                                            waterway? (river reach)</p>
                                        <Table style={{ fontFamily: 'Open Sans' }} headings={[
                                            "This section of the  waterway has had a lot of direct human disturbance.",
                                            "This section of the waterway has  had some direct human  disturbance",
                                            "This section of the  waterway has no direct  human disturbance."
                                        ]}
                                            handleChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q1c", "How much direct human disturbance has there been at this section of the waterway? (river reach)", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }

                                            }}

                                        />
                                        <br />
                                        <p>
                                            <span className="head-bold-one">What direct human disturbance can you see at this section of the waterway?</span>
                                            <input classname="form-control"
                                                placeholder=""
                                                type="text"

                                                name="waterway"

                                                onChange={( e ) => {

                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1d", "What direct human disturbance can you see at this section of the waterway?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}
                                            /></p>

                                    </div>
                                    <div className={tab.tabThree}>
                                        <p><h4>Thinking about the shape and depth of the waterway at this place:</h4></p>


                                        <p className="head-bold">Q1.3 Have there been any changes to the shape of the river’s channel and the river
                                            bed at this place?</p>
                                        <p>
                                            <Table
                                                headings={[
                                                    "The shape of the river has  changed completely here.",
                                                    "There have been some changes to the shape of the river here.",
                                                    "There have been no unusual changes to the shape of the river here."
                                                ]}

                                                handleChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1e", "Have there been any changes to the shape of the river’s channel and the river bed at this place?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />
                                        </p>
                                        <p>
                                            <span className="head-bold-one">What changes to the shape of the river can you see here?</span>
                                            <input classname="form-control" placeholder=""
                                                type="text"

                                                name="upstream"
                                                onChange={( e ) => {

                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1f", "What changes to the shape of the river can you see here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                    </div>
                                    <div className={tab.tabFour}>

                                        <p><h4>Thinking about the water flow and water quality at this place:</h4></p>
                                        <p className="head-bold">Q1.4 How much has the flow of water changed at this place?</p>
                                        <p>
                                            <Table headings={[
                                                "The flow of water here has changed completely.",
                                                "There have been some changes to the flow of water here. ",
                                                "The flow of water has not changed at all."
                                            ]}

                                                handleChange={( e ) => {

                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1g", "How much has the flow of water changed at this place?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}
                                            />
                                        </p>

                                        <p>
                                            <span className="head-bold-one">What changes to the flow of water can you see here?</span>
                                            <input classname="form-control"
                                                placeholder=""
                                                type="text"

                                                name="upstream"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1h", "What changes to the flow of water can you see here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                        <p className="head-bold">Q1.5 How good is the water quality at this place?</p>
                                        <Table headings={[
                                            "The water quality is bad. I would not drink or swim in this water",
                                            "The water quality is ok. I wouldn’t drink it, but I might swim here.",
                                            "The water quality is good. I would drink it and swim here."
                                        ]}

                                            handleChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q1i", "How good is the water quality at this place?", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }
                                            }}
                                        />
                                        <br />
                                        <p>
                                            <span className="head-bold-one">Describe the water quality. What can you see or smell?</span>
                                            <input classname="form-control"
                                                placeholder=""
                                                type="text"

                                                name="waterway"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1j", "Describe the water quality. What can you see or smell?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                    </div>
                                    <div className={tab.tabFive}>

                                        <p><h4>Thinking  about  vegetation  at  this  place:</h4></p>
                                        <p className="head-bold">Q1.6 How  much  native  vegetation  can  you  see  at  this  place?</p>
                                        <p>
                                            <Table headings={[
                                                "There  is  not  much  native  vegetation  here.",
                                                "There  is  some  native  vegetation  here.",
                                                "There  is  a  lot  of  native  vegetation  here."
                                            ]}
                                                handleChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1k", "How  much  native  vegetation  can  you  see  at  this  place?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />
                                        </p>

                                        <p>
                                            <span className="head-bold-one">What  native  vegetation can  you  see here/what  condition  is  it  in?</span>
                                            <input classname="form-control"
                                                placeholder=""
                                                type="text"

                                                name="upstream"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1l", "What  native  vegetation can  you  see here/what  condition  is  it  in?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                        <p className="head-bold">Q1.7 How  much  introduced  vegetation  or  weeds  can  you  see  at  this  place?</p>
                                        <Table headings={[
                                            "There  is  a  lot  of  introduced  vegetation  or  weeds  here.",
                                            "There  is  some  introduced  vegetation  or  weeds  here.",
                                            "There  is  hardly  any  introduced  vegetation  or  weeds  here."
                                        ]}

                                            handleChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q1m", "How  much  introduced  vegetation  or  weeds  can  you  see  at  this  place?", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }
                                            }}

                                        />
                                        <br />
                                        <p>
                                            <span className="head-bold-one">What  introduced  vegetation  or  weeds  can  you  see here?</span>
                                            <input classname="form-control"
                                                placeholder=""
                                                type="text"

                                                name="waterway"

                                                onChange={( e ) => {
                                                    setPageOne(
                                                        {
                                                            ...pageOne,
                                                            ["What  introduced  vegetation  or  weeds  can  you  see here?"]: e.target.value
                                                        } )
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1n", "What  introduced  vegetation  or  weeds  can  you  see here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                    </div>
                                    <div className={tab.tabSix}>

                                        <p><h4>Thinking about animals, birds and fish at this place:</h4></p>
                                        <p className="head-bold">Q1.8 Is there habitat for native animals and birds at this place?</p>
                                        <p>
                                            <Table headings={[
                                                "There is no habitat for  native animals and birds here.",
                                                "There is some habitat for native animals and birds here.",
                                                "There is a lot of habitat for native animals and birds here"
                                            ]}
                                                handleChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1o", "Is there habitat for native animals and birds at this place?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />
                                        </p>

                                        <p>
                                            <span className="head-bold-one">What habitats for native animals and birds can you see here?</span>
                                            <input classname="form-control" placeholder="" type="text" name="upstream"

                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1p", "What habitats for native animals and birds can you see here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            /></p>

                                        <p className="head-bold">Q1.9 Is there habitat for waterbirds at this place?</p>
                                        <Table headings={[
                                            "There is no habitat for waterbirds here.",
                                            "There is some habitat for waterbirds here.",
                                            "There is a lot of habitat for waterbirds here"
                                        ]}

                                            handleChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q1q", "Is there habitat for waterbirds at this place?", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }
                                            }}


                                        />

                                        <p style={{ paddingTop: '1rem' }}>
                                            <span className="head-bold-one" >What habitats for waterbirds can you see here?</span>
                                            <input classname="form-control" placeholder="" type="text" name="waterway"

                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1r", "What habitats for waterbirds can you see here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}
                                            /></p>
                                        <p>
                                            <p className="head-bold">Q1.10 How good is the native fish population at this place?</p>
                                            <Table headings={[
                                                "I would expect that there are no native fish here",
                                                "I would expect that there are some native fish here",
                                                "I would expect that there are a lot of native fish here."
                                            ]}

                                                handleChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1s", "How good is the native fish population at this place?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}

                                            />
                                        </p>
                                        <p>
                                            <span className="head-bold" style={{ paddingLeft: '0' }}>What native fish would you expect to find here?</span>
                                            <input classname="form-control" placeholder="" type="text" name="waterway"

                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1t", "What native fish would you expect to find here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}
                                            /></p>
                                        <p>
                                            <p className="head-bold"> Q1.11 Are there exotic, non-native fish here?</p>
                                            <Table headings={[
                                                "I would expect that there are a lot of exotic, nonnative fish here.",
                                                "I would expect that there are some exotic, non-native fish here.",
                                                "I would expect that there  are no exotic, non-native  fish here."
                                            ]}

                                                handleChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q1u", "Are there exotic, non-native fish here?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}


                                            />
                                        </p>
                                        <p>
                                            <span className="head-bold-one">What exotic, non-native fish would you expect to find here?</span>
                                            <input classname="form-control" placeholder="" type="text" name="waterway" /></p>

                                    </div>
                                    <div className={tab.tabSeven}>

                                        <p><h4 style={{ letterSpacing: '2px', fontSize: '22px' }}>Part 2: Cultural Values and Uses</h4></p>
                                        <p className="head-bold-one">Let’s assess the cultural values and uses at this place.</p>
                                        <p>
                                            <SimpleTable headings={[
                                                "Based on what you can see, how good is this place for?",
                                                "Poor",
                                                "Average",
                                                "Good"

                                            ]}
                                                data={formData}
                                                handleChange={( e, q, id ) => {
                                                    console.log( "id===>", id )
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( id, q, e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}
                                            />
                                        </p>
                                        <p>
                                            <SimpleTable headings={[
                                                "Based on what you can see, how important is this place for?",
                                                "Poor",
                                                "Average",
                                                "Good"

                                            ]} data={formDataTwo}

                                                handleChange={( e, q, id ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( id, q, e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}
                                            />
                                        </p>



                                    </div>
                                    <div className={tab.tabEight}>
                                        <p><h3 className="head-bold" style={{ letterSpacing: '2px', fontSize: '22px' }}>Part 3: Summary and Objectives</h3></p>
                                        <p><h4>Let’s assess the health of the waterway at this place.</h4></p>
                                        <p >Let’s think about this place overall.</p>

                                        <p className="head-bold">Q3.1 Overall, does the current waterway health at this place support the values
                                            and uses that you want to see here?</p>
                                        <p>
                                            <label style={{ display: "block", paddingLeft: "35px" }}>
                                                <input
                                                    classname="form-control"
                                                    type="radio"
                                                    value="Yes"
                                                    name="question"
                                                    style={{
                                                        opacity: 1, position: "unset"
                                                    }} /> <span style={{
                                                        marginRight: "10px"
                                                    }}>Yes</span>
                                            </label>
                                            <label style={{ display: "block", paddingLeft: "35px" }}>

                                                <input classname="form-control" type="radio" value="No" name="question"
                                                    style={{ opacity: 1, position: "unset" }} /> No
                                            </label>

                                        </p>


                                        <p><input classname="form-control" type="text"
                                            placeholder="" name="name"

                                            onChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q3a", "Overall, does the current waterway health at this place support the values and uses that you want to see here?", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }

                                            }}
                                        /></p>
                                        <p className="head-bold" style={{ marginBottom: '0px' }}>Q3.2 Overall, having spent time at this place today, what is your feeling about the
                                            waterway here?</p>

                                        <p><input classname="form-control" type="text" placeholder="" name="name"
                                            onChange={( e ) => {
                                                if ( e.target.value.split( " " ).length <= 500 ) {
                                                    setValuesToArray( "Q3b", " Overall, having spent time at this place today, what is your feeling about the waterway here?", e.target.value )

                                                } else {
                                                    alert( "You can only write 500 words" )
                                                }

                                            }}
                                        /></p>

                                        <p>
                                            <p className="head-bold" style={{ marginBottom: '0px' }}>Q3.3 Why is this place important to you and/or your community?</p>
                                            <input classname="form-control" type="text" placeholder="" name="place"

                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q3c", "Why is this place important to you and/or your community?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}
                                            /></p>
                                        <p>
                                            <p className="head-bold" style={{ marginBottom: '0px' }}>Q3.4 How does this place benefit you and/or your community?</p>
                                            <input classname="form-control" type="text" placeholder="" name="river"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q3d", "How does this place benefit you and/or your community?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}

                                            />
                                        </p>
                                        <p>
                                            <p className="head-bold" style={{ marginBottom: '0px' }}>Q3.5 Is this place easily accessible to you and/or your community?</p>
                                            <input classname="form-control" type="text" placeholder="" name="riverType" className="form-control"

                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q3e", "Is this place easily accessible to you and/or your community?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}

                                            />
                                        </p>
                                        <h4 style={{ color: "white", fontWeight: "bold", letterSpacing: '1px' }}>Let’s think about objectives.</h4>
                                        <p>
                                            <p className="head-bold" style={{ marginBottom: '0px' }}> Q3.6 What should this place look like?</p>
                                            <input classname="form-control" type="text" placeholder="" name="projectCode"
                                                onChange={( e ) => {
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q3f", "What should this place look like?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }

                                                }}

                                            />
                                        </p>
                                        <p>
                                            <p className="head-bold" style={{ marginBottom: '0px' }}>Q3.7 What do you want to see happen at this place to ensure that it stays/becomes
                                                healthy?</p>
                                            <input classname="form-control" type="text" placeholder="" name="projectCode"
                                                onChange={( e ) => {
                                                    setPageOne(
                                                        {
                                                            ...pageOne,
                                                            ["What do you want to see happen at this place to ensure that it stays/becomes healthy?"]: e.target.value
                                                        } )
                                                    if ( e.target.value.split( " " ).length <= 500 ) {
                                                        setValuesToArray( "Q3g", "What do you want to see happen at this place to ensure that it stays/becomes healthy?", e.target.value )

                                                    } else {
                                                        alert( "You can only write 500 words" )
                                                    }
                                                }}


                                            />
                                        </p>
                                        <h4>Thank you for participating!</h4>

                                    </div>


                                    {/* <div class="thanks-message text-center" id="text-message">
                                <img src="https://i.imgur.com/O18mJ1K.png" width="100" className="mb-4" />
                                <h3 className="head-bold">Thanks for your Donation!</h3> <span className="head-bold">Your donation has been entewhite! We will contact you shortly!</span>
                            </div> */}
                                    <div style={{ overflow: "auto", fontFamily: 'Open sans' }} id="nextprevious">
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                            <button type="button" id={preTab >= 1 ? "nextBtn" : "nextD"}
                                                onClick={() => {
                                                    if ( preTab > 0 ) {
                                                        console.log( "prev", preTab )
                                                        showTab( preTab - 1 )
                                                    }
                                                }}>Previous</button>
                                            {preTab < 7 ? <button type="button" disabled={preTab < 7 ? false : true} id={preTab < 7 ? "nextBtn" : "nextD"} onClick={() => {
                                                console.log( "previous", preTab )
                                                if ( pageOne.place != "" && pageOne.river != "" && pageOne.riverType != "" ) {
                                                    showTab( preTab + 1 )
                                                } else {
                                                    alert( "Please Fill All the mendatory fields " )
                                                }
                                            }}>Next</button>
                                                : <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        // marginTop: "91px",
                                                    }}
                                                >
                                                    <button

                                                        type="button"
                                                        onClick={() => {
                                                            handleSubmit()

                                                        }}>Submit</button>
                                                </div>}
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Survey