
import React, { useState } from 'react'
import { Container, Row, Col, Image, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const data = [
    {

        url: 'https://cdn.dev.kaiejin.org.au/static-content/LGImages/latji.JPG' ,
        title: "Latji Latji:",
        desc: [
            "The Latji Latji are a Murray River Tribe with country on both sides of the river around the Hattah Kulkyne Lakes and up to the Mildura district. Latji Latji are also known as Larcha Larcha, Ladji Ladji, Ladje Ladje, Litchi Litchi, Latju Latju, Latja Latja. "
            , "The Latji Latji are closely related to the Tati Tati and also share boundaries with Nyeri Nyeri, Barkindji, Barindji and Wergaia. "]
    },
    {
        url: 'https://cdn.dev.kaiejin.org.au/static-content/LGImages/wadi.JPG' ,
        title: "Wadi Wadi:",
        desc: ["The Wadi Wadi language was spoken around Swan Hill in the Mid-Murray River district, their country extended both sides of the Murray River to include rivers such as the Edward and Wakool rivers. Well known Wadi Wadi locations include Tyntynder, Swan Hill, Woorinen, Stoney Crossing and Lake Tyrrell. ",
            "Wadi Wadi are also known as Wati Wati, Wotti Wotti, Watte Watte, and the Swan Hill tribe. Tribal neighbours include Wamba Wamba to the South, Wergaia to the West, Tati Tati to the Northwest, and Mutti Mutti and Nari Nari to the Northeast. "]
    },
    {
        url:  'https://cdn.dev.kaiejin.org.au/static-content/LGImages/tati.jpg',
        title: "Tati Tati: ",
        desc: [
            "The Tati Tati language was spoken along the Murray River at Bumbang (Robinvale) in far Northwest Victoria and Boonyaricool (Euston) far Southwest New South Wales. Special places within Tati Tati country include Lake Benanee, Bumbang Island, Narrung, Chalka Creek, and Lake Tyrrell. ",
            "The Tati Tati were also known as Dadi Dadi, Darty Darty, Tarti Tarti, Ta-tathi, Taatee Taatee, Nimp-mam-wurn or light lips, Tungutt, and the Narrung Tribe. ",
            "Tati Tati hosted tribal ceremonies with their neighbours including the Latji Latji, Weki Weki, Wadi Wadi, Mutti Mutti and Wergaia language groups. Still today the Tati Tati people continue live in their traditional country along the Murray River. "]
    },
    {
        url:  'https://cdn.dev.kaiejin.org.au/static-content/LGImages/nari.JPG' ,
        title: "Nari Nari: ",
        desc: [
            "Nari Nari people spoke their language in the vicinity of Hay NSW and also in the Lower Murrumbidgee and Lower Lachlan rivers area, particularly the Hay Plain. Nari Nari are also known as Jari Jari, Yari Yari, Narri Narri, Narinari, ",
            "The Nari Nari are tribal neighbours with Yitha Yitha, Mutti Mutti, Wadi Wadi, Wemba Wemba, and Wiradjuri. "
        ]
    },
    {
        url: 'https://cdn.dev.kaiejin.org.au/static-content/LGImages/mutti.JPG' ,
        title: "Mutti Mutti: ",
        desc: [
            "Mutti Mutti was spoken in the vicinity of Balranald far Southwestern New South Wales. ",
            "Variations in spelling of the tribal name include Mathi Mathi, Madi Madi, Madhi Madhi, Mati Mati, Muddi Muddi, and Mudhi Mudhi. ",
            "Mutti Mutti tribal country includes the lower Murrumbidgee River at its confluence with the Lachlan River, as well as and the junction with the Murray River. Mutti Mutti Country extends out to Lake Mungo in the Willandra Lakes World Heritage Area. ",
            "Neighbours of the Mutti Mutti include Yitha Yitha, Nari Nari, Wadi Wadi, and Tati Tati tribes. There are many descendants today who proudly identify as Mutti Mutti. "
        ]
    },
    {
        url: 'https://cdn.dev.kaiejin.org.au/static-content/LGImages/yitha.JPG' ,
        title: "Yitha Yitha: ",
        desc: [
            "The Yitha Yitha people spoke their language in Southwestern New South Wales, especially within the vicinity of the Lower Lachlan River near Booligal and Oxley, as well as South of the Willandra creek which extends out towards Lake Mungo and the Willandra Lakes World Heritage area.  ",
            "Today there is estimated to be over 2000 descendants of the surviving Yitha Yitha ancestors. Yitha Yitha families now reside in Murrumbidgee and Murray River towns such Balranald, Robinvale, Swan Hill & Mildura areas. ",
            "The Yitha Yitha Tribe were known to possess special knowledge about the night sky constellations in the stars. "
        ]
    }
]
function MyVerticallyCenteredModal( props ) {
    console.log( "props", props )
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header  closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* Image Preview */}
          </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <div className="card" style={{ width: "100%", padding: "5%" }}>
                    <Row>
                        <Col sm={12} lg={4} md={4} className="img-lang">
                            <div className="center-middle popImg" >
                                <Image src={props.url} rounded />
                            </div>
                        </Col>
                        <Col sm={12} lg={8} md={8}>

                            <div className="card-body">
                                <h1 className="card-title">{props.title}</h1>

                                {props.desc.map( rec => {
                                    return <p className="card-text">{rec}</p>

                                } )}

                             
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
}
const TabsImages = ( props ) => {
    const [selectedImage, setSelectedImage] = useState( "" )
    const [selectedData, setSelectedData] = useState( {} )
    const [modalShow, setModalShow] = React.useState( false );
    return (
        <div>
            {selectedData.desc ? <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow( false )}
                url={selectedImage}
                title={selectedData.title}
                desc={selectedData.desc}

            /> : null}
            <Row className='repeatImg'>
                {data.map( rec => <Col xs={6} md={2} style={{ padding: "20px" ,paddingTop:'30px'}} className="image-gallary" >
                    <Image
                        src={rec.url}
                        rounded width="90%"
                        onClick={() => {
                            setSelectedImage( rec.url )
                            setModalShow( true )
                            setSelectedData( rec )
                        }}
                        className="img-gal" />
                </Col> )}
            </Row>
        </div>
    )

}
export default TabsImages