import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button, Form, Input } from 'reactstrap';
import EmailValidator from 'email-validator';
import './user.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import { Badge, Dropdown, Menu, Space, Table } from 'antd';
import Footer from '../footer/Footer';
function a11yProps( index ) {
    return {
        id: `scrollable-auto-tab-${ index }`,
        "aria-controls": `scrollable-auto-tabpanel-${ index }`
    };
}

const useStyles = makeStyles( theme => ( {
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
} ) );

const NewUsers = () => {

    const [usersData, setUsersData] = React.useState( [] )
    const [usersCogData, setUsersCogData] = React.useState( [] )
    const [open, setOpen] = React.useState( false );
    const [openProdSub, setOpenProdSub] = React.useState( false );
    const [openProd, setOpenProd] = React.useState( false );
    const [updateOpen, setUpdateOpen] = React.useState( false );
    const [btnUpdate, setBtnUpdate] = React.useState( false );
    const [disabletext, setDisabletext] = React.useState( false );
    const [showFieldSupp, setShowFieldSupp] = React.useState( false );
    const [post, setPostArray] = React.useState( [] );
    const [value, setValue] = React.useState( 0 );
    const [locations, setLocations] = useState( [] )
    const [scroll, setScroll] = useState( {} )
    const scrollToRef = ( ref ) => window.scrollTo( 0, ref.current.offsetTop )
    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    const [inputField, setInputField] = React.useState( {

        'role': "",
        'email': "",
        'name': "",
        location: "",
        code: "",


    } )

    const [validateRole, setValidateRole] = React.useState( false );
    const [validate, setValidate] = React.useState( false );


    const getLocations = () => {
        Axios.get( `${ process.env.REACT_APP_API_BASE }/location` ).then( res => {
            console.log( "body===>", res )
            if ( res.data ) {
                setLocations( res.data.body )
            }

        } )
    }
    const listcustomer = () => {

        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",

        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const listdetails = response.data.body;
                setPostArray( listdetails );

            } )
        // })
    }
    const submitButton = () => {
        // console.log()
        setDisabletext( false );
        console.log( inputField.email )
        // const {email,password} = this.state
        // validate Email
        if ( !EmailValidator.validate( inputField.email ) ) {
            console.log( 'wrong emial' )
            setValidate( true );
            return;
        }
        if ( !inputField.role ) {
            console.log( 'inside empty' );
            setValidateRole( true );
            return;
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/create",
            "data": inputField
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    setOpen( true );
                    setTimeout( () => {
                        setOpen( false );
                    }, 1500 );
                    listcustomer();
                }
                setInputField( {
                    email: '',
                    name: '',
                    role: ''

                } )
            } )
            .catch( ( error ) => {
                console.log( error )
                if ( error.message == "Request failed with status code 401" ) {
                    alert( "User account already exists." )

                } else {
                    alert( error.message )
                }
            } )
    }
    const submitLocation = () => {
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/location",
            "data": {
                location: inputField.location,
                code: inputField.code
            }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    setOpen( true );
                    setTimeout( () => {
                        setOpen( false );
                    }, 1500 );

                }
                setInputField( {
                    ...inputField,
                    location: "",
                    code: ""

                } )
            } )
            .catch( ( error ) => {
                console.log( error )
                if ( error.message == "Request failed with status code 401" ) {
                    alert( "." )

                } else {
                    alert( error.message )
                }
            } )
    }
    const updateButton = () => {
        if ( !inputField.email ) {
            console.log( 'inside empty' );
            setValidate( true );

            return;
        }
        console.log( inputField )
        var data = {
            'role': inputField.role,
            'name': inputField.name,
            'email': inputField.email
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/update",
            "data": data
        } )
            .then( ( response ) => {
                console.log( response )
                if ( response.status == 200 ) {
                    setUpdateOpen( true );
                    setTimeout( () => {
                        setUpdateOpen( false );
                    }, 1500 );
                    listcustomer();
                    setBtnUpdate( false )
                    setDisabletext( false );
                    setInputField( {
                        email: '',
                        name: '',
                        role: ''
                    } )

                }
            } )
    }
    const myRef = useRef( null )
    const executeScroll = () => scrollToRef( myRef )

    const getUsersList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                setUsersCogData( lengthOrder )


                //   },
            } )
    }
    const getOrderList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user-entry/list-unconfirmed-users",
        } )
            .then( ( response ) => {
                console.log( response.data );
                const lengthOrder = response.data.body;
                setUsersData( lengthOrder )


                //   },
            } )
    }
    useEffect( () => {
        getOrderList();
        getUsersList()
        listcustomer()
        getLocations()
        if ( isMobile ) {
            setScroll( {
                x: 1200
            } )

        }
    }, [] )
    const ConfirmOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'confirmed' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // getOrderList();
                    // setStatusOrder( true );
                    // setOpenThnk( true );
                    // setTimeout( () => {
                    //     setOpenThnk( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }

    const AdminCanOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'rejected' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // setStatusOrder( true )
                    // setStatusCanOrder( true )
                    // setOpenThnkCanc( true );
                    // setTimeout( () => {
                    //     setOpenThnkCanc( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }
    const columns = [{
        title: "Name",
        dataIndex: "name",

    },
    {
        title: "Gender",
        dataIndex: "gender",
    },

    {
        title: "Age",
        dataIndex: "age",
    },

    {
        title: "Email",
        dataIndex: "email",
    },
    {
        title: "Address",
        dataIndex: "address",
    },
    {
        title: "Status",
        dataIndex: "status",
        render: ( _, rec ) => (
            <div >
                <Button onClick={( e ) => ConfirmOrderBtn( rec, e )} style={{ fontSize: '14px', borderRadius: "32px", marginRight: "10px" }}>Confirm</Button>
                <Button style={{ fontSize: '14px', borderRadius: "32px" }} onClick={( e ) => AdminCanOrderBtn( rec, e )}>Reject</Button>
            </div>
        ),
    }

    ]
    let data = usersData ? usersData.map( rec => {
        return {
            name: rec.first_name + " " + rec.last_name,
            address: rec.street + ", " + rec.suburb + ", " + rec.state + " " + rec.post_code,

            ...rec

        }
    } ) : []

    return (
        <>
            <div className="  d-flex  justify-content-center " style={{ background: "rgb(50, 50, 50)", marginTop: "20px", minHeight: "100vh", flexDirection: "row" }}>
                <div style={{ width: '90%' }}>


                    <Col md={12}>
                        <Row>
                            <h1 style={{ color: "white" }} >New Users</h1>

                        </Row>
                        {/* <Row>

                        <Table responsive hover className="boxShadow " style={{ border: '.01em solid #fff' }}>
                            <thead>
                                <th className="align-middle text-center">Name</th>
                                <th className="align-middle text-center">Gender</th>
                                <th className="align-middle text-center">Age</th>
                                <th className="align-middle text-center">Email</th>
                                <th className="align-middle text-center">Address</th>
                                <th className="align-middle text-center">Status</th>
                            </thead>
                            <tbody>
                                {usersData.length ? usersData.map( ( rec, index ) => (
                                    <>
                                        {rec.status == 'unconfirmed' ? (
                                            <tr key={index}>

                                                <td className="align-middle text-center">{rec.first_name + " " + rec.last_name}</td>
                                                <td className="align-middle text-center">{rec.gender}</td>
                                                <td className="align-middle text-center">{rec.age}</td>

                                                <td className="align-middle text-center">{rec.email}</td>
                                                <td className="align-middle text-center">{rec.street + ", " + rec.suburb + ", " + rec.state + " " + rec.post_code}</td>

                                                <td className="align-middle text-center"><Button onClick={( e ) => ConfirmOrderBtn( rec, e )} style={{ width: '160px', fontSize: '14px' }}>Confirm User</Button><br /><br />
                                                    <Button style={{ width: '160px', fontSize: '14px' }} onClick={( e ) => AdminCanOrderBtn( rec, e )}>Reject User</Button></td>









                                            </tr>
                                        ) : null}
                                    </>
                                ) ) : <div className="d-flex align-items-center justify-content-center"><p style={{ color: "white" }}>No Users Found</p></div>}

                            </tbody>
                        </Table>


                    </Row> */}
                        <Row>
                            <Table
                                dataSource={data}
                                columns={columns}
                                scroll={scroll}
                            />
                        </Row>
                    </Col>


                </div>
            </div>
            <Footer />
        </>

    )
}

export default NewUsers