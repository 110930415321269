import React, { useEffect } from 'react'
import { Component } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap'
import './Home.css'
import './circle.css'
import Footer from '../footer/Footer';



let PitjanData = [
    {
        title: "Weather",
        desc: "For Tati Tati Country, Spring starts earlier in the year. We feel the mirinyuma wilangi (cold wind) and mirti (rain), but mirti feeds Country as everything begins a new cycle of growth. Cold, crisp mornings are followed by warm sunny days, as Ngawingi (sun) starts shining more, so the people know this signifies change. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/PP02.JPG'
    },
    {
        title: "Country ",
        desc: "This is time of flowering. Country is green and damp, following Winter mirti. Plants and buds are sprouting, wulungi (grass) starts to regrow, bush tomatoes begin to ripen. Rivers and creeks are full as kaiejin (water) is flowing crisp and fresh. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/PP03.jpg'
    },
    {
        title: "Animals",
        desc: "As weather warms, and kaiejin flows, fish spawn and insects breed. Birds begin mating as food sources begin to increase. Wangi (crow) and pathangal (pelican) are seen in larger numbers fishing, hunting, and breeding. Karrwingi (emu) lays her eggs this time of year, and kurangi (kangaroo) families can be found grazing on new grass. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/PP01.JPG'
    },
    {
        title: "People",
        desc: "This is a time of awakening when people and animals are on the move again. The feeling of change and anticipation arises, and the cold begins to melt away, warm sun lifts our spirits, and clean, fresh kaiejin is once again flowing on Country. This is the season for Cultural Burns. While the bush is damp, overgrowth is burned back to manage Country and make way for new growth.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/PP04.jpg'
    }

]


let nangaData = [
    {
        title: "Weather",
        desc: "Hot, sunny days and warm winds. Nanga wilangi (warm wind) signifies the season has changed. Strong dust storms and drier Country sees tree dropping limbs. Tirili (sky) is lighter, days are longer, and the nights are clear. Turti (stars) are bright.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/NW02.jpg'
    },
    {
        title: "Country ",
        desc: "Land is drying but still green. Plants are still growing and flowering, pigface are vibrant, Old Man Weed is growing in shallow water, Cumbungi roots can be harvested, and Saltbush leaves are best to eat now. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/NW01.JPG'
    },
    {
        title: "Animals",
        desc: "As Country begins to warm, more animals can be seen. Kani (snake) and bull-ants are remerging. Nganuri (goanna), yukun (shingleback lizard), and kenti (bearded dragon) can be found basking in the sun. This time of year there is an abundance of birds nesting and feeding; wilik wilik (galah) and keranggie (white cockatoo) are always in trees by the water. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/NW04.jpg'
    },
    {
        title: "People",
        desc: "Warmer weather brings more family gatherings and cultural activities. This is the time of year for harvesting bark for cultural objects such as bowls, baskets, spears, or shields. Connection to Country and family is strengthened with the rejuvenation and invigoration we feel from the land.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/NW05.jpg'
    }

]

let tiperaiData = [
    {
        title: "Weather",
        desc: "Hot, dry, sunny days with no moisture felt in the air. Days are longer, and the nights are clear and warm. Warm wind begins to get softer, signalling change in seasons. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/T02.JPG'
    },
    {
        title: "Country ",
        desc: "As weather gets hot, Country begins to dry. Shrubs and wulungi (grass) are starting to brown, as leaves and bark dries out, and trees are dropping limbs. The warm heat see piyal (River Red Gum) and various eucalyptus tree flowering. Cumbungi is growing tall in creeks and riverbanks. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/T04.jpg'
    },
    {
        title: "Animals",
        desc: "This is the season for water creatures. Pandyil (Murray Cod), Wiringul (Golden Perch) and tumimum (turtle) are abundant. Yapi (yabby) and tyipel (cray fish) grow as the hot sun warms rivers and creeks. Wirpil (eagle) and pirwi (hawk) are seen diving for fish, wangi (crow) is hunting lizards and insects. By the waters edge nino (frog) and taramang (wood duck) can be heard and seen.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/T01.jpg'
    },
    {
        title: "People",
        desc: "This is a time of year for contentment and happiness as family are near and food is plentiful as fish are now big enough to eat. Time is spent by the waters edge, swimming, fishing, cooking, and relaxing. Children are playing and joyful. This is also the end of harvesting wood for objects as it is now too dry.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/T03.JPG'
    }
]
let kartiData = [
    {
        title: "Weather",
        desc: "This is the warmest time of the year, when ground is dry, and the skies are clear. People and animals know to avoid time of the day when it is Big Heat – from lunch to mid-afternoon. The Big Heat is when we rest. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/KK05.JPG'
    },
    {
        title: "Country ",
        desc: "Land is dry and brown, Piyal (River Red Gum) are dropping limbs and leaves are scattered on Country. Water is warm and full of life, but some creeks and waterways have dried up. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/KK03.JPG'
    },
    {
        title: "Animals",
        desc: "Animals stay near water sources, and only come out mornings and afternoons to avoid the Big Heat. Birds, insects and fish are still plentiful, and snakes are very active.  ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/KK02.JPG'
    },
    {
        title: "People",
        desc: "This time of year we get up early to fish, and rest during the Big Heat. The Boorong used to see Pandyil (cod) in the sky and know it was time to come to Tati Tati Country. This is a time of year to be healthy and happy and rested. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/KK04.JPG'
    }
]


let tungkaData = [
    {
        title: "Weather",
        desc: "As the last of long hot days passes, the first frost arrives in the morning, signifying the change has come. Days become more and more cloudy, and nights begin to get longer. As weather starts to cool, the effects of the Big Heat linger. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TT04.JPG'
    },
    {
        title: "Country ",
        desc: "Country is mostly dry now, as plants and waterways have made it through the Big Heat. The ground is covered by leaves and branches, and all but the largest waterways have dried up. Grasses are brown and dry, and some flowering species are dormant. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TT02.JPG'
    },
    {
        title: "Animals",
        desc: "As weather starts to cool, and water sources are scarce, Kurangi moves on to other Country. Ants and insects are resurfacing for water and food sources. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TT03.jpg'
    },
    {
        title: "People",
        desc: "As we move out of the Big Heat, people are active again, taking advantage of the milder days. This time of year we look ahead and make plans. As the First Frost arrives, a sense of new beginnings arises as we look to the rains that will heal Country and restart the cycle of growth. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TT01.jpg'
    }
]
let purungiData = [
    {
        title: "Weather",
        desc: "Nights are long and dark. Rain and cold wind follow the frost, as the Winter sets in. The sun still shines bright during the day when it is clear, although her warmth isn’t strong. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TP02.jpg'
    },
    {
        title: "Country ",
        desc: "Country is quiet and begins to grow green again. Cold rains rejuvenate Country that was dry and brown from the Big Heat. New growth is beginning to show signs of sprouting and gone are dry grasses and leaves. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TP04.JPG'
    },
    {
        title: "Animals",
        desc: "Animals are resting until warmer weather returns. Karrwingi lays her eggs this time of year, as Nganuri and other predators sleep. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TP03.JPG'
    },
    {
        title: "People",
        desc: "People stay warm by fires and under shelter this time of year. Emu eggs are collected for food and to be used as cultural objects. Possum skin cloaks are used for warmth. ",
        imageURL: 'https://cdn.dev.kaiejin.org.au/static-content/land/TP01.jpg'
    }
]


const MyVerticallyCenteredModal = ( props ) => {
    console.log( "props", props )

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header> */}
            <Modal.Body>
                <div className="card-land" style={{ padding: "5%" }}>
                    {/* <Row> */}
                    {/* <AliceCarousel > */}
                    <div style={{ textAlign: "center", marginBottom: "60px" }}>
                        <h1 style={{ color: "white" }}>{props.title}</h1>
                        <p>- {props.subTitle}</p>
                        <p>{props.desc}</p>
                    </div>
                    {props.selectedData.map( ( rec, index ) => {
                        console.log( "index", index )

                        return <div><div>

                            {index % 2 == 0 ? <Row style={{ marginTop: "20px", marginBottom: "20px" }}>


                                <Col sm="12" md="6" xs="12" lg="6" >
                                    <h3 className="mb-20" >{rec.title}</h3>

                                    <p>{rec.desc}</p>

                                </Col>
                                <Col sm="12" md="6" xs="12" lg="6">

                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={rec.imageURL}
                                                alt="First slide"
                                            // style={{ height: "600px" }}
                                            />
                                            <Carousel.Caption>

                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row> : <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                                <Col sm="12" md="6" xs="12" lg="6">

                                    <Carousel variant="dark" controls={false} >
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={rec.imageURL}
                                                alt="First slide"
                                            // style={{ height: "600px" }}
                                            />
                                            <Carousel.Caption>

                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>

                                <Col sm="12" md="6" xs="12" lg="6" >
                                    <h3 className="mb-20" >{rec.title}</h3>

                                    <p>{rec.desc}</p>

                                </Col>

                            </Row>}
                        </div></div>
                    } )}
                    {/* <button className="wiki-item" >See More</button> */}
                    {/* </AliceCarousel> */}
                    {/* </Row> */}

                </div >
            </Modal.Body >
        </Modal >
    );
}
class Land extends Component {

    state = {
        audio: typeof Audio !== "undefined" && new Audio( "your-url.mp3" ),
        isPlaying: false,
        selectedData: [],
        showModal: false,
        title: "",
        subTitle: "",
        desc: ""
    }
    handleAudio = ( file ) => {
        let audio = new Audio( file )
        audio.play()
    }
    componentDidMount = () => {
        // window.scrollTo( 0, 0 )
        let Clicked = document.getElementById( 'clicked' );
        Clicked.click()
    }

    render() {
        return (
            <>
                <div className="root-about">
                    <a href="#heroLand" id='clicked' style={{ display: 'none' }} ></a>

                    <section id="heroLand">
                        <div className="heroLang-container" >
                            <div className="exploreOneLand">
                                <p className="typoOne">Land</p>
                                <h4 className="explore-typo-land">RUWE</h4>
                            </div>
                        </div>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "25px 10% 0px" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start" id="testflow">
                                    <h1 className="jt-init headWaterPage" style={{ color: "#c0eb34" }}>Mityan Tema</h1>

                                </div>

                            </Col>
                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "25px 8% 0px" }}>
                            <Col>
                                <p style={{ textAlign: "start", color: "white", opacity: "0.9" }}><strong>Moon Knowledge</strong> -  Tati Tati Wadi Wadi Seasonal Calendar (This calendar is interactive you can click on a particular section to see more details)</p>
                            </Col>
                        </Row>

                        <div style={{ height: "150vh" }} className="main-moon">
                            <div>
                                <div className="moonPic">
                                    <img className="imgMoon" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImages/earth.png'} />

                                    <img className="back_circle" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/wheel1.png'} />

                                    {/* <img className="circle1" src={summer} /> */}
                                    {/* <img className="winter" src={winter} />
                            <img className="spring" src={spring} />
                            <img className="autumn" src={autumn} /> */}
                                    {/* <img className="latesummer" src={latesummer} /> */}
                                    <img className=" tenagi" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/tenagi.png'} onClick={() => {
                                        this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Tungka.m4a' )
                                        this.setState( {
                                            ...this.state,
                                            selectedData: tungkaData,
                                            showModal: true,
                                            title: "Tenagi Tungka",
                                            subTitle: "Time of First Frost",
                                            desc: "Autumn: From mid-March, through April and May."
                                        } )

                                    }} />
                                    <img className="kerrawi" src={"https://cdn.dev.kaiejin.org.au/static-content/wheelImage/kerrawi.png"} onClick={() => {
                                        this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Karti.m4a' )
                                        this.setState( {
                                            ...this.state,
                                            selectedData: kartiData,
                                            showModal: true,
                                            title: "Karrawi Karti",
                                            subTitle: "Time of big heat",
                                            desc: "Late Summer: Start of January through February, into March."
                                        } )

                                    }} />
                                    {/* <img className="earlySummer" src={earlySummer} />
                            <img className="lateSpring" src={lateSpring} /> */}
                                    <img className="teparai" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/teparai.png'} onClick={() => {
                                        this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Tiperai.m4a' )
                                        this.setState( {
                                            ...this.state,
                                            selectedData: tiperaiData,
                                            showModal: true,
                                            title: "Tiperai",
                                            subTitle: "Time of Fishing",
                                            desc: "Late Spring: Late October, through November and December."
                                        } )

                                    }} />
                                    <img className="nanga" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/nanga.png'} onClick={() => {
                                        this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Nanga.m4a' )
                                        this.setState( {
                                            ...this.state,
                                            selectedData: nangaData,
                                            showModal: true,
                                            title: "Nanga wilangi",
                                            subTitle: "Time of warm wind",
                                            desc: "Late Spring: Early September, through October and early November."
                                        } )

                                    }} />
                                    {/* <img className="earlySpring" src={earlySpring} />
                            <img className="winterMonth" src={winterMonth} /> */}
                                    <img className="pitjan" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/pitjan.png'}
                                        onClick={() => {
                                            this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Pitjan.m4a' )
                                            this.setState( {
                                                ...this.state,
                                                selectedData: PitjanData,
                                                showModal: true,
                                                title: "Pitjan Pitjan",
                                                subTitle: "Time of flowering",
                                                desc: "Early Spring: Late July, through August and into the start of September."
                                            } )
                                        }}


                                    />
                                    <img className="thurangal" src={'https://cdn.dev.kaiejin.org.au/static-content/wheelImage/thurangal.png'}
                                        onClick={() => {
                                            this.handleAudio( 'https://cdn.dev.kaiejin.org.au/static-content/audio/Purungi.m4a' )
                                            this.setState( {
                                                ...this.state,
                                                selectedData: purungiData,
                                                showModal: true,
                                                title: "Thurangal Purungi",
                                                subTitle: "Time of long night",
                                                desc: "Winter: May, June, and into July."
                                            } )

                                        }}
                                    />
                                    <div style={{ clear: 'both' }}></div>


                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>

                        </div>
                        {this.state.selectedData.length ? <MyVerticallyCenteredModal
                            show={this.state.showModal}
                            onHide={() => this.setState( { showModal: false } )}
                            selectedData={this.state.selectedData}
                            title={this.state.title}
                            subTitle={this.state.subTitle}
                            desc={this.state.desc}

                        /> : null}
                    </section>
                    <section >


                        {/* <div className="heroLand-container" >
                        <div className="exploreOne">
                            
                            <input type='radio' name='opt' id='ococonut' class='menuopt ococonut' />
                            <input type='radio' name='opt' id='ovanilla' class='menuopt ovanilla' />
                            <input type='radio' name='opt' id='oorange' class='menuopt oorange' />
                            <input type='radio' name='opt' id='oalmond' class='menuopt oalmond' />
                            <input type='radio' name='opt' id='ogrape' class='menuopt ogrape' />
                            <input type='radio' name='opt' id='oblackberry' class='menuopt oblackberry' />
                    
                            <nav className="seasonal-cal">
                                <ul class='circle'>
                                    <li class='coconut light slice'>
                                        <label for='ococonut' class='circle'>Coconut</label>
                                    </li>
                                    <li class='vanilla light slice'>
                                        <label for='ovanilla' class='circle'>Vanilla</label>
                                    </li>
                                    <li class='orange light slice'>
                                        <label for='oorange' class='circle'>Orange</label>
                                    </li>
                                    <li class='almond light slice'>
                                        <label for='oalmond' class='circle'>Almond</label>
                                    </li>
                                    <li class='grape light slice'>
                                        <label for='ogrape' class='circle'>Grape</label>
                                    </li>
                                  
                                    <li class='unsel circle'><label for='unsel'></label></li>
                                </ul>
                            </nav>

                        </div>
                    </div> */}
                    </section>
                    <section>

                    </section>
                </div >
                <Footer />
            </>
        )
    }
}
export default Land;