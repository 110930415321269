
import React, { useEffect, useState } from "react";
import { Row, Col, Container, InputGroup, FormControl } from "react-bootstrap";
import { PayPalButton } from "react-paypal-button-v2";
import Footer from "../footer/Footer";
import { isMobile } from 'react-device-detect'
import './donation.css'
const Donation = () => {
    const [value, setValue] = useState()
    const isNumber = ( value ) => {
        value = Number( value )
        return typeof value === 'number' && isFinite( value );
    }
    useEffect( () => {
        setTimeout( () => {

            window.scroll( {
                top: 0,
                left: 0,
                behavior: 'smooth'
            } )
        }, 40 );

        window.scrollTo( 0, 0 )
    }, [] )
    return ( <><div className="page-section container contact-root d-flex justify-content-center align-item-center" style={{ maxWidth: "100%", background: "rgb(200, 197, 197)", minHeight: "100vh" }}>
        <Container className="width-don">
            <div style={{marginLeft:"20px"}}>
            <h1 style={{ textAlign: "center" }}>Make a Donation</h1>
            <p className="p-tag">
                Tati Tati Kaiejin is an Indigenous-owned and operated not-for-profit organisation whose aim is to reconnect First Nations peoples to waterways and Country.
            </p>
            <p className="p-tag">
                A core value of Tati Tati Kaiejin is to emphasise and promote the cultural, environmental, and spiritual knowledges of Tati Tati Traditional Owners to improve and sustain the well-being of Country, water, animals, culture, and peoples.
            </p>
            <p className="p-tag">
                We welcome donations to help implement our vision to coordinate the creation of resources and sustainability of our resources and culture.
            </p>
            <p className="p-tag">
                All online donations are secure or alternatively, you can contact Tati Tati Kaiejin through the contact us page to make a donation.
            </p>
            <p className="p-tag">
                Your support is greatly appreciated. All donations to Kaiejin above $2 are tax deductible.
            </p>
            <p className="p-tag">
                We thank you for your generosity and support.
            </p>
            </div>
            <div>
                {isMobile ? <>
                    <Row style={{ display: "flex", justifyContent: "center", marginLeft: "10px" }}>
                        {[5, 10].map( rec => <Col
                            sm="2" md="2" lg="2" xs={4}>
                            <button style={{
                                color: "white",
                                borderRadius: "3px",
                                marginTop: "3px",
                                fontFamily: "open sans",
                                width: "100%"
                            }}
                                onClick={() => {
                                    setValue( rec )
                                }}
                            >${rec}</button></Col> )}
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center", marginLeft: "10px", marginTop: "20px" }}>
                        {[15, 50, 100].map( rec => <Col
                            md="2"
                            lg="2"
                            sm="2"
                            xs={4}
                        >
                            <button style={{
                                color: "white",
                                borderRadius: "3px",
                                marginTop: "3px",
                                fontFamily: "open sans",
                                width: "100%"
                            }}
                                onClick={() => {
                                    setValue( rec )
                                }}
                            >${rec}</button></Col> )}
                    </Row>
                </> :
                    <Row style={{ display: "flex", justifyContent: "center", marginLeft: "10px" }}>
                        {[5, 10, 15, 50, 100].map( rec => <Col
                            sm="2" md="2" lg="2" xs={4}>
                            <button style={{
                                color: "white",
                                borderRadius: "3px",
                                marginTop: "3px",
                                fontFamily: "open sans",
                                width: "100%"
                            }}
                                onClick={() => {
                                    setValue( rec )
                                }}
                            >${rec}</button></Col> )}
                    </Row>
                }

                <Row style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <Col md="10" lg="10" sm="12" xl="10">
                        <InputGroup className="mb-2 lg-group" style={{

                            margin: "30px 0px",
                            background: "grey",
                            borderRadius: "32px",
                            border: "0px",
                            height: "50px",
                            paddingLeft: "10px",
                            border: "0px"


                        }}
                        >
                            <InputGroup.Text>$</InputGroup.Text>
                            <FormControl
                                style={{ border: "0px" }}
                                value={value}
                                onChange={( event ) => {
                                    if ( isNumber( event.target.value ) ) {
                                        setValue( event.target.value )
                                    }
                                }}
                                id="inlineFormInputGroup"
                                placeholder="" />
                        </InputGroup>

                    </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col md="10" lg="10" sm="12" xl="10">
                        <PayPalButton
                            amount={value}
                            shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                            onSuccess={( details, data ) => {
                                console.log( "Transaction completed by " + JSON.stringify( details ) );
                                console.log( "data", data )

                                // OPTIONAL: Call your server to save the transaction
                                return fetch( "https://api.dev.itrazoadi.com/kaiejin/paypal", {
                                    method: "post",
                                    body: data
                                } );
                            }}
                            options={{
                                clientId: "Aa3goU8mKl9SLmR9aqWyJ2b6xBuZzuay-TQhcYFbng-BSqtOlziBFlhPIbgXlCxj057on09AwYQ4CPJJ"
                            }}
                            style={{
                                layout: 'vertical',
                                color: 'black',
                                shape: 'pill',
                                label: 'pay'
                            }}
                        />

                    </Col>
                </Row>
            </div>
        </Container>


    </div>
        <Footer />

    </>
    )
}

export default Donation