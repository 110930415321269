import React, { useState } from 'react'
import './partners.css'
import { Container, Row, Col, Image, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const data = [
    {

        url:'https://cdn.dev.kaiejin.org.au/static-content/partners/MLDRIN.png' ,
        title: "Murray Lower Darling Rivers Indigenous Nations (MLDRIN)",
        partnerURL: "https://www.mldrin.org.au",
        desc: "Confederation of sovereign First Nations from the Southern part of the Murray Darling Basin, focussed on caring for rivers and achieving water rights for Aboriginal people. As a founding ally and supporter of Tati Tati Kaiejin, MLDRIN have paved the way for Cultural Flows and First Nations voices to be heard for water rights."
    },
    {
        url: 'https://cdn.dev.kaiejin.org.au/static-content/partners/eja.jpg',
        title: "Environmental Justice Australia",
        partnerURL: "https://www.envirojustice.org.au",
        desc: "Not-for-profit legal organisation aimed at aiding community organisations in protecting natural resources. EJA have collaborated with Tati Tati Kaiejin on the pursuit of Cultural Flows for Margooya Lagoon, researching and reporting on the legal frameworks and policies in place that are barriers to First Nations access to water and water rights.",

    },
    {
        url: "https://cdn.dev.kaiejin.org.au/static-content/partners/DELWP.jpg",
        title: "Department of Environment, Land, Water and Planning (DELWP)",
        partnerURL: "https://www.delwp.vic.gov.au",
        desc: "Government department in Victoria that works in partnership with a range of agencies and stakeholders to protect and preserve Victoria’s native landscape through a range of biodiversity, wildlife, sustainability, climate change and community programs. DELWP have committed to fund and support Tati Tati Kaiejin in their objectives of caring for Country and waterways. ",
    },
    {
        url:"https://cdn.dev.kaiejin.org.au/static-content/partners/Parks Vic.png",
        title: "Parks Victoria ",
        partnerURL: "https://www.parks.vic.gov.au",
        desc: "Through their Managing Country Together framework, Parks Victoria are commitment to strong, meaningful partnerships with Traditional Owners. Managing Country Together is about understanding and respecting Traditional Owner rights and values, taking responsibility for protecting Aboriginal cultural heritage on the parks estate and offering the best support to Aboriginal people working in the park management sector.",
    },
    {
        url: 'https://cdn.dev.kaiejin.org.au/static-content/partners/VEWH.jpg',
        title: "Victorian Environmental Water Holder (VEWH)",
        partnerURL: "https://www.vewh.vic.gov.au",
        desc: "As the independent body responsible for holding Victoria’s environmental water, Tati Tati Kaiejin and the VEWH are building relationships to further goals of First Nations self-determination and sovereignty.",
    }
]
function MyVerticallyCenteredModal( props ) {
    console.log( "props", props )
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header> */}
            <Modal.Body>
                <div class="card" style={{ width: "100%", padding: "5%" }}>
                    <Image src={props.url} rounded width="10%" className="img-gal" />
                    <div className="card-body">
                        <h1 className="card-title">{props.title}</h1>
                        <p className="card-text">
                            {props.desc}
                        </p>
                        <Link to={{ pathname: props.partnerURL }} target="_blank" >{props.title}</Link>

                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
}
const Partners = () => {
    const [selectedImage, setSelectedImage] = useState( "" )
    const [selectedData, setSelectedData] = useState( {} )
    const [modalShow, setModalShow] = React.useState( false );
    return (
        <div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow( false )}
                url={selectedImage}
                title={selectedData.title}
                desc={selectedData.desc}
                partnerURL={selectedData.partnerURL}
            />
            <Row>
                {data.map( rec => <Col xs={6} md={4} style={{ padding: "10px" }} className="image-gallary" >
                    <Image
                        src={rec.url}
                        rounded width="70%"
                        onClick={() => {
                            setSelectedImage( rec.url )
                            setModalShow( true )
                            setSelectedData( rec )
                        }}
                        className="img-gal" />
                </Col> )}
            </Row>
        </div>
    )
}
export default Partners