import React from "react";
import { Component } from "react";
import { Carousel, Col, Modal, Row } from "react-bootstrap";
import "./Home.css";

import Member from "./Member";
import Partners from "../partners/Partners";
import { Link } from "react-router-dom";
import Axios from "axios";
import Footer from "../footer/Footer";

const membersData = [
  {
    name: "Brendan Kennedy",
    designation: "Director | Aboriginal Water Officer",
    image:
      "https://cdn.dev.kaiejin.org.au/static-content/staff/BrendanKennedy.jpg",
    contact: "brendan@kaiejin.org.au | 04 28770630 ",
    description: [
      "Descendant of the Murray River & Murrumbidgee River Traditional Nations, Brendan is a leader in all areas advocating for First Nations rights to water and Country, Tati Tati nation sovereignty, and community self-determination. He has taught language in schools and early childhood centres in Robinvale and continues to share and create learning opportunities for the North-western Victorian Aboriginal Communities. ",
      " Tati Tati, Latji Latji, Wadi Wadi, Mutti Mutti man, Brendan has been reviving culture and language for over 15 years, producing language books, animations, digital apps, Country reports and art exhibitions.",
      " Brendan’s roles as Aboriginal Water Officer and Tati Tati Kaiejin Cultural Engagement Officer include continuing efforts to achieve Cultural Flows and purinya kaiejin kathiwatha – healthy water flowing.",
    ],
  },
  {
    name: "Melissa Kennedy",
    designation: "CEO | Aboriginal Water Officer ",
    image: "https://cdn.dev.kaiejin.org.au/static-content/staff/melisaa.png",
    contact: "melissa@kaiejin.org.au | 04 31888139",
    description: [
      "As cofounder and CEO of Tati Tati Kaiejin, Melissa’s aim is to uplift First Nations people and support sustainable environmental practices through the progression, restoration and replenishment of culture and Country. ",
      " Also holding the position of Tati Tati Aboriginal Water Officer, Melissa’s role in the community includes supporting river restoration projects, creating spaces for traditional knowledge gathering and sharing, as well as engaging with various environmental stakeholders to progress Tati Tati water and landcare objectives.",
      "Recently starting on as a Research Fellow with the University of Melbourne’s Centre of Excellence Places Program, Melissa’s focus is on better understanding and documenting the relationship between water (through Cultural Flows) and Indigenous well-being over the lifecourse, supporting outcomes of policy change and First Nations empowerment.",
    ],
  },
  {
    name: "Rachel Kennedy",
    designation: "Director | Project Manager",
    image: "https://cdn.dev.kaiejin.org.au/static-content/staff/Rachel.jpg",
    contact: "rachel@kaiejin.org.au | 04 74353162",
    description: [
      "Rachel cofounded Tati Tati Kaiejin with aspirations of restoring Country to past conditions where First Nations cared for and sustained the health of land, water and animals. Rachel has a strong focus on providing opportunities for Indigenous youth by nurturing individual goals, creating employment opportunities, prioritising cultural knowledges, and facilitating the intergenerational sharing of culture and lore.",
      "As Project Manager, Rachel is responsible for planning and overseeing all Tati Tati Kaiejin projects, working closely with Tati Tati people to ensure all projects are reflective of First Nations values, aspirations, and objectives.",
      " Rachel continues to promote personal development by participating in workshops and seminars that facilitate professional growth. By furthering strengths and knowledge this way, Tati Tati Kaiejin can develop and thrive as a First Nations resource for genuine change and empowerment.",
    ],
  },
];
const data = [
  {
    name: "Water",
    nicName: "KAIEJIN",
    imageUrl:
      "https://cdn.dev.kaiejin.org.au/static-content/Hero images/Water.jpg",
    link: "/water",
  },
  {
    name: "Language",
    nicName: "Tyalingi",
    imageUrl:
      "https://cdn.dev.kaiejin.org.au/static-content/Hero images/Language.jpg",
    link: "/language",
  },
  {
    name: "Land",
    nicName: "RUWE",
    imageUrl:
      "https://cdn.dev.kaiejin.org.au/static-content/Hero images/Land 2.jpg",
    link: "/land",
  },
  {
    name: "Fire",
    nicName: "NARUNGE",
    imageUrl:
      "https://cdn.dev.kaiejin.org.au/static-content/Hero images/Fire 2.jpg",
    link: "/fire",
  },
  {
    name: "Community",
    nicName: "KULINGI",
    imageUrl:
      "https://cdn.dev.kaiejin.org.au/static-content/Hero images/Community.jpg",
    link: "/community",
  },
];

const MyVerticallyCenteredModal = (props) => {
  console.log("props", props);

  return (
    <Modal
      style={{ marginTop: "60px" }}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Image Preview
          </Modal.Title>
        </Modal.Header> */}
      <Modal.Body>
        <div className="card-land" style={{ padding: "5%" }}>
          {/* <Row> */}
          {/* <AliceCarousel > */}
          <div style={{ marginBottom: "60px", textAlign: "center" }}>
            {props.desc.map((rec) => {
              return <p style={{ textAlign: "start" }}>{rec}</p>;
            })}
            <button
              style={{
                marginTop: "10px",
                borderRadius: "5px",
                fontFamily: "open sans",
              }}
              onClick={props.onHide}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const generateTokens = () => {
  const accessToken = sessionStorage.getItem("accesstoken");
  console.log(accessToken);
  Axios({
    method: "POST",
    url: process.env.REACT_APP_API_BASE + "/auth",
    data: { access_token: accessToken },
  }).then((response) => {
    console.log(response.data);
    // setAccessKeyId(response.AccessKeyId)
    sessionStorage.setItem("AccessKeyId", response.data.AccessKeyId);
    sessionStorage.setItem("AccessKeySec", response.data.SecretAccessKey);
    sessionStorage.setItem("sessionToken", response.data.SessionToken);

    // setAccessKeySec(response.SecretAccessKey)
  });
};

class About extends Component {
  state = {
    showModal: true,
    desc: [
      "We, Tati Tati, as Traditional Owners of Northwest Murray River Country, have never ceded sovereignty, nor ever relinquished, given, or traded our inherent rights to Country and water.",
      "We ask all those willing to hear our story to do so with open eyes, minds, and hearts, and join us as allies in the journey of healing Country, revitalizing culture, and restoring justice.",
      "We are, and will forever be, the rightful people to care for Country.",
      "We advise Aboriginal and Torres Strait Islander brothers and sisters, Aunties and Uncles, the following content may contain images or quotes from people who have passed.",
    ],
  };

  componentDidMount = () => {
    // window.scrollTo( 0, 0 )
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 40);

    window.scrollTo(0, 0);

    let visited = localStorage["alreadyVisited"];
    if (visited) {
      this.setState({ showModal: false });
      //do not view Popup
    } else {
      //this is the first time
      localStorage["alreadyVisited"] = true;
      this.setState({ showModal: true });
    }
    const role = localStorage.getItem("userRole");
    console.log("damin", role);

    if (role == "admin") {
      generateTokens();
    }
  };

  render() {
    return (
      <div className="root-about">
        {this.state.showModal ? (
          <MyVerticallyCenteredModal
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            desc={this.state.desc}
          />
        ) : null}
        <div id="hero">
          <div className="hero-container">
            <h1 className="welcome-text" style={{ color: "#dede96" }}>
              Purinya kaiejin kathiwatha
            </h1>
            <h2 style={{ color: "#dede96" }}>Healthy water flowing</h2>
            <a className="arrow-icon" href="#about">
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </a>
          </div>
        </div>
        <div>
          <div style={{ background: "rgb(37,36,36)", overflow: "hidden" }}>
            <Row>
              <Col md="12" id="about">
                <div className="explore">
                  <p className="typo">EXPLORE</p>
                  <h4 className="explore-typo">EXPLORE</h4>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: "-20px" }}>
              <Row id="portfolio" className="portfolio explore">
                <img
                  src={
                    "https://cdn.dev.kaiejin.org.au/static-content/about/back.png"
                  }
                  className="back-one"
                />
                {data.map((rec) => (
                  <Col md="5" className="portfolio-item filter-app">
                    <Link to={rec.link}>
                      {" "}
                      <img
                        src={rec.imageUrl}
                        className="img-fluid"
                        alt=""
                        width="100%"
                      />
                      <div className="portfolio-info">
                        <h1 className="text-nic">{rec.name}</h1>
                        <h1 className="text-top">{rec.nicName}</h1>
                        <a
                          href={rec.link}
                          data-gall="portfolioGallery"
                          class="venobox preview-link"
                          title="App 1"
                        >
                          <i class="bx bx-plus"></i>
                        </a>
                        <a
                          href="portfolio-details.html"
                          class="details-link"
                          title="More Details"
                        >
                          <i class="bx bx-link"></i>
                        </a>
                      </div>
                    </Link>
                  </Col>
                ))}
                <img
                  src={
                    "https://cdn.dev.kaiejin.org.au/static-content/about/back.png"
                  }
                  className="back-two"
                />
                <img
                  src={
                    "https://cdn.dev.kaiejin.org.au/static-content/about/back.png"
                  }
                  className="back-three"
                />
              </Row>
            </Row>
            <Row className="portfolio explore">
              <Col md="12" lg="12">
                <div className="explore">
                  <p className="typo">ABOUT US</p>
                  <h4 className="explore-typo">ABOUT US</h4>
                </div>
              </Col>
            </Row>
            <Row
              style={{ padding: "0px 10%", background: "rgb(37 36 36 / 0%)" }}
            >
              <Col sm="12" md="6" xs="12">
                <img
                  src={
                    "https://cdn.dev.kaiejin.org.au/static-content/about/back.png"
                  }
                  className="back-four"
                />

                <Carousel variant="dark" className="first-img">
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/about/aboutOne.jpg"
                      }
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/about/aboutThree.jpg"
                      }
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/about/aboutFive.jpg"
                      }
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </Col>

              <Col
                sm="12"
                md="6"
                xs="12"
                style={{ background: "rgb(37 36 36 / 0%)" }}
              >
                <div
                  style={{ background: "rgb(37 36 36 / 0%);", color: "white" }}
                >
                  <p className="">
                    Tati Tati Kaiejin is an Indigenous-owned and operated
                    not-for-profit organisation whose aim is to reconnect First
                    Nations peoples to waterways and Country.
                  </p>
                  <p>
                    A core value of Tati Tati Kaiejin is to emphasise and
                    promote the cultural, environmental, and spiritual
                    knowledges of Tati Tati Traditional Owners to improve and
                    sustain the well-being of Country, water, animals, culture,
                    and peoples.
                  </p>
                  <p>
                    Kaiejin is working on projects to deliver outcomes for
                    Traditional Owners around water, land and environment and to
                    revive traditional knowledges, cultural activities, monitor
                    Country, evaluate and manage the health of waterways, and
                    provide opportunities for greater participation and
                    employment for Tati Tati peoples in water management and
                    planning.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="portfolio explore progressClass">
              <Col md="6" lg="6">
                <div className="card-body">
                  <h1 className="jt-init head head-pro">Progression</h1>
                  <p className="start">
                    Progression of Traditional Owner priorities, values,
                    cultures, knowledges, language, and aspirations. Tati Tati
                    Kaiejin are committed to advancing employment opportunities
                    and First Nations participation in projects on Country, of
                    which traditional Owners hold responsibilities and inherent
                    right to care for. First Nations cultural aspirations will
                    be central to project objectives and outcomes.
                  </p>
                  <p className="start">
                    Tati Tati Kaiejin prioritises First Nations voices and aims
                    to imbed them as central to decision-making processes,
                    uplifting cultural knowledges and practices as the only way
                    forward for an environmentally sustainable future of Caring
                    for Country.
                  </p>
                </div>
              </Col>
              <Col sm="" md="6" xs="12" lg="6">
                <img
                  src={
                    "https://cdn.dev.kaiejin.org.au/static-content/about/back.png"
                  }
                  className="back-five"
                />
                <Carousel
                  variant="dark"
                  className="second-img"
                  controls={false}
                >
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/goals/goalsTwo.jpg"
                      }
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
            <Row
              className="portfolio explore"
              style={{ padding: "0px 30px", marginTop: "40px" }}
            ></Row>
            <Row className="portfolio explore" style={{ padding: "0px 10%" }}>
              <Col sm="" md="6" xs="12" lg="6">
                <Carousel variant="dark" className="first-img" controls={false}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/goals/goalsOne.jpg"
                      }
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Col>
              <Col md="6" lg="6" sm="12">
                <div className="card-body start">
                  <h1
                    className="jt-init head restClass"
                    style={{ color: "rgb(195,127,78)" }}
                  >
                    Restoration
                  </h1>
                  <p className=" ">
                    Restoration of Country. Tati Tati Kaiejin are committed to
                    the care and restoration of Country, as a key element of
                    Traditional Owner understandings of knowing and being is
                    tied to the health of Country from which our ancestors
                    belonged to.
                  </p>
                  <p
                    className="head text-center"
                    style={{ fontSize: "40px", fontFamily: "creamy coconut" }}
                  >
                    Healthy Country = Healthy People.
                  </p>
                </div>
              </Col>
            </Row>
            <Row
              className="portfolio explore"
              style={{ padding: "0px 10% 100px", marginTop: "40px" }}
            >
              <Col md="6" lg="6" sm="12">
                <div className="card-body start">
                  <h1 className="jt-init head replenClass">Replenishment</h1>
                  <p className=" ">
                    Replenishment of waterways. Tati Tati Kaiejin will promote
                    and support Traditional Owner practices and priorities to
                    replenish rivers, lagoons, floodplains, creeks, and
                    waterholes on Tati Tati Country.
                  </p>
                  <p className="">
                    The mismanagement of water in the Murray Darling Basin is of
                    grave concern to Tati Tati traditional Owners, particularly
                    surrounding Margooya Lagoon. Tati Tati Kaiejin aim to
                    install the Cultural Flows methodology as the way forward
                    for First Nations water management and create a healthy and
                    thriving Lagoon.
                  </p>
                </div>
              </Col>
              <Col sm="" md="6" xs="12" lg="6">
                <Carousel
                  variant="dark"
                  className="second-img"
                  controls={false}
                >
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        "https://cdn.dev.kaiejin.org.au/static-content/goals/goalsThree.jpg"
                      }
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>

            <Row
              className="portfolio explore"
              style={{ padding: "0px 7%", marginTop: "40px" }}
            >
              {membersData.map((rec) => {
                return (
                  <Col lg="4" md="4">
                    <Member
                      name={rec.name}
                      image={rec.image}
                      description={rec.description}
                      designation={rec.designation}
                      contact={rec.contact}
                    />
                  </Col>
                );
              })}
            </Row>
            <Row
              style={{
                padding: "0px 5% 10px 10%",
                marginTop: "10px",
                background: "#c8c5c5",
              }}
            >
              <h1
                className="jt-init head mb-30"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                Partners
              </h1>
              <Partners />
            </Row>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default About;
