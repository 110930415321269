import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Image,
  Modal,
  ToggleButton,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Container from "reactstrap/lib/Container";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./dashboard.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRef } from "react";
import "./table-border.css";
import ReactToPrint from "react-to-print";

import { HiRefresh } from "react-icons/hi";
import { isMobile } from "mobile-device-detect";
import { Select, Table } from "antd";
import Footer from "../footer/Footer";
const { Option } = Select;
const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
  },

  {
    title: "Date",
    dataIndex: "created_datetime",
    render: (_, rec) => {
      return <p>{moment(rec.created_datetime).format("DD/MM/YYYY")}</p>;
    },
  },
  {
    title: "Address",
    dataIndex: "address",
  },
  {
    title: "Postal Code",
    dataIndex: "postalCode",
  },
  {
    title: "State",
    dataIndex: "state",
  },
  {
    title: "Suburb",
    dataIndex: "suburb",
  },
  {
    title: "Supplier Statement",
    dataIndex: "supplierStatement",

    render: (_, rec) => {
      if (rec.supplierStatement) {
        return <p style={{ fontFamily: "open sans", color: "white" }}>true</p>;
      } else {
        return <p style={{ fontFamily: "open sans", color: "white" }}>false</p>;
      }
    },
  },
];
const SuppliersPage = (props) => {
  const [supplier, setSuppliers] = useState([]);

  const [search_date, setSearch_date] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [supplierFull, setSuppliersFull] = useState([]);
  const [scroll, setScroll] = useState({});
  const componentRef = useRef();

  const getSuppliers = () => {
    Axios({
      method: "GET",
      url: process.env.REACT_APP_API_BASE + "/supplier",
    }).then((response) => {
      console.log(response.data.body);
      const lengthOrder = response.data.body;
      lengthOrder.map((rec) => {
        // console.log( moment.utc(rec.created_datetime ).local().format('YYYY-MM-DD HH:mm:ss'), rec.lastName )
        return rec;
      });
      setSuppliers(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      setSuppliersFull(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      // setDataa( { suppliers: lengthOrder } )
      setFilteredData(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      //   },
    });
  };
  useEffect(() => {
    getSuppliers();
    if (isMobile) {
      setScroll({
        x: 1200,
      });
    }
  }, []);
  return (
    <>
      <Container
        style={{
          background: "rgb(50, 50, 50)",
          marginTop: "20px",
          minHeight: "100vh",
        }}
      >
        <h1 style={{ color: "white" }}>Suppliers</h1>
        <div style={{ marginBottom: "30px" }}>
          <Row>
            <Col md="8" sm="12" className="d-1">
              <div
                style={{ display: "inline-block" }}
                className="date-picker-text"
              >
                {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                <ReactDatePicker
                  selected={search_date}
                  onChange={(date) => {
                    console.log(
                      "supplier,",
                      supplier.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setFilteredData(
                      supplier.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setSearch_date(date);
                  }}
                  dateFormat="Pp"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div style={{ display: "inline-block" }}>
                <HiRefresh
                  style={{ color: "white", fontSize: "30px", fill: "#4D6646" }}
                  onClick={() => {
                    setFilteredData(supplier);
                    setSearch_date(new Date());
                  }}
                />
              </div>
            </Col>

            <Col md="4" sm="12">
              <div className="btn-ex">
                <div ref={componentRef} className="print-screen">
                  <table>
                    <tbody>
                      <tr>
                        {/* {Object.keys( filteredData.length  && filteredData[0] ).map( rec => <th>{rec}</th> )} */}
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Date</th>
                        <th>Address</th>
                        <th>Postal Code</th>
                        <th>State</th>
                        <th>Supplier Statement</th>
                      </tr>
                      {filteredData.map((rec) => {
                        return (
                          <tr>
                            <td>{rec.firstName}</td>
                            <td>{rec.lastName}</td>
                            <td>{rec.created_datetime}</td>
                            <td>{rec.address}</td>
                            <td>{rec.postalCode}</td>
                            <td>{rec.state}</td>
                            <td>
                              <ToggleButton
                                className="mb-2 back"
                                id="toggle-check"
                                type="checkbox"
                                variant="outline-primary"
                                checked={rec.supplierStatement}
                                value="1"
                                // onChange={( e ) => setIsPrivate( e.currentTarget.checked )}
                                style={{
                                  color: "white",
                                  borderColor: "black",
                                  border: "0px",
                                }}
                              ></ToggleButton>
                              Made in the course or furtherance of an activity
                              done as a private recreational pursuit or hobby
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <ReactToPrint
                  trigger={() => (
                    <button
                      size={36}
                      style={{
                        float: "right",
                        // marginRight: "10px",
                        cursor: "pointer",
                        color: "white",
                        borderRadius: "32px",
                        fontFamily: "open sans",
                        width: "165px",
                      }}
                    >
                      Export
                    </button>
                  )}
                  content={() => componentRef.current}
                  pageStyle={{ background: "" }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Table
          columns={columns}
          dataSource={filteredData}
          // pagination
          id="table-to-xls"
          //   scroll={scroll}
          scroll={{ x: 600 }}
          responsive
          // style={{ borderRadius: "32px" }}
        />
      </Container>
      <Footer />
    </>
  );
};
export default SuppliersPage;
