import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Axios from "axios";
import Footer from "../footer/Footer";
const Awa = () => {
  const [quickSightUrl, setQuickSightUrl] = React.useState("");
  const quickURL = () => {
    const tokenString = localStorage.getItem("idtoken");

    console.log("in string", tokenString);
    if (tokenString) {
      Axios({
        method: "POST",
        url: process.env.REACT_APP_API_QUICKSIGHT + `dashboard-embed`,
        data: { id_token: tokenString },
      }).then((res) => {
        console.log("res.toString()", res.data.EmbedUrl);
        setQuickSightUrl(res.data.EmbedUrl);
      });
    }
  };
  useEffect(() => {
    quickURL();
  }, []);
  return (
    <>
      <Container style={{ background: "rgb(50, 50, 50)", marginTop: "20px" }}>
        <Container style={{ height: "90vh" }}>
          <h1 style={{ color: "white" }}>AWA Insights</h1>
          <div style={{ marginBottom: "10px" }}>
            <iframe
              width="100%"
              style={{ height: "85vh" }}
              src={quickSightUrl}
            ></iframe>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
};
export default Awa;
