import { Input } from 'antd'
import Axios from 'axios'
import React, { useRef, useState } from 'react'
import { Container, Row, Col, Form, Button, FormGroup, FormControl } from 'react-bootstrap'
import ReactToPrint from 'react-to-print'
import Footer from '../footer/Footer'
const SupplierDetails = () => {
    const [message, setMessage] = useState( "" )
    const componentRef = useRef();
    const [data, setData] = useState( {
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        suburb: "",
        state: "",
        postalCode: "",
        supplierStatement: false
    } )
    const handleSubmit = () => {
        const { firstName, lastName, address, suburb, state, supplierStatement, phone } = data
        if ( firstName != "" && lastName != "" && address != "" && suburb != "" && state != "" && supplierStatement != false && phone != "" ) {

            let phonenumber = ""
            if ( data.phone.charAt( 0 ) != 0 ) {
                phonenumber = "+61" + data.phone
            } else {
                phonenumber = "+61" + data.phone.slice( 1 );
            }
            Axios.post( `${ process.env.REACT_APP_API_BASE }/supplier`, { ...data, phone: phonenumber } ).then( rec => {
                console.log( 'data', rec )
                if ( rec.status == 200 ) {
                    setData( {
                        firstName: "",
                        lastName: "",
                        address: "",
                        suburb: "",
                        state: "",
                        postalCode: "",
                        supplierStatement: false,
                        phone: ""
                    } )
                    setMessage( "" )
                    alert( 'data is succefully added !' );

                }
            } ).catch( e => {
                setData( {
                    firstName: "",
                    lastName: "",
                    address: "",
                    suburb: "",
                    state: "",
                    postalCode: "",
                    supplierStatement: false,
                    phone: ""
                } )
                setMessage( "" )
                alert( "You already submitted the form for the day." )
                // console.log( e )
            } )
        } else {
            setMessage( "Please Fill all the Fields" )
        }
        // console.log( "e", data )
    }
    const handleChange = ( e ) => {
        const name = e.target.name
        setData( {
            ...data,
            [name]: e.target.value
        } )
    }
    const role = localStorage.getItem( 'userRole' )
    return (
        <>
            <div className="page-section container contact-root" style={{ maxWidth: "100%" }}>
                <div className="align-center mrg-xl-bottom">
                    <h2 className=" underlined inline-block" style={{ color: "white" }}>Supplier Details</h2>
                </div>
                {message != "" ? <p style={{ color: "red", textAlign: "center" }}>{message}</p> : null}

                <Form onSubmit={handleSubmit} className="container-xs pad-md" >
                    <div ref={componentRef} >
                        <Row className="mb-3">
                            <Col sm="12" md="6" lg="6">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label className="label-clr">First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        style={{ color: "white" }}
                                        value={data.firstName}
                                        placeholder="First Name"
                                        onChange={( e ) => handleChange( e )}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="6" lg="6">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label className="label-clr">Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        style={{ color: "white" }}
                                        value={data.lastName}
                                        placeholder="Last Name"
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="12" lg="12">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label className="label-clr">Phone</Form.Label>

                                    <Input
                                        addonBefore="+61"
                                        value={data.phone}
                                        // placeholder="Phone"
                                        onChange={( e ) => {
                                            if ( e.target.value.length > 10 ) {
                                                alert( "You could not add more then 10 digits" )
                                            } else {
                                                setData( {
                                                    ...data,
                                                    phone: e.target.value
                                                } )
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup className=" mb-3" controlId="formGridAddress1" style={{ marginTop: "-13px" }}>
                            <Form.Label className="label-clr" >Address</Form.Label>
                            <Form.Control
                                name="address"
                                style={{ color: "white" }}
                                placeholder="Address"
                                value={data.address}
                                onChange={( e ) => handleChange( e )}


                            />
                        </FormGroup>


                        <Row className="mb-3">
                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridCity" className='mb-3'>
                                    <Form.Label className="label-clr">Suburb/Town</Form.Label>
                                    <Form.Control
                                        style={{ color: "white" }}
                                        value={data.suburb}
                                        name="suburb"
                                        placeholder='Suburb/Town'
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridState" className='mb-3'>
                                    <Form.Label className="label-clr">State/Territory</Form.Label>
                                    <FormControl
                                        defaultValue="Select State"
                                        value={data.state}
                                        as="select"
                                        name="state"
                                        required
                                        onChange={( e ) => handleChange( e )}
                                    >
                                        <option value="">Select State</option>
                                        <option>QLD</option>
                                        <option>NT</option>
                                        <option>NSW</option>
                                        <option>SA</option>
                                        <option>VIC</option>
                                        <option>WA</option>

                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridZip" className='mb-3'>
                                    <Form.Label className="label-clr">Postal Code</Form.Label>
                                    <Form.Control
                                        name="postalCode"
                                        style={{ color: "white" }}
                                        placeholder="Postal Code"
                                        value={data.postalCode}
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <h2 style={{ color: "white" }}>Reasons for not quoting an ABN</h2>
                        <p style={{ color: "white", marginBottom: "0rem" }}>The supplier is an individual and has given the payer a written statement to the effect that the supply is:</p>
                        <FormGroup className="mb-3" id="formGridCheckbox">
                            <Form.Check
                                type="checkbox"
                                style={{ color: "white" }}
                                label="made in the course or furtherance of an activity done as a private recreational pursuit"
                                checked={data.supplierStatement}
                                onChange={( e ) => {
                                    setData( { ...data, supplierStatement: e.target.checked } )
                                }}
                            />
                        </FormGroup>
                    </div>
                    <Button
                        variant="primary"
                        className="submit-btn btn-jumbo  inline-block"
                        style={{
                            fontFamily: 'Creamy Coconut',
                            letterSpacing: '2px',
                            fontSize: '25px', width: '25%', background: '#C58151', border: '1px solid #C58151'
                        }}
                        onClick={( e ) => handleSubmit()}
                    >
                        Submit
                    </Button>

                    {role == "admin" ?

                        <ReactToPrint
                            trigger={() => <Button size={36} style={{
                                float: "right",
                                marginRight: "25px",
                                cursor: "pointer",
                                fontFamily: 'Creamy Coconut',
                                letterSpacing: '2px',
                                fontSize: '25px',
                                width: '25%',
                                background: '#C58151',
                                border: '1px solid #C58151'
                            }} >Export</Button>}
                            content={() => componentRef.current}

                            pageStyle={{ background: "" }}
                        />

                        : null}
                </Form>


            </div>
            <Footer />
        </>
    )
}
export default SupplierDetails