import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Button, Form, Input } from 'reactstrap';
import { Table } from 'antd'
import EmailValidator from 'email-validator';
import './user.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Axios from 'axios';
import Footer from '../footer/Footer';
function TabPanel( props ) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${ index }`}
            aria-labelledby={`scrollable-auto-tab-${ index }`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps( index ) {
    return {
        id: `scrollable-auto-tab-${ index }`,
        "aria-controls": `scrollable-auto-tabpanel-${ index }`
    };
}

const useStyles = makeStyles( theme => ( {
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
} ) );

const Location = () => {

    const [usersData, setUsersData] = React.useState( [] )
    const [usersCogData, setUsersCogData] = React.useState( [] )
    const [open, setOpen] = React.useState( false );
    const [openProdSub, setOpenProdSub] = React.useState( false );
    const [openProd, setOpenProd] = React.useState( false );
    const [updateOpen, setUpdateOpen] = React.useState( false );
    const [btnUpdate, setBtnUpdate] = React.useState( false );
    const [disabletext, setDisabletext] = React.useState( false );
    const [showFieldSupp, setShowFieldSupp] = React.useState( false );
    const [post, setPostArray] = React.useState( [] );
    const [value, setValue] = React.useState( 0 );
    const [locations, setLocations] = useState( [] )
    const scrollToRef = ( ref ) => window.scrollTo( 0, ref.current.offsetTop )
    const handleChange = ( event, newValue ) => {
        setValue( newValue );
    };

    const [inputField, setInputField] = React.useState( {

        'role': "",
        'email': "",
        'name': "",
        location: "",
        code: "",


    } )
    const [inputFieldStatusSupplier, setInputFieldStatusSupplier] = useState( {
        contact_type: '',
        comment: ''
    } )
    const [deliveryAddresses, setDeliveryAddresses] = useState( [{
        street_number_and_name: '',
        suburb: '',
        business_state: '',
        postcode: '',
    }] )
    const [validateRole, setValidateRole] = React.useState( false );
    const [validate, setValidate] = React.useState( false );

    const editLocation = ( e, details ) => {
        e.preventDefault();

        console.log( details )
        setDisabletext( true );
        setInputField( {
            ...inputField,
            location: details.location,
            code: details.code
        } );
        setBtnUpdate( true )
        if ( !details.location && details.location == undefined ) {
            console.log( 'inside undefined role' )
            setInputField( { role: '' } )
        }
        if ( !details.code && details.code == undefined ) {
            console.log( 'inside undefined roleee' )
            setInputField( { name: '' } )
        }
    }

    const deleteLocation = ( e, details ) => {
        e.preventDefault();
        console.log( details )
        // const businesName = details.business_name;
        confirmAlert( {
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.delete( `${ process.env.REACT_APP_API_BASE }/location?location=${ details.location }` )
                            .then( ( response ) => {
                                console.log( response );
                                getLocations();

                            } )
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        } );

    }
    const inputsHandler = ( e ) => {
        console.log( inputField );
        console.log( e.target );
        setInputField( { ...inputField, [e.target.name]: e.target.value } )
        console.log( e.target.value )
        setValidate( false )
        setValidateRole( false )

    }
    const getLocations = () => {
        Axios.get( `${ process.env.REACT_APP_API_BASE }/location` ).then( res => {
            console.log( "body===>", res )
            if ( res.data ) {
                setLocations( res.data.body )
            }

        } )
    }
    const listcustomer = () => {

        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",

        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const listdetails = response.data.body;
                setPostArray( listdetails );

            } )
        // })
    }
    const submitButton = () => {
        // console.log()
        setDisabletext( false );
        console.log( inputField.email )
        // const {email,password} = this.state
        // validate Email
        if ( !EmailValidator.validate( inputField.email ) ) {
            console.log( 'wrong emial' )
            setValidate( true );
            return;
        }
        if ( !inputField.role ) {
            console.log( 'inside empty' );
            setValidateRole( true );
            return;
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/create",
            "data": inputField
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    setOpen( true );
                    setTimeout( () => {
                        setOpen( false );
                    }, 1500 );
                    listcustomer();
                }
                setInputField( {
                    email: '',
                    name: '',
                    role: ''

                } )
            } )
            .catch( ( error ) => {
                console.log( error )
                if ( error.message == "Request failed with status code 401" ) {
                    alert( "User account already exists." )

                } else {
                    alert( error.message )
                }
            } )
    }
    const submitLocation = () => {
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/location",
            "data": {
                location: inputField.location,
                code: inputField.code
            }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    setOpen( true );
                    setTimeout( () => {
                        setOpen( false );
                    }, 1500 );

                }
                setInputField( {
                    ...inputField,
                    location: "",
                    code: ""

                } )
            } )
            .catch( ( error ) => {
                console.log( error )
                if ( error.message == "Request failed with status code 401" ) {
                    alert( "." )

                } else {
                    alert( error.message )
                }
            } )
    }
    const updateButton = () => {
        if ( !inputField.email ) {
            console.log( 'inside empty' );
            setValidate( true );

            return;
        }
        console.log( inputField )
        var data = {
            'role': inputField.role,
            'name': inputField.name,
            'email': inputField.email
        }
        // return;
        axios( {
            "method": "POST",
            "url": process.env.REACT_APP_API_BASE + "/user/update",
            "data": data
        } )
            .then( ( response ) => {
                console.log( response )
                if ( response.status == 200 ) {
                    setUpdateOpen( true );
                    setTimeout( () => {
                        setUpdateOpen( false );
                    }, 1500 );
                    listcustomer();
                    setBtnUpdate( false )
                    setDisabletext( false );
                    setInputField( {
                        email: '',
                        name: '',
                        role: ''
                    } )

                }
            } )
    }
    const myRef = useRef( null )
    const executeScroll = () => scrollToRef( myRef )
    const updateLocation = () => {
        if ( !inputField.location ) {
            console.log( 'inside empty' );
            setValidate( true );

            return;
        }
        console.log( inputField )
        var data = {
            'location': inputField.location,
            'code': inputField.code,
        }
        // return;
        axios.put( `${ process.env.REACT_APP_API_BASE }/location`, data )
            .then( ( response ) => {
                console.log( response )
                if ( response.status == 200 ) {
                    setUpdateOpen( true );
                    setTimeout( () => {
                        setUpdateOpen( false );
                    }, 1500 );
                    getLocations();
                    setBtnUpdate( false )
                    setDisabletext( false );
                    setInputField( {
                        code: '',
                        location: '',

                    } )

                }
            } )
    }
    const getUsersList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user/list",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                setUsersCogData( lengthOrder )


                //   },
            } )
    }
    const getOrderList = () => {
        var recordUncon = '';
        axios( {
            "method": "GET",
            "url": process.env.REACT_APP_API_BASE + "/user-entry/list-unconfirmed-users",
        } )
            .then( ( response ) => {
                console.log( response.data.body );
                const lengthOrder = response.data.body;
                setUsersData( lengthOrder )


                //   },
            } )
    }
    useEffect( () => {
        getOrderList();
        getUsersList()
        listcustomer()
        getLocations()
    }, [] )
    const ConfirmOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'confirmed' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // getOrderList();
                    // setStatusOrder( true );
                    // setOpenThnk( true );
                    // setTimeout( () => {
                    //     setOpenThnk( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }

    const AdminCanOrderBtn = ( dataOrders, e ) => {
        //  e.preventDefault();
        console.log( dataOrders )
        //  return;
        axios( {
            "method": "PUT",
            "url": process.env.REACT_APP_API_BASE + "/user-entry",
            "data": { ...dataOrders, 'status': 'rejected' }
        } )
            .then( ( response ) => {
                console.log( response );
                if ( response.data ) {
                    getOrderList();
                    // console.log( response )
                    // setStatusOrder( true )
                    // setStatusCanOrder( true )
                    // setOpenThnkCanc( true );
                    // setTimeout( () => {
                    //     setOpenThnkCanc( false );
                    // }, 3000 )
                }
            } )
        // }
        // setData( [...data, { ...inputField,  dob: moment( dob ).format( 'L' ) }] )
        // setOpenThnk( true );
        // setTimeout( () => {
        //   setOpenThnk( false );

        // }, 3000 )
        // setOrderValue( {
        //   orderNumber: '',
        //   customer: "",
        //   carcassType: "",
        //   quantity: "",
        //   weight: "",
        //   pricePerKg: "",
        //   orderTotalValue: "",
        // } )

    }
    const columns = [{
        title: "Location",
        dataIndex: "location"
    },
    {
        title: "Code",
        dataIndex: "code"
    },
    {
        title: "Actions",
        dataIndex: "actions",
        render: ( _, loc ) => (
            <>

                <button
                    onClick={( e ) => {
                        editLocation( e, loc )
                        window.scrollTo( 0, 0 )
                    }
                    }
                    style={{ marginRight: '0px', background: 'transparent' }}
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                </button>

                <button onClick={( e ) => deleteLocation( e, loc )} style={{ marginRight: '0', background: 'transparent' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg></button>
            </>
        )
    },

    ]
    return (
        <>
            <div className="d-flex  justify-content-center " style={{ background: "rgb(50,50,50)", marginTop: "20px", minHeight: "100vh" }}>
                <div style={{ width: '90%', }}>


                    <Col md={12}>
                        <Row>
                            <h1 style={{ color: "white" }} >Location</h1>

                        </Row>
                        <Row>

                            <br />
                            <Form>
                                <FormGroup >
                                    <Row style={{ marginBottom: "30px" }} id="form-loc" ref={myRef}>


                                        <Col md="4">
                                            <label> Location</label>
                                            <Input
                                                type="text"
                                                name="location"
                                                onChange={inputsHandler}
                                                value={inputField.location}
                                            />
                                        </Col>

                                        <Col md="4">
                                            <label>Code</label>
                                            <Input
                                                type="text"
                                                name="code"
                                                onChange={inputsHandler}
                                                value={inputField.code}
                                            />
                                        </Col>

                                        {btnUpdate == false ? (
                                            <Col md={4} className="martop">
                                                <label style={{ color: 'transparent' }}>Update button</label><br />
                                                <Button variant="outlined" onClick={submitLocation}
                                                    color="primary" style={{ background: '#4D6646', border: '0px solid #4D6646', borderRadius: "32px" }}><span> <b> Add & Save</b> </span></Button>
                                            </Col>
                                        )
                                            :
                                            (
                                                <Col md={4} className="martop">
                                                    <label style={{ color: 'transparent' }}>Update button</label><br />
                                                    <Button variant="outlined" style={{ background: '#4D6646', border: '0px solid #4D6646', borderRadius: "32px" }}
                                                        color="primary" onClick={updateLocation}><span> <b>Update</b> </span></Button>
                                                </Col>
                                            )}
                                    </Row>


                                    <Row>
                                        {/* <Table responsive className='table-bordered boxShadow' style={{ border: '.01em solid #fff' }}>
                                        <thead>
                                            <tr>
                                                <th>Location</th>
                                                <th> Code</th>


                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {locations.map( loc =>
                                                <tr >
                                                    <td style={{ textTransform: 'capitalize' }}>{loc.location}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{loc.code}</td>

                                                    <td colSpan='38'>

                                                        <>

                                                            <button
                                                                onClick={( e ) => {
                                                                    editLocation( e, loc )
                                                                    window.scrollTo( 0, 0 )
                                                                }
                                                                }
                                                                style={{ marginRight: '0px', background: 'transparent' }}
                                                            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                </svg>
                                                            </button>

                                                            <button onClick={( e ) => deleteLocation( e, loc )} style={{ marginRight: '0', background: 'transparent' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg></button>
                                                        </>


                                                    </td>
                                                </tr>
                                            )}


                                        </tbody>
                                    </Table> */}
                                        <Table
                                            dataSource={locations}
                                            columns={columns} />
                                    </Row>


                                </FormGroup>

                            </Form>

                        </Row>
                    </Col>

                </div>
            </div>
            <Footer />
        </>

    )
}

export default Location