import React, { useState } from 'react'

function PDFViewer( props ) {
    const [contentURL, setContentURL] = useState( "" )
    const { match: { params } } = props;


    const { name, url } = params;
    console.log( name, url )

    const returnURL = ( name ) => {
        let a = ""
        switch ( name ) {
            case "1342_MILDRIN 16pp Report (Lana)_v1pages":
                a = "https://online.fliphtml5.com/bguax/jdmn/"
                break;
            case "2017 Tati Tati AWA Report":
                a = "https://online.fliphtml5.com/bguax/vivq/"
                break;
            case "Community Guide":
                a = "https://online.fliphtml5.com/bguax/zyjy/"
                break;
            case "Cultural Water for Cultural Economies Report":
                a = "https://online.fliphtml5.com/bguax/ghnz/"
                break;
            case "Establishing a Cultural Flows Model on Tati Tati Country":
                a = "https://online.fliphtml5.com/bguax/iyqx/"
                break;
            case "Fire+strategy+final[43096]":
                a = "https://online.fliphtml5.com/bguax/youn/"
                break;
            case "Margooya Lagoon Cultural Flows Management Plan":
                a = "https://online.fliphtml5.com/bguax/zxyd/"
                break;
            case "Tyalingi":
                a = "https://online.fliphtml5.com/bguax/iitn/"
                break;
            case "wangilatha wangu nga kiyawatha":
                a = "https://online.fliphtml5.com/bguax/umgh/"
                break;
                case "Yakila_Yarna_Thalingi_reduced_size":
                a = "https://online.fliphtml5.com/bguax/uvde/"
                break;

            default:
                break;
        }
        return a
    }
    return ( <iframe
                style={{ width: "100%", height: "80vh" }}
                src={returnURL( name )}
                seamless="seamless"
                scrolling="no"
                frameborder="0"
                allowtransparency="true"
                allowfullscreen="true" ></iframe>

           
    );
}


export default PDFViewer