import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  OverlayTrigger,
  Popover,
  Row,
  Col,
  Image,
  Modal,
  ToggleButton,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Container from "reactstrap/lib/Container";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./dashboard.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { FcRefresh } from "react-icons/fc";
import { IoMdRefreshCircle } from "react-icons/io";
import { HiRefresh } from "react-icons/hi";
import { FaAirbnb } from "react-icons/fa";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Select } from "antd";
const { Option } = Select;
const columns = [
  {
    name: "First Name",
    selector: (row) => row.firstName,
  },
  {
    name: "Last Name",
    selector: (row) => row.lastName,
  },

  {
    name: "Date",
    selector: (row) => moment(row.created_datetime).format("DD/MM/yyyy"),
  },
  {
    name: "Address",
    selector: (row) => row.address,
  },
  {
    name: "Postal Code",
    selector: (row) => row.postalCode,
  },
  {
    name: "State",
    selector: (row) => row.state,
  },
  {
    name: "Suburb",
    selector: (row) => row.suburb,
  },
  {
    name: "Supplier Statement",
    selector: (row) => String(row.supplierStatement),
  },
];
const columnsSurvey = [
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Place",
    selector: (row) => row.projectCode,
  },

  {
    name: "Date",
    selector: (row) => moment(row.created_datetime).format("DD/MM/yyyy"),
  },
  {
    name: "River",
    selector: (row) => row.river,
  },
  {
    name: "River Type",
    selector: (row) => row.riverType,
  },
];
const columnsUser = [
  {
    name: "First Name",
    selector: (row) => row.first_name,
  },
  {
    name: "Last Name",
    selector: (row) => row.last_name,
  },
  {
    name: "Event",
    selector: (row) => row.eventTitle,
  },
  {
    name: "Date",
    selector: (row) => moment(row.created_datetime).format("DD/MM/yyyy"),
  },
  {
    name: "Address",
    selector: (row) => row.address,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
  },
];

const Dashboard = (props) => {
  const [supplier, setSuppliers] = useState([]);
  const [supplierFull, setSuppliersFull] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [dataa, setDataa] = useState({});
  const [search_date, setSearch_date] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [search_dateSurvey, setSearch_dateSurvey] = useState(new Date());
  const [filteredDataSurvey, setFilteredDataSurvey] = useState([]);
  const [printQuestion, setPrintQuestion] = useState([
    {
      questionId: "Q1a",
      question:
        "What direct human disturbance are you aware of or have you seen in the upstream landscape?",
      answers: [],
    },
    {
      questionId: "Q1b",
      question:
        "What direct human disturbance can you see at this section of the waterway?",
      answers: [],
    },
    {
      questionId: "Q1c",
      question: "What changes to the shape of the river can you see here?",
      answers: [],
    },
    {
      questionId: "Q1d",
      question: "What changes to the flow of water can you see here?",
      answers: [],
    },
    {
      questionId: "Q1e",
      question: "Describe the water quality. What can you see or smell?",
      answers: [],
    },
    {
      questionId: "Q1f",
      question:
        "What native vegetation can you see here/what condition is it in?",
      answers: [],
    },
    {
      questionId: "Q1g",
      question: "What introduced vegetation or weeds can you see here?",
      answers: [],
    },
    {
      questionId: "Q1h",
      question: "What habitats for native animals and birds can you see here?",
      answers: [],
    },
    {
      questionId: "Q1i",
      question: "What habitats for waterbirds can you see here?",
      answers: [],
    },
    {
      questionId: "Q1j",
      question: "What native fish would you expect to find here?",
      answers: [],
    },
    {
      questionId: "Q1k",
      question: "What exotic, non-native fish would you expect to fine here?",
      answers: [],
    },
    {
      questionId: "Q3a",
      question:
        "Overall, does the current waterway health at this place support the values and uses that you want to see here?",
      answers: [],
    },
    {
      questionId: "Q3b",
      question:
        "Overall, having spent time at this place today, what is your feeling about the waterway here?",
      answers: [],
    },
    {
      questionId: "Q3c",
      question: "Why is this place important to you and/or your community?",
      answers: [],
    },
    {
      questionId: "Q3d",
      question: "How does this place benefit you and/or your community?",
      answers: [],
    },
    {
      questionId: "Q3e",
      question: "Is this place easily accessible to you and/or your community?",
      answers: [],
    },
    {
      questionId: "Q3f",
      question: "What should this place look like?",
      answers: [],
    },
    {
      questionId: "Q3g",
      question:
        "What do you want to see happen at this place to ensure that it stays/becomes healthy?",
      answers: [],
    },
  ]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [filteredDataUser, setFilteredDataUser] = useState([]);
  const [datauser, setDataUser] = useState([]);
  const componentRef = useRef();
  const componentRef1 = useRef();
  let [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [temp, setTempSurvey] = useState([]);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event);
    console.log(newValue);
  };
  const getLocations = () => {
    Axios.get(`${process.env.REACT_APP_API_BASE}/location`).then((res) => {
      console.log("body===>", res);
      if (res.data) {
        setLocations(res.data.body.map((rec) => rec.code));
      }
    });
  };
  const handleClick = (e) => {
    supplierFull.filter((rec) => {});
  };
  function MyVerticallyCenteredModal(props) {
    console.log("props", props);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
                Image Preview
              </Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="card" style={{ width: "100%", padding: "5%" }}>
            <div className="wiki-list-modal">
              <Row>
                <h1 className="card-title">{props.title}</h1>
              </Row>
              <Row>
                <table>
                  <tr style={{ border: "1px solid grey !important" }}>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                  {props.selectedData.map((rec) => {
                    return (
                      <tr style={{ border: "1px solid grey !important" }}>
                        {" "}
                        <td style={{ border: "1px solid grey !important" }}>
                          {rec.question}
                        </td>
                        <td>{rec.ans}</td>
                      </tr>
                    );
                  })}
                </table>
                {/* <button className="wiki-item" >See More</button> */}
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  const getSuppliers = () => {
    Axios({
      method: "GET",
      url: process.env.REACT_APP_API_BASE + "/supplier",
    }).then((response) => {
      console.log(response.data.body);
      const lengthOrder = response.data.body;
      lengthOrder.map((rec) => {
        // console.log( moment.utc(rec.created_datetime ).local().format('YYYY-MM-DD HH:mm:ss'), rec.lastName )
        return rec;
      });
      setSuppliers(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      setSuppliersFull(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      // setDataa( { suppliers: lengthOrder } )
      setFilteredData(
        lengthOrder.map((rec) => {
          return {
            ...rec,
            created_datetime: moment
              .utc(rec.created_datetime)
              .local()
              .format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      //   },
    });
  };
  const getSuverys = () => {
    Axios({
      method: "GET",
      url: process.env.REACT_APP_API_BASE + "/survey",
    }).then((response) => {
      console.log(response.data.body);
      const lengthOrder = response.data.body;
      lengthOrder.map((rec) => {
        Array.isArray(rec.questions) &&
          rec.questions.map((recOne) => {
            for (let i in printQuestion) {
              console.log(
                "printQuestion",
                printQuestion[i].question == recOne.question
              );
              if (printQuestion[i].question == recOne.question) {
                if (!recOne.ans == "") {
                  printQuestion[i].answers.push(recOne.ans);
                }
              }
            }
            return rec;
          });
        return rec;
      });
      setPrintQuestion([...printQuestion]);
      setSurvey(lengthOrder);
      setTempSurvey(lengthOrder);
      setFilteredDataSurvey(lengthOrder);

      //   },
    });
  };
  const getEvents = () => {
    Axios({
      method: "GET",
      url: process.env.REACT_APP_API_BASE + "/event-registeration",
    }).then((response) => {
      console.log(response.data.body);
      const lengthOrder = response.data.body;
      setFilteredDataUser(lengthOrder);
      setDataUser(lengthOrder);
      // setFilteredDataUser( lengthOrder )

      //   },
    });
  };
  const [quickSightUrl, setQuickSightUrl] = React.useState("");
  // this is needed, because InfiniteCalendar forces window scroll
  console.log("in string");
  const quickURL = () => {
    const tokenString = localStorage.getItem("idtoken");

    console.log("in string", tokenString);
    if (tokenString) {
      Axios({
        method: "POST",
        url: process.env.REACT_APP_API_QUICKSIGHT + `dashboard-embed`,
        data: { id_token: tokenString },
      }).then((res) => {
        console.log("res.toString()", res.data.EmbedUrl);
        setQuickSightUrl(res.data.EmbedUrl);
      });
    }
  };
  useEffect(() => {
    getSuppliers();
    getSuverys();
    getEvents();
    quickURL();
    getLocations();
  }, []);
  console.log("dataa", dataa);
  const children = [];
  const childrenOne = [];

  children.push(<Option key={"Select Event"}>Select Event</Option>);
  for (let i = 0; i < datauser.length; i++) {
    if (!childrenOne.find((rec) => rec == datauser[i].eventTitle)) {
      if (datauser[i].eventTitle != undefined) {
        console.log("filteredDataUser[i].eventTitle", datauser[i].eventTitle);
        childrenOne.push(datauser[i].eventTitle);
        children.push(
          <Option key={datauser[i].eventTitle}>{datauser[i].eventTitle}</Option>
        );
      }
    }
  }
  function handleChangeSelect(value) {
    console.log(`selected ${datauser}`);
    setFilteredDataUser(datauser.filter((rec) => rec.eventTitle == value));
  }
  const handleChangeSelectAWA = (e) => {
    for (let i in printQuestion) {
      printQuestion[i].answers = [];
    }
    temp
      .filter((rec) => rec.projectCode == e.target.value)
      .map((rec) => {
        Array.isArray(rec.questions) &&
          rec.questions.map((recOne) => {
            for (let i in printQuestion) {
              console.log(
                "printQuestion   4",
                printQuestion[i].question == recOne.question
              );
              if (printQuestion[i].question == recOne.question) {
                if (!recOne.ans == "") {
                  printQuestion[i].answers.push(recOne.ans);
                }
              }
            }
            return rec;
          });
        return rec;
      });
    setPrintQuestion([...printQuestion]);
    setLocation(e.target.value);
    setFilteredDataSurvey(
      temp.filter((rec) => rec.projectCode == e.target.value)
    );
  };
  useEffect(() => {
    setLocations(locations);
  }, [locations]);
  console.log("Location,", locations);
  return (
    <Container style={{ background: "rgb(37,36,36)", marginTop: "20px" }}>
      {selectedData && selectedData.length ? (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Survey Data"}
          selectedData={selectedData}
        />
      ) : null}
      <Container>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: { background: "#fff", color: "#fff" },
              }}
              className="tabb"
            >
              <Tab
                label="AWA Insights"
                value="1"
                style={{
                  padding: "12px 16px",
                  // color: 'rgba(0, 0, 0, 0.6)',
                  fontFamily: "creamy coconut",
                  fontSize: "20px",
                }}
              />
              <Tab
                label="Suppliers"
                value="2"
                style={{
                  padding: "12px 16px",
                  fontFamily: "creamy coconut",
                  fontSize: "20px",
                }}
              />
              <Tab
                label="Event Registered Users"
                value="3"
                style={{
                  padding: "12px 16px",
                  fontFamily: "creamy coconut",
                  fontSize: "20px",
                }}
              />
              <Tab
                label="Surveys"
                value="4"
                style={{
                  padding: "12px 16px",
                  color: "rgba(0, 0, 0, 0.6)",
                  fontFamily: "creamy coconut",
                  fontSize: "20px",
                }}
              />
            </TabList>
          </Box>
          <br />
          <TabPanel value="1" style={{ padding: "0px" }}></TabPanel>
          <TabPanel value="2" style={{ padding: "0px" }}>
            <h1 style={{ color: "white" }}>Suppliers</h1>
            <div style={{ marginBottom: "10px" }}>
              <div style={{ width: "20%", display: "inline-block" }}>
                {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                <ReactDatePicker
                  selected={search_date}
                  onChange={(date) => {
                    console.log(
                      "supplier,",
                      supplier.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setFilteredData(
                      supplier.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setSearch_date(date);
                  }}
                  dateFormat="Pp"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div style={{ width: "30%", display: "inline-block" }}>
                <HiRefresh
                  style={{ color: "white", fontSize: "30px", fill: "#4D6646" }}
                  onClick={() => {
                    setFilteredData(supplier);
                    setSearch_date(new Date());
                  }}
                />
              </div>
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  float: "right",
                }}
              >
                <div ref={componentRef} className="print-screen">
                  <table>
                    <tbody>
                      <tr>
                        {/* {Object.keys( filteredData.length  && filteredData[0] ).map( rec => <th>{rec}</th> )} */}
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Date</th>
                        <th>Address</th>
                        <th>Postal Code</th>
                        <th>State</th>
                        <th>Supplier Statement</th>
                      </tr>
                      {filteredData.map((rec) => {
                        return (
                          <tr>
                            <td>{rec.firstName}</td>
                            <td>{rec.lastName}</td>
                            <td>{rec.created_datetime}</td>
                            <td>{rec.address}</td>
                            <td>{rec.postalCode}</td>
                            <td>{rec.state}</td>
                            <td>
                              <ToggleButton
                                className="mb-2 back"
                                id="toggle-check"
                                type="checkbox"
                                variant="outline-primary"
                                checked={rec.supplierStatement}
                                value="1"
                                onChange={(e) =>
                                  props.setIsPrivate(e.currentTarget.checked)
                                }
                                style={{
                                  color: "white",
                                  borderColor: "black",
                                  border: "0px",
                                }}
                              ></ToggleButton>
                              Made in the course or furtherance of an activity
                              done as a private recreational pursuit or hobby
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <ReactToPrint
                  trigger={() => (
                    <button
                      size={36}
                      style={{
                        float: "right",
                        // marginRight: "10px",
                        cursor: "pointer",
                        color: "white",
                        borderRadius: "32px",
                        fontFamily: "open sans",
                        width: "165px",
                      }}
                    >
                      Export
                    </button>
                  )}
                  content={() => componentRef.current}
                  pageStyle={{ background: "" }}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              id="table-to-xls"
              style={{ borderRadius: "32px" }}
            />
          </TabPanel>
          <TabPanel value="3" style={{ padding: "0px" }}>
            <h1 style={{ color: "white" }}>Events Registered Users</h1>
            <div style={{ marginBottom: "10px" }}>
              <div style={{ width: "20%", display: "inline-block" }}>
                {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                <Select
                  style={{ width: "100%", borderRadius: "32px" }}
                  placeholder="Select Event "
                  onChange={handleChangeSelect}
                >
                  {children}
                </Select>
              </div>
              <div style={{ width: "20%", display: "inline-block" }}>
                <HiRefresh
                  style={{ color: "white", fontSize: "30px", fill: "#4D6646" }}
                  onClick={() => {
                    setFilteredDataUser(datauser);
                    // setSearch_dateSurvey( new Date() )
                  }}
                />
              </div>
            </div>
            <DataTable
              columns={columnsUser}
              data={filteredDataUser}
              pagination
            />
          </TabPanel>
          <TabPanel value="4" style={{ padding: "0px" }}>
            <h1 style={{ color: "white" }}>Surveys</h1>
            <div style={{ marginBottom: "10px" }}>
              <div style={{ width: "20%", display: "inline-block" }}>
                {/* <DateRange
                            editableDateInputs={true}
                            onChange={item => setState( [item.selection] )}
                            moveRangeOnFirstSelection={false}
                            
                            ranges={state}
                        /> */}
                <ReactDatePicker
                  selected={search_dateSurvey}
                  onChange={(date) => {
                    console.log(
                      "supplier,",
                      survey.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    for (let i in printQuestion) {
                      printQuestion[i].answers = [];
                    }
                    survey
                      .filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                      .map((rec) => {
                        Array.isArray(rec.questions) &&
                          rec.questions.map((recOne) => {
                            for (let i in printQuestion) {
                              console.log(
                                "printQuestion   4",
                                printQuestion[i].question == recOne.question
                              );
                              if (
                                printQuestion[i].question == recOne.question
                              ) {
                                if (!recOne.ans == "") {
                                  printQuestion[i].answers.push(recOne.ans);
                                }
                              }
                            }
                            return rec;
                          });
                        return rec;
                      });

                    const childrenOne1 = [];
                    let loc = survey.filter(
                      (rec) =>
                        moment(new Date(rec.created_datetime)).format(
                          "DD/MM/yyyy"
                        ) == moment(new Date(date)).format("DD/MM/yyyy")
                    );
                    for (let i = 0; i < loc.length; i++) {
                      console.log("loc[i].projectCode", loc[i].projectCode);
                      if (
                        !childrenOne1.find((rec) => rec == loc[i].projectCode)
                      ) {
                        if (loc[i].projectCode != undefined) {
                          childrenOne1.push(loc[i].projectCode);
                          // children.push( <Option key={datauser[i].eventTitle}>{datauser[i].eventTitle}</Option> );
                        }
                      }
                    }
                    console.log("loc[i].projectCode 1", childrenOne1);
                    locations = [];
                    setLocations([...childrenOne1]);
                    setPrintQuestion([...printQuestion]);
                    setFilteredDataSurvey(
                      survey.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setTempSurvey(
                      survey.filter(
                        (rec) =>
                          moment(new Date(rec.created_datetime)).format(
                            "DD/MM/yyyy"
                          ) == moment(new Date(date)).format("DD/MM/yyyy")
                      )
                    );
                    setSearch_dateSurvey(date);
                  }}
                  dateFormat="Pp"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div
                style={{
                  width: "20%",
                  display: "inline-block",
                  marginLeft: "10px",
                }}
              >
                <select
                  classname="form-control"
                  value={location}
                  style={{
                    display: "block",
                    width: "100%",
                    backgroundColor: "#E6E6E6",
                    height: "50px",
                    borderRadius: "5px",
                    color: "black",
                    fontFamily: "open sans",
                    fontSize: "14px",
                    borderRadius: "32px",
                  }}
                  onChange={(e) => {
                    console.log("evet 2", e);
                    handleChangeSelectAWA(e);
                  }}
                >
                  <option value="Select Location">Select Location</option>
                  {locations.length
                    ? locations.map((rec) => {
                        return <option value={rec}>{rec}</option>;
                      })
                    : null}
                </select>
              </div>
              <div style={{ width: "5%", display: "inline-block" }}>
                <HiRefresh
                  style={{ color: "white", fontSize: "30px", fill: "#4D6646" }}
                  onClick={() => {
                    setFilteredDataSurvey(survey);
                    setSearch_dateSurvey(new Date());
                    setTempSurvey(survey);
                    setLocation("Select Location");
                  }}
                />
              </div>
              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  float: "right",
                }}
              >
                <div ref={componentRef1} className="print-screen">
                  {Array.isArray(printQuestion)
                    ? printQuestion.map((rec) => {
                        return (
                          <div>
                            <p
                              style={{
                                fontSize: "20px",
                                fontFamily: "open sans",
                              }}
                            >
                              {rec.question}
                            </p>
                            <ul>
                              {rec.answers.map((recOne) => {
                                return <li>{recOne}</li>;
                              })}
                            </ul>
                          </div>
                        );
                      })
                    : null}
                </div>
                <ReactToPrint
                  trigger={() => (
                    <button
                      size={36}
                      style={{
                        float: "right",
                        cursor: "pointer",
                        color: "white",
                        borderRadius: "32px",
                        fontFamily: "open sans",
                        width: "165px",
                      }}
                    >
                      Export
                    </button>
                  )}
                  content={() => componentRef1.current}
                  pageStyle={{ background: "" }}
                />
              </div>
            </div>

            <DataTable
              columns={columnsSurvey}
              data={filteredDataSurvey}
              pagination
              onRowClicked={(e) => {
                setSelectedData(e.questions);
                setModalShow(true);
                console.log("e", e.questions);
              }}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </Container>
  );
};

export default Dashboard;
