import React from 'react'
import { Component } from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import Footer from '../footer/Footer';
import './Home.css'

class Fire extends Component {
    componentDidMount() {
        // window.scrollTo(0, 0)
        let Fire = document.getElementById( 'clicked' );
        Fire.click()
    }
    render() {
        return (
            <>
                <div className="root-fire">
                    <a href="#heroFire" id='clicked' style={{ display: 'none' }} ></a>
                    <section id="heroFire">
                        <div className="heroFire-container" >
                            <div className="exploreOne">
                                <p className="typoOne">fire</p>
                                <h4 className="explore-typo-fire">Narunge</h4>
                            </div>
                        </div>
                    </section>


                    <section id="about">
                        <div style={{ overflow: "hidden" }}>
                            <Row style={{ padding: "0px 10%", marginTop: "40px" }}>
                                <Col md="12" lg="12" >
                                    <div className="card-body">
                                        <h1 className="jt-init headWater" style={{ color: "#b3a160" }}>Cultural Significance of Fire</h1>

                                    </div>

                                </Col>

                            </Row>
                            <Row className=" exploreOne" style={{ padding: "0px 7%" }}>
                                <Col sm="12" md="6" xs="12" className="">
                                    <Carousel variant="dark" className="first-img" controls={false}>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={"https://cdn.dev.kaiejin.org.au/static-content/fire/fire.JPG"}
                                                alt="First slide"
                                            />

                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                                <Col sm="12" md="6" xs="12">
                                    <div className="card-body marginTpRm">

                                        <p>
                                            For over 65,000 years, Kulinge (Fire) has been a significant symbol for First Nations peoples. Traditionally fire has been used as a practical device for cooking, warmth, hunting and regulating the environment. Fire is also used in a very spiritual way, with many stories, dance and memories being passed down through generations around the fire.

                                        </p>

                                        <p>
                                            First Nations people use fire to protect Aboriginal land, scared places, and the memories and traditions that are tied to those places. By applying traditional fire management, First Nations peoples guide a cool and quick burn across the target landscape. This is more commonly known as cultural burns. Cultural burning uses smaller, low-intensity fires which reduces the risk of extreme, high flames that can burn whole trees, and larger bush areas.
                                        </p>
                                        <p>
                                            Cultural Burns utilize cool fires – smaller, low intensity fires - during optimal times of the season and in the right places to improve the health of country. This is achieved by a multitude of different improvements, including encouraging the regeneration of particular plants that thrive in the conditions created by cultural burning, as well as minimising the risk of large-scale wildfires in drier times.
                                        </p>
                                    </div>

                                </Col>

                            </Row>



                        </div>
                    </section >
                </div >
                <Footer />

            </>
        )
    }
}
export default Fire;