import {
  Button,
  Col,
  Container,
  Form,
  Row,
  TabContainer,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Avatar, Collapse, Comment } from "antd";
import { CaretRightOutline } from "@ant-design/icons";
import { propStyle } from "aws-amplify-react";
import "./forum.css";
import { io } from "socket.io-client";
import Messages from "./Messages";
import { useLocation } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Axios from "axios";
import Footer from "../footer/Footer";
const { Panel } = Collapse;
const ExampleComment = ({ children, data }) => (
  <React.Fragment>
    <Comment
      content={
        <div className="div-b">
          <p style={{ color: "black" }}>{data.reply}</p>
          <a style={{ float: "right" }}>Posted By: {data.name}</a>
        </div>
      }

      // author={<a style={{ color: "white" }}>{data.name}</a>}
    >
      {children}
    </Comment>
  </React.Fragment>
);

// let questions = [{
//     question: "Question one",
//     replies: [{
//         name: "Sanaullah",
//         reply: "reply one"
//     },
//     {
//         name: "Preet",
//         reply: "reply two"
//     }
//     ]
// },
// {
//     question: "Question Two",
//     replies: [{
//         name: "Maria",
//         reply: "reply one"
//     },
//     {
//         name: "Reeanjou",
//         reply: "reply two"
//     }
//     ]
// }
// ]

const Forum = () => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [socket, setSocket] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [name, setName] = useState("");
  const [nameR, setNameR] = useState("");

  const [reply, setReply] = useState("");
  const location = useLocation();
  useEffect(() => {
    getQuestions();
  }, []);

  const submitReply = (ques) => {
    const { id, title } = location.state;
    let data = {
      eventId: id,
      question: base64_encode(ques),
      name: nameR,
      timestamp: new Date(),
      reply,
      // userId: localStorage.getItem("email"),
    };
    if (nameR == "") {
      alert("Please fill name");
    } else {
      Axios.put(`${process.env.REACT_APP_API_BASE}/forum-data`, data)
        .then((rec) => {
          console.log("data", rec);
          if (rec.status == 200) {
            getQuestions();
            alert("Reply is succefully added!");
            setQuestion("");
            setReply("");
            setName("");
            setNameR("");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const submitQuestion = () => {
    const { id, title } = location.state;
    let data = {
      eventId: id,
      question: base64_encode(question),
      name,
      timestamp: new Date(),

      userId: localStorage.getItem("email"),
    };
    if (name == "" || !name) {
      alert("Please fill name");
    } else {
      Axios.post(`${process.env.REACT_APP_API_BASE}/forum-data`, data)
        .then((rec) => {
          console.log("data", rec);
          if (rec.status == 200) {
            getQuestions();
            alert("Question is successfully added.");
            setQuestion("");
            setName("");
            setReply("");
            setNameR("");
          }
        })
        .catch((e) => {
          alert(e);
          console.log(e);
        });
    }
  };
  const getQuestions = () => {
    const { id, title } = location.state;
    Axios.get(`${process.env.REACT_APP_API_BASE}/forum-data?eventId=${id}`)
      .then((rec) => {
        console.log("data===>", rec);
        if (rec.data.body.length) {
          setQuestions(rec.data.body);
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  // useEffect( () => {
  //     const newSocket = io( `wss://1sr1zozot8.execute-api.ap-southeast-2.amazonaws.com/dev?username=client_name` );
  //     setSocket( newSocket );
  //     console.log( "Socket", newSocket )
  //     return () => newSocket.close();
  // }, [setSocket] );
  return (
    <>
      <Container>
        <h2 style={{ color: "white", marginTop: "30px" }}>
          {location.state.name}
        </h2>
        <Row>
          <Col lg={8} md={8} sm={12}>
            <div
              style={{
                width: "100%",
                display: "inline-block",
              }}
            >
              <Collapse
                bordered={false}
                defaultActiveKey={[""]}
                // expandIcon={( { isActive } ) => <CaretRightOutline rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
                onChange={() => {
                  setReply("");
                  setNameR("");
                }}
              >
                {questions &&
                  questions.map((rec, index) => {
                    return (
                      <Panel
                        header={`${rec.question} - ${rec.name}`}
                        key={index}
                        className="site-collapse-custom-panel"
                      >
                        {rec.replies
                          ? rec.replies.map((rec1) => (
                              <>
                                <ExampleComment data={rec1} />
                              </>
                            ))
                          : null}
                        <div
                          style={{
                            // width: "30%",
                            // background: "grey",
                            display: "inline-block",
                            // float: "right",
                            // marginLeft: "30px",
                            padding: "0px 0px 0px 10px",
                            borderRadius: "10px",
                            // marginTop: "30px",
                            width: "100%",
                          }}
                        >
                          <Form.Group style={{ marginTop: "8px" }}>
                            {/* <Form.Label className="label-clr" style={{ fontSize: '20px', letterSpacing: '2px' }}>Reply</Form.Label> */}
                            <Form.Control
                              type="textarea"
                              className="form-control"
                              style={{ height: "100px" }}
                              placeholder="Add your new comment/answer here..."
                              value={reply}
                              onChange={(e) => {
                                setReply(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Row>
                            <Col md="6">
                              <Form.Group style={{ marginTop: "8px" }}>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="Name (required)"
                                  value={nameR}
                                  onChange={(e) => {
                                    setNameR(e.target.value);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="2"></Col>
                            <Col md="4">
                              <Form.Group
                                style={{
                                  marginTop: "8px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  style={{
                                    fontFamily: "creamy coconut",
                                    float: "right",
                                    border: "4px dashed #4D6646",
                                    width: "135px",
                                    background: "#707070",
                                    borderRadius: "32px",
                                  }}
                                  onClick={() => {
                                    submitReply(rec.question);
                                  }}
                                >
                                  Reply
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div
              style={{
                // width: "30%",
                background: "grey",
                display: "inline-block",
                // float: "right",
                // marginLeft: "30px",
                padding: "10px",
                borderRadius: "10px",
                marginTop: "30px",
                width: "100%",
              }}
              className="reply-div"
            >
              <h1 style={{ color: "white" }}>
                Have a question about the events? Ask out friendly community.
              </h1>
              <Form.Group style={{ marginTop: "8px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Name<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group style={{ marginTop: "8px" }}>
                <Form.Label
                  className="label-clr"
                  style={{ fontSize: "20px", letterSpacing: "2px" }}
                >
                  Post
                </Form.Label>
                <Form.Control
                  type="textarea"
                  className="form-control"
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group
                style={{
                  marginTop: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    fontFamily: "creamy coconut",
                    float: "right",
                    border: "4px dashed #4D6646",
                    width: "135px",
                    background: "#707070",
                    borderRadius: "32px",
                    float: "right",
                  }}
                  onClick={() => {
                    submitQuestion();
                  }}
                >
                  {" "}
                  Post
                </Button>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Forum;
