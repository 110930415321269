import React from 'react'
import { Component } from 'react';
import { Carousel as Caro, Col, Row } from 'react-bootstrap';
import './Home.css'
import AliceCaro from 'react-alice-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ImageCarousel } from 'react-responsive-carousel';
import Footer from '../footer/Footer';
const imagesOne = [
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/one.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/one.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/two.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/two.jpg",
    },
    {
        original:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/three.jpg" ,
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/three.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/four.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/four.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/five.jpg",
        thumbnail:  "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/five.jpg",
    },
    {
        original:  "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/six.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/six.jpg",
    },
    {
        original:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/seven.jpg" ,
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/seven.jpg" ,
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eight.jpg" ,
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eight.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/nine.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/nine.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/ten.jpg",
        thumbnail:  "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/ten.jpg",
    },
    {
        original:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eleven.jpg",
        thumbnail:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eleven.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twelve.jpg",
        thumbnail:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twelve.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirteen.jpg",
        thumbnail:  "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirteen.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/fourteen.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/fourteen.jpg",
    },
    {
        original:  "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/fifteen.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/fifteen.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/sixteen.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/sixteen.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/seventeen.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/seventeen.jpg",
    },
    {
        original:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eighteen.jpg", 
        thumbnail:"https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/eighteen.jpg", 
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/nineteen.jpg", 
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/nineteen.jpg", 
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twenty.jpg", 
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twenty.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyone.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyone.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentytwo.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentytwo.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentythree.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentythree.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyfour.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyfour.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyfive.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyfive.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentysix.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentysix.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyseven.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyseven.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyeight.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentyeight.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentynine.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/twentynine.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirty.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirty.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtyone.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirty.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtytwo.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtytwo.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtythree.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtythree.jpg",
    },
    {
        original: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtyfour.jpg",
        thumbnail: "https://cdn.dev.kaiejin.org.au/static-content/community/ancesstory/thirtyfour.jpg",
    },
];

class Community extends Component {
    state = {
        confirmAnc: false
    }
    componentDidMount = () => {
        window.scrollTo( 0, 0 )
    }
    handleClick = () => {
        this.setState( {
            confirmAnc: true
        } )
    }
    render() {
        let role = localStorage.getItem( 'userRole' )
        const { confirmAnc } = this.state
        return (
            <div className="root-comm">
                <section id="heroCommunity">
                    <div className="heroCommunity-container" >
                        <div className="exploreOne">
                            <p className="typoOne">Community</p>
                            <h4 className="explore-typo-com" >Kulingi</h4>
                        </div>
                    </div>
                </section>
                <section id="about">
                    <div style={{ overflow: "hidden" }}>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow" style={{ color: "#yellow", fontWeight: "bold" }}>Statement of sovereignty</h1>
                                </div>
                            </Col>
                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%", marginTop: "10px" }}>
                            {/* <div className=" start "> */}
                            <div className="card-body-com-latest" style={{ marginTop: "5px", marginBottom: "20px" }}>
                                <h2 className="green-head">Tati Tati kulingurra thangurra kaiejin parram parram tyalingi wangu warripa tharrayamu thangi nga thiti thalekatha kulingi kungatha delki thangi.</h2>
                                <p className="" style={{ opacity: ".99" }}>
                                    We, the Tati Tati First Peoples’, are the sovereign nation that carry responsibilities of caring for Country in Northwest Victoria Murray River region. It is our responsibility as caretakers of waterways, landscapes, wildlife, ancestral sites, totemic species, and cultural heritage, as well as language, lore, song, dance, ceremony, and customs; our inherent right and connection to place, land, and animals date back over 2000 generations.
                                </p>
                                <p className="" style={{ opacity: ".99" }}>
                                    We, the Tati Tati First Peoples’, state we have never ceded sovereignty, nor ever relinquished, given, or traded our inherent right to Country and culture to any foreign individuals, parties, or governments. We do not consent to any foreign individuals, parties, or governments to act on our behalf in managing Country. Our involvement in any capacity does not, and should not, be misinterpreted as consent for decision-making or management on our behalf.
                                </p>
                                <p className="" style={{ opacity: ".99" }}>

                                    We are, and will forever be, the rightful people to care for our Country.
                                </p>


                            </div>
                        </Row>

                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%" }}>
                            <Caro variant="dark" controls={false}>
                                <Caro.Item >
                                    <img
                                        className="d-block w-100"
                                        src={"https://cdn.dev.kaiejin.org.au/static-content/community/sectionOne.png"}
                                        alt="First slide"
                                    />

                                </Caro.Item>



                            </Caro>
                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow">Tati Tati People</h1>
                                    <p className="heading-below">Tati Tati people are Millu Kulingi – Murray River Tribe.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 7%" }}>
                            <Col md="8" lg="8" >
                                <div className=" start card-body-com-latest">
                                    <p style={{ opacity: ".99" }}>
                                        Our country extends both sides of the Millu – Murray River, with Booniaricool on the NSW side of the river and Bumbang on the Victorian side – now known as Euston and Robinvale respectively.
                                    </p>
                                    <p style={{ opacity: ".99" }}>
                                        Our tribal neighbours are Latji Latji to the West, Mutti Mutti to the Northeast, Wadi Wadi to the Southeast, & Wergaia to the South.
                                    </p>
                                </div>
                            </Col>
                            <Col md="4" sm="12" lg="4">
                                <Caro variant="dark" controls={false}>
                                    <Caro.Item>
                                        <img
                                            className="d-block w-100"
                                            src={"https://cdn.dev.kaiejin.org.au/static-content/community/sectionKinShip.JPG"}
                                            alt="First slide"
                                        />

                                    </Caro.Item>



                                </Caro>
                            </Col>

                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow">Kinship</h1>
                                    <p style={{}} className="heading-below">Mukwarra & Kilparra.</p>
                                </div>
                            </Col>
                        </Row>
                        {/* Kinship */}
                        <Row className=" exploreOne" style={{ padding: "0px 7%" }}>
                            <Col sm="12" md="6" xs="12" >
                                <Caro variant="dark" >
                                    <Caro.Item>
                                        <img
                                            className="d-block w-100"
                                            src={" https://cdn.dev.kaiejin.org.au/static-content/community/sectionKinshipTwo.JPG"}
                                           
                                            alt="First slide"
                                        />

                                    </Caro.Item>



                                </Caro>
                            </Col>
                            <Col sm="12" md="6" xs="12">
                                <div className="card-body-com-latest" style={{ marginTop: "5px" }}>
                                    <AliceCaro >
                                        <div>
                                            <p>
                                                Tati Tati are a matrilineal ( following the female line ) nation that traditionally followed a moiety system separating all things - people, animals, places, the environment - into two halves.

                                            </p>

                                            <p>
                                                Our Tati Tati moiety and ancestral totems are known as either Mukwarra or Kilparra and decide who you can marry and what moiety children are born into. If a Mukwarra female marries a Kilparra male, their children are Mukwarra. If a Kilparra female marries a Mukwarra male, their children are Kilparra. This is because members of the same moiety are considered siblings, cousins, and family, and have responsibilities to care for each other.
                                            </p>
                                        </div>
                                        <p>
                                            This responsibility also extends to animals, plants, rivers and land.Tati Tati peoples are not allowed to kill or eat animals that are of the same moiety or ancestral totem.They have a responsibility to ensure the health and survival of that moiety animal, plant, or place.
                                        </p>

                                    </AliceCaro>
                                </div>

                            </Col>

                        </Row>
                        {/* wel */}
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow">Wellbeing </h1>

                                </div>
                            </Col>
                        </Row>
                        {/* Kinship */}
                        <Row className=" exploreOne" style={{ padding: "0px 7%" }}>

                            <Col sm="12" md="6" xs="12">
                                <div className="card-body-com-latest" style={{ marginTop: "5px" }}>
                                    <AliceCaro >

                                        <p>
                                            The wellbeing of First Nations Peoples is more than just the absence of illness. It is a holistic way of being that combines the spiritual, cultural, social, emotional, and physical wellbeing of both the individual and the Nation group.<br />For Tati Tati, water is essential to wellbeing and cultural, social and spiritual identities.
                                        </p>

                                        <p>
                                            Water supports livelihoods, the wellbeing of plants, animals and people, and the transfer of traditional knowledges.Our wellbeing centres on values of caring for Country and continuing legacies of our ancestors past through our connection to place, people, animals, and plants.
                                        </p>



                                    </AliceCaro>
                                </div>

                            </Col>
                            <Col sm="12" md="6" xs="12">
                                <Caro variant="dark" >
                                    <Caro.Item>
                                        <img
                                            className="d-block w-100"
                                            src={"https://cdn.dev.kaiejin.org.au/static-content/community/sectionWellbeing.JPG"}
                                            
                                            alt="First slide"
                                        />

                                    </Caro.Item>
                                    <Caro.Item>
                                        <img
                                            className="d-block w-100"
                                            src={"https://cdn.dev.kaiejin.org.au/static-content/community/sectionWellbeingTwo.JPG"}
                                            alt="First slide"
                                        />

                                    </Caro.Item>
                                    <Caro.Item>
                                        <img
                                            className="d-block w-100"
                                            src={"https://cdn.dev.kaiejin.org.au/static-content/community/sectionWellbeingThree.JPG"}

                                            alt="First slide"
                                        />

                                    </Caro.Item>
                                </Caro>
                            </Col>

                        </Row>
                        {/*  */}
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow">Research Project: </h1>
                                    <p style={{ fontWeight: "bold" }} className="heading-below">Kulingi Kaiejin Ruwe </p>
                                </div>
                            </Col>
                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%", position: "relative", zIndex: "99999" }} >
                            <div className="" style={{ marginTop: "5px", textAlign: "start" }}>
                                {/* <AliceCaro > */}

                                <p>
                                    In partnership with researchers at the University of Melbourne, Tati Tati researchers and nation members are co-designing a research project to capture the relationship between wellbeing of Tati Tati waters and land, and the wellbeing of Tati Tati Traditional Owners.We are interested to demonstrate how this connection has changed over time, and what actions are needed to support strong and positive outcomes in the future.
                                    <br />
                                    Over several years, we will use research activities such as yarning groups, Aboriginal Waterways Assessments, surveys and mapping to:



                                </p>
                                <div>
                                    <ol>
                                        
                                        <li>
                                            describe the nature and significance of Tati Tati connection to waterways and Country, and how this varies over time and place;
                                        </li>
                                        <li>
                                            measure and evaluate how access to water and health of waterways and Country influences the wellbeing of Tati Tati people, across the life course; and
                                        </li>
                                        <li>
                                            design pathways for enhanced wellbeing through access to water and caring for Country. These pathways may include building capacity to advocate for policy reform and changes in practice, to increase water control, entitlements and tenure, and land and resource management.
                                        </li>
                                    </ol>
                                </div>
                                <p>
                                    This research is supported by the Australian Government through the Australian Research Council's Centre of Excellence for Children and Families over the Life Course (the Life Course Centre). Our partnership will be with the Melbourne Sustainable Society Institute at the University of Melbourne, which leads the Life Course Centre’s Places Program. More information on the Places Program can be found here:
                                    <a href="https://www.lifecoursecentre.org.au/research/places-program/" target="blank" > https://www.lifecoursecentre.org.au/research/places-program/ </a>

                                </p>


                            </div>
                        </Row>
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow"> Tati Tati lead researchers: </h1>

                                </div>
                            </Col>
                        </Row>

                        <Row style={{ padding: "0px 10%"}}>

                            <div style={{ opacity: "0.99", color: "white" }}>
                                <p>Melissa Kennedy
                                    <br />
                                    Research Fellow - Participatory Engagement </p>
                                <p>Brendan Kennedy
                                    <br />
                                    Enterprise Principal Fellow - Cultural Economies and Sustainability
                                </p>




                                <p>
                                    <p> For more information contact: </p>
                                    <p>Melissa Kennedy
                                        <br />
                                        mel.kennedy @unimelb.edu.au </p>
                                    <p>

                                        Or </p>
                                    <p>
                                        Dr.Sangeetha Chandra-Shekeran <br />
                                        sangeetha.chandra @unimelb.edu.au
                                    </p>
                                </p>



                                {/* </AliceCaro> */}
                            </div>
                        </Row>
                        {/* Kinship */}
                        <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow"> University of Melbourne researchers: </h1>

                                </div>
                            </Col>
                        </Row>
                        <Row className=" exploreOne" style={{ padding: "0px 7%" }}>
                            <Col sm="12" md="4" xs="12" lg="4" style={{ marginBottom: '20px' }}>
                                <Caro variant="dark" interval={null} controls={false}>
                                    <Caro.Item>

                                        <div className="card-body-com-latest" style={{ marginTop: "5px", }}>
                                            <h2 style={{ color: "white" }}>Dr Sangeetha Chandra Shekeran</h2>
                                            <p>Senior Research Fellow</p>
                                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                                <img src={"https://cdn.dev.kaiejin.org.au/static-content/community/sangeeta.png"} style={{ borderRadius: "50%", width: "139px" }} />
                                            </div>
                                            <p style={{ textAlign: "start", padding: "10px", margin: "10px" }}>
                                                Sangeetha is Senior Research Fellow and co-Lead
                                                of the Places Program in the Centre of Excellence
                                                for Children and Families over the Life Course.
                                                Sangeetha is an economic geographer with
                                                expertise in socio-environmental change,
                                                including projects for remote Indigenous
                                                communities to access benefits from large-scale
                                                investment, showing how the law shapes the
                                                availability of opportunities.
                                            </p>
                                        </div>
                                    </Caro.Item>
                                 



                                </Caro>
                            </Col>
                            <Col sm="12" md="4" xs="12" lg="4" style={{ marginBottom: '20px' }}>
                                <Caro variant="dark" interval={null} controls={false}>
                                   
                                    <Caro.Item>
                                        {/* <img
                                            className="d-block w-100"
                                            src={julia}
                                            alt="First slide"
                                        /> */}
                                        <div className="card-body-com-latest" style={{ marginTop: "5px" }}>
                                            <h2 style={{ color: "white" }}> Dr Julia de Bruyn</h2>
                                            <p>Research Fellow</p>
                                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", textAlign: "start" }}>
                                                <img src={"https://cdn.dev.kaiejin.org.au/static-content/community/julia.png"} style={{ borderRadius: "50%", width: "139px" }} />
                                            </div>
                                            <p style={{ textAlign: "start" }}>
                                                Julia has seven years of experience conducting
                                                participatory research with rural communities
                                                in smallholder farming households.Julia is a vet
                                                whose research has shifted to focus on
                                                connections between livestock-keeping, food
                                                security and children's nutrition. She has
                                                worked with Indigenous communities in
                                                Arnhem Land, as well as with culturally and
                                                linguistically diverse groups in Southern and
                                                Eastern Africa and Indigenous communities in
                                                Peru.
                                            </p>
                                        </div>

                                    </Caro.Item>




                                </Caro>
                            </Col>
                            <Col sm="12" md="8" xs="12" lg="8">


                            </Col>

                        </Row>

                        {role === "admin" || role === "registered_user" ? <Row id="portfolio" className="portfolio explore" style={{ padding: "0px 10%" }}>
                            <Col md="12" lg="12" >
                                <div className="card-body start">
                                    <h1 className="jt-init  headComPage" id="cflow">Ancestry</h1>
                                </div>
                            </Col>
                        </Row> : null}
                        {( (role === "admin" || role ==="registered_user") && !confirmAnc ) ? <div className="d-flex justify-content-center"><div style={{
                            width: "30%",
                            background: "rgb(60,60,60)",
                            position: "relative",
                            zIndex: "99999",
                            padding: "20px",
                            borderRadius: "10px",
                            marginBottom:"20px"

                        }}>
                            <p style={{ color: "red" }}>Aboriginal and/or Torres Strait Islander peoples should be aware that this page may contain images of people who have passed away.</p>
                            <p style={{ color: "white", textAlign: "center" }}>Click OK to proceed</p>
                            <div className="d-flex justify-content-center">
                                <button style={{fontFamily:"open sans"}}onClick={() => this.handleClick()}>Ok</button>
                            </div>
                        </div> </div> : null}
                        {( (role === "admin" || role ==="registered_user") && confirmAnc ) ? <ImageCarousel showStatus={false}>
                            {imagesOne.map( rec => {
                                return ( <div>
                                    <img src={rec.original} />
                                    {/* <p className="legend">Legend 1</p> */}
                                </div> )
                            } )}
                        </ImageCarousel> : null}

                    </div>
                </section >
          <Footer />

            </div >
        )
    }
}
export default Community;