
import React, { useEffect, useState } from 'react'
import { Col, Collapse, Container, Form, Row } from 'react-bootstrap'
import { AiOutlineUpload } from 'react-icons/ai'
import Evt from '../../asset/evt.PNG'
import Up from '../../asset/up.PNG'
import Footer from '../footer/Footer'


import './help.css'

const Help = () => {
    const [open, setOpen] = useState( false );
    const [openEvent, setOpenEvent] = useState( false );

    useEffect( () => {
        setTimeout(() => {
                
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
        }, 40);
    
    window.scrollTo( 0, 0 )
    },[])
    return (
        <>
        <Container style={{background: "rgb(50, 50, 50)",marginTop:"20px" ,minHeight:"100vh"}}>
            <div className='q_a'>
                <div className='head_title'>
                    <div style={{

                        color: "white",
                        background: "#515050 0% 0% no-repeat padding-box",
                        padding: "30px 30px 2px",
                        borderRadius: "20px",
                        minHeight: "115px"
                    }}

                        onClick={() => setOpen( !open )}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        <h2 style={{
                            background: "#E6E6E6",
                            fontFamily: "creamy coconut",
                            height: "55px",
                            borderRadius: "32px",
                            padding: "10px 30px 2px",
                        }}>
                            How to be a Registered User?
                        </h2>
                        <Collapse in={open} >
                            <div id="example-collapse-text" style={{
                                color: "white",
                                paddingLeft: "30px",
                                fontFamily: "open sans",
                                background: "#515050"
                            }}>
                                <Row className='top-mrg'> 
                                    <Col md="6">
                                        <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px", paddingTop: "15%" }}>
                                            <h1 style={{ color: "white" }}>Getting Started</h1>
                                            {/* Step 1: Go to Kaiejin.org.au > Click Login/Register */}
                                            Click on the "Login/Register" button at the top right of the page.
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/help/home1.png'} width="97%"  style={{paddingBottom:"20px"}}/>
                                    </Col>
                                </Row>
                                {/* <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px" }}> Step 2: Click the Register link </div> */}
                                <Row className='top-mrg'>
                                    <Col md="6">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/help/login1.png'} width="97%" style={{paddingBottom:"20px"}}/>

                                    </Col>
                                    <Col md="6">
                                        <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px", paddingTop: "15%" }}>
                                            <h1 style={{ color: "white" }}>Registering</h1>
                                            Click on the "Register" button toward the center of the page
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='top-mrg'>
                                    <Col md="6" >
                                        <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px", }}>
                                            <h1 style={{ color: "white" }}>Signing Up</h1>
                                            <p >
                                                Simply fill in your details and then click the "Register" button before the form </p>
                                            <p >After you click the "Register" button, it may take up to one business day for an
                                                admin to confirm the user sign up. </p><p >
                                                Once the account has been confirmed, the user will receive an email with a username and a temporary password.
                                            </p><p >Simply login with the username and temporary password and you will be promoted to change you password. </p><p >
                                                Congratulations, now you can login with you username and new password.</p>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/help/signup2.png'} width="97%" style={{paddingBottom:"20px"}} />

                                    </Col>
                                </Row>
                                {/* <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px" }}> Step 3: Fill up the details and click Register </div>

                                <p style={{ marginTop: "10px" }}>
                                    Please Note:
                                </p>
                                <ul style={{ color: "white", fontFamily: "open sans", fontSize: "16px", marginTop: "10px", marginBottom: "10px" }}>
                                    <li>User cannot use the same email more than once.</li>
                                    <li>After registration, Admin will receive an email notification and will need to confirm user sign up.</li>
                                    <li>After the admin has accepted and confirmed the user sign up, the user will receive an email confirmation with the verification that includes username and temporary password.</li>

                                </ul>
                                <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px" }}> Step 4: User needs to login and change their temporary password. </div> */}


                            </div>
                        </Collapse>
                    </div></div>

                <div>

                </div>
            </div>
            <div className='q_a'>
                <div className='head_title'>
                    <div
                        style={{

                            color: "white",
                            background: "#515050 0% 0% no-repeat padding-box",
                            padding: "30px 30px 2px",
                            borderRadius: "20px",
                            minHeight: "115px"
                        }}

                        onClick={() => setOpenEvent( !openEvent )}
                        aria-controls="example-collapse-text"
                        aria-expanded={openEvent}
                    >
                        <h2
                            style={{
                                background: "#E6E6E6",
                                fontFamily: "creamy coconut",
                                height: "55px",
                                borderRadius: "32px",
                                padding: "10px 30px 2px",
                            }}

                        >
                            How to Register for an Event?

                        </h2>
                        <Collapse in={openEvent} >
                            <div id="example-collapse-text" style={{ color: "white", paddingLeft: "30px", background: "#333", fontFamily: "open sans", fontSize: "16px" }}>
                             
                                    <Row>  <Col md="6" className='top-mrg'>
                                        <h1 style={{ color: "white" }}>
                                            Public Events
                                        </h1>
                                        <p>Please note: Unregistered users can see the upcoming public events without logging in.</p>
                                        <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px 0px" }}> Step 1: User should click the Register button on the particular event they want to participate. </div>
                                    </Col>
                                        <Col md="6" className='top-mrg'>
                                            <img src={Up} width="97%" style={{paddingBottom:"20px"}}/>
                                        </Col>
                                        <Col md="6" className='top-mrg'>
                                            <img src={Evt} width="97%" style={{paddingBottom:"20px"}} />
                                        </Col>
                                        <Col md="6" className='top-mrg'>
                                            <div style={{ fontFamily: "open sans", fontSize: "16px"}} className="step-2"> Step 2: User must fill up all the mandatory fields </div>
                                            <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px 0px" }}> Step 3: Click Submit.</div>
                                        </Col>

                                        <Col md="6" className='top-mrg'>
                                            <h1 style={{ color: "white" }}>   Private Events </h1>
                                            <p>Please Note: User must need to Login to Tati Tati Kaiejin portal to see private events.</p>
                                            <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px -0px" }}> Step 1: User should click the Register button on the particular event they want to participate. </div>
                                        </Col>
                                        <Col md="6" className='top-mrg'>
                                            <img src={Up} width="97%" style={{paddingBottom:"20px"}}/>
                                        </Col>
                                        <Col md="6" className='top-mrg'>
                                            <img src={Evt} width="97%" style={{paddingBottom:"20px"}} />
                                        </Col>
                                        <Col md="6" className='top-mrg'>
                                            <div style={{ fontFamily: "open sans", fontSize: "16px" }} className="step-2"> Step 2: User must fill up all the mandatory fields</div>
                                            <div style={{ fontFamily: "open sans", fontSize: "16px", margin: "10px 0px" }}> Step 3: click submit.</div>
                                        </Col>

                                    </Row>

                            



                            </div>
                        </Collapse>
                    </div>
                </div>

                <div>

                </div>
            </div>

        </Container>
        <Footer/>
        </>
    )
}

export default Help