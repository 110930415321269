import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Icon, Button } from 'antd';
import { connect } from 'react-redux'
import UserMiddleware from '../store/Middleware/UserMiddleWare';
class NormalLoginForm extends Component {
	constructor( props ) {
		super( props );

		this.state = {
			email: '',
			password: ''
		};
	}


	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields( ( err, values ) => {
			if ( !err ) {
				this.props.loginUser( values );
				this.setState({email:''})
			}
		} );
	};
	redirectToHome = () => {
		let token = localStorage.getItem( 'token' )

		if ( token ) {
			return <Redirect to="/home" />
		}
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="container">


				<div className="login">
					<Form onSubmit={this.handleSubmit} className="login-form">
						<div className="logo">
							{/* <img src={require( "./../images/logo.svg" )} width="120px" height="40px" /> */}
						</div>
						<Form.Item>
							{getFieldDecorator( 'email', {
								rules: [
									{
										type: 'email',
										message: 'The input is not valid E-mail!',
									},
									{
										required: true,
										message: 'Please input your E-mail!',
									},
								],
							} )( <Input placeholder="email" /> )}
						</Form.Item>
						<br/>
						<Form.Item>
							{getFieldDecorator( 'password', {
								rules: [{ required: true, message: 'Please input your Password!' }],
							} )(
								<Input
									type="password"
									placeholder="Password"
								/>,
							)}
						</Form.Item>
						<Form.Item>

							<Button type="primary" htmlType="submit" className="login-form-button">
								Log in
          				</Button>

						</Form.Item>
					</Form>

					<Link to="/register">Create an account</Link>
					{this.redirectToHome()}
				</div>
			</div>
		);
	}
}
const Login = Form.create( { name: 'normal_login' } )( NormalLoginForm );
const mapStateToPeops = ( state ) => {
	return {
		isLoading: state.userReducer.isLoading,
		errorMessage: state.userReducer.emailErrorMessage,
		userId: state.userReducer.user == null ? undefined : state.userReducer.user._id

	}
}
const mapDispatchToProps = ( dispatch ) => {
	return {
		loginUser: ( data ) => {
			return dispatch( UserMiddleware.loginUser( data ) )
		}

	}
}
export default connect( mapStateToPeops, mapDispatchToProps )( Login );
