import * as actions from "../actions/userActions";
import Axios from "axios";
import { baseurl } from "./index";

class UserMiddleware {
    static registerUser = ( data ) => {
        return dispatch => {
            dispatch( actions.registerUser( data ) )
            Axios.post( `${ baseurl }/users/register`, data ).then( res => {
                console.log( "response ini===>", res );
                if ( !res.data.success ) {
                    return dispatch( actions.registerUserFailed( {
                        success: res.data.success, error: res.data.error
                    } ) )
                }
                return dispatch( actions.registerUserSuccessfull( res ) );
            }
            ).
                catch( err => dispatch( actions.registerUserFailed( err ) ) )

        }
    }
    static loginUser = ( data ) => {
        return dispatch => {
            dispatch( actions.loginUser( data ) )
            Axios.post( `${ baseurl }/users/login`, data ).then( res => {
                localStorage.setItem( "token", res.data.token )
                dispatch( actions.loginUserSuccessfull( res.data ) )
                return window.location.reload();
            } ).catch( err => dispatch( actions.loginUserFailed( err ) ) )

        }
    }
}
export default UserMiddleware;