import React from 'react'
const SimpleTable = ( props ) => {
    const { data, headings } = props
    return (
        <table>
            <thead>
                <tr>
                    {headings.map( rec => {
                        return (
                            <th>
                                {rec}
                            </th>
                        )
                    } )}
                </tr>
            </thead>
            <tbody>
                {data.map( rec => {
                    console.log("record",rec)
                    return <tr>
                        <td>{rec.desc}</td>
                        <td>
                            <p> <input type="radio" value="Poor" name={rec.desc} style={{ opacity: 1, position: "unset" }}
                                onClick={( e ) => props.handleChange( e, rec.desc,rec.id )}

                            /> {rec.valueOne}</p>

                        </td>
                        <td>
                            <div className="d-flex justify-content-center" >
                                <p> <input type="radio" value="Average" 
                                name={rec.desc} style={{ opacity: 1, position: "unset" }}
                                onClick={( e ) => props.handleChange( e, rec.desc,rec.id )}
                                /> {rec.valueTwo}</p>

                            </div>
                        </td>
                        <td>
                            <div className="d-flex justify-content-center" >
                                <p> <input 
                                type="radio" 
                                value="Good" 
                                name={rec.desc} 
                                style={{ opacity: 1, position: "unset" }} 
                                onClick={( e ) => props.handleChange( e, rec.desc,rec.id )}
                                /> {rec.valueThree}</p>

                            </div>
                        </td>

                    </tr>

                } )}</tbody >
        </table >
    )
}

export default SimpleTable