import React, { useEffect, useState } from 'react'
import { Form, TextArea,  Button } from 'react-bootstrap';
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {Input} from 'antd'
import Footer from '../footer/Footer';

const Contact = () => {
    const [data, setData] = useState( {
        name: "",
        phone: "",
        organization: "",
        website: "",
        email: "",
        message: ""
    } )
    useEffect( () => {
        setTimeout(() => {
                
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
        }, 40);
    
    window.scrollTo( 0, 0 )
    },[])
    const validateEmail = ( email ) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test( String( email ).toLowerCase() );
    }
    const handleSubmit = () => {
        console.log( "data", data )
        if ( validateEmail( data.email || data.phone.length!=0) ) {
            let phonenumber = ""
            if(data.phone.charAt(0)!=0){
                phonenumber ="+61"+data.phone
            }else{
                phonenumber = "+61"+data.phone.slice(1);
            }
            axios.post( `${ process.env.REACT_APP_API_BASE }/contact`, {...data,phone:phonenumber} ).then( rec => {
                alert( "Succesfully sent " )
                setData( {
                    name: "",
                    phone: "",
                    organization: "",
                    website: "",
                    email: "",
                    message: ""
                } )
            } ).catch( e => {
                console.log( e )
            } )
        } else {
            alert( "Please Enter correct data" )
        }

    }
    return ( <div className="page-section container contact-root" style={{ maxWidth: "100%", background: "rgb(37,36,37) !important" }}>
        <div className="align-center mrg-xl-bottom">
            <h2 className=" underlined inline-block" style={{ color: "white" }}>Contact Us</h2>
        </div>
        <Form className="container-xs pad-md">
            <Form.Group>
                <Form.Label className="label-clr">Name</Form.Label>
                <Form.Control
                    type="text"
                    className="form-control"
                    value={data.name}
                    onChange={
                        ( e ) => { setData( { ...data, name: e.target.value } ) }
                    }
                />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
                <Form.Label className="label-clr">Email</Form.Label>
                <Form.Control
                    type="email"
                    className="form-control"
                    value={data.email}
                    onChange={
                        ( e ) => { setData( { ...data, email: e.target.value } ) }
                    }
                />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
                <Form.Label className="label-clr">Phone Number</Form.Label>
                {/* <Form.Control
                    type="number"
                    className="form-control"
                    value={data.phone}
                    onChange={
                        ( e ) => { setData( { ...data, phone: e.target.value } ) }
                    }
                /> */}
                <Input
                    addonBefore="+61"
                    value={data.phone}
                    onChange={( e ) => {
                        if ( e.target.value.length > 10 ) {
                            alert( "You could not add more then 10 digits" )
                        } else {
                            setData( {
                                ...data,
                                phone: e.target.value
                            } )
                        }
                    }}
                />


            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
                <Form.Label className="label-clr">Organisation (optional)</Form.Label>
                <Form.Control
                    type="text"
                    className="form-control"
                    value={data.organization}
                    onChange={
                        ( e ) => { setData( { ...data, organization: e.target.value } ) }
                    }
                />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
                <Form.Label className="label-clr">Website Address (optional)</Form.Label>
                <Form.Control
                    type="text"
                    className="form-control"
                    value={data.website}
                    onChange={
                        ( e ) => { setData( { ...data, website: e.target.value } ) }
                    }
                />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
                <Form.Label className="label-clr">Message</Form.Label>
                <Form.Control
                    as="textarea"
                    className="form-control min-height-4xl"
                    rows="12"
                    maxLength={1500}
                    placeholder=""
                    value={data.message}
                    onChange={
                        ( e ) => { setData( { ...data, message: e.target.value } ) }
                    }

                />
            </Form.Group>

            <div className="mrg-3xl-top align-center">
                <Button
                    className="submit-btn btn-jumbo  inline-block"
                    variant="primary"
                    type="button" 
                    style={{ fontFamily: 'Creamy Coconut', letterSpacing: '2px', fontSize: '25px', width: '25%', background: '#C58151', border: '1px solid #C58151' }}
                    onClick={() => {
                        handleSubmit()
                    }}
                >
                    Send
                </Button>
            </div>
        </Form>
        <Footer />

    </div>

    )
}

export default Contact;