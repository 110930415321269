import { Row } from "antd";
import React from "react";
// import logo from "../../images/logo.png"

import { useHistory } from "react-router-dom";

import {
  Navbar,
  Nav,
  Form,
  FormControl,
  NavDropdown,
  Button,
  NavItem,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Navbar.css";
import { Link } from "react-router-dom";
const NavbarComponent = () => {
  const history = useHistory();

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  };
  //   if (sessionStorage.getItem("username")) {
  //     setTimeout(() => {
  //       alert("Session time out, please sign in again");
  //       logout();
  //     }, 10 * 1000);
  //   }

  let role = localStorage.getItem("userRole");
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        id="header"
        class="header-transparent"
      >
        <Navbar.Brand href="/" style={{ marginLeft: "3%" }}>
          <img
            src={
              "https://cdn.dev.kaiejin.org.au/static-content/Tati Tati Logo.png"
            }
            width="200px"
            height="130px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" navbar-light />
        <Navbar.Collapse id="basic-navbar-nav" navbar-light>
          <Nav className="mr-auto" style={{ marginLeft: "3%" }}>
            {role == "admin" ? (
              // <NavItem className="px-3" >
              //     <Nav.Link
              //         to="/dashboard"
              //         as={Link}
              //         eventKey="0"

              //     >
              <NavDropdown
                className="px-3"
                title="Dashboard"
                id="basic-nav-dropdown"
              >
                {/* <NavDropdown.Item href="">Action</NavDropdown.Item> */}
                <NavItem>
                  <Nav.Link to="/awa" as={Link} eventKey="13">
                    {" "}
                    AWA Insights{" "}
                  </Nav.Link>
                </NavItem>
                <NavItem className="px-4">
                  <Nav.Link to="/supplierspage" as={Link} eventKey="14">
                    {" "}
                    Suppliers{" "}
                  </Nav.Link>
                </NavItem>
                <NavItem className="px-4">
                  <Nav.Link to="/registeredUsers" as={Link} eventKey="15">
                    {" "}
                    Event Registered Users{" "}
                  </Nav.Link>
                </NavItem>
                <NavItem className="px-4">
                  <Nav.Link to="/surveys" as={Link} eventKey="16">
                    {" "}
                    Surveys{" "}
                  </Nav.Link>
                </NavItem>
              </NavDropdown>
            ) : // </Nav.Link>
            // </NavItem>
            // <NavItem className="px-3">
            //     <Nav.Link
            //         to="/dashboard"
            //         as={Link}
            //         eventKey="0"

            //     > Dashboard </Nav.Link></NavItem>
            null}

            {role == "admin" ? (
              //  <NavItem className="px-3">
              //     <Nav.Link to="/users"
              //         as={Link}
              //         eventKey="1"
              //     >

              <NavDropdown
                className="px-3"
                title="Admin"
                id="basic-nav-dropdown"
              >
                {/* <NavDropdown.Item href="">Action</NavDropdown.Item> */}
                <NavItem>
                  <Nav.Link to="/newusers" as={Link} eventKey="10">
                    {" "}
                    New Users{" "}
                  </Nav.Link>
                </NavItem>
                <NavItem className="px-4">
                  <Nav.Link to="/user-management" as={Link} eventKey="11">
                    {" "}
                    User Management{" "}
                  </Nav.Link>
                </NavItem>
                <NavItem className="px-4">
                  <Nav.Link to="/location" as={Link} eventKey="12">
                    {" "}
                    Location{" "}
                  </Nav.Link>
                </NavItem>
              </NavDropdown>
            ) : // </Nav.Link></NavItem>
            null}
            <NavItem className="px-3">
              {" "}
              <Nav.Link to="/" as={Link} eventKey="2">
                {" "}
                Home{" "}
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/resources" as={Link} eventKey="3">
                {" "}
                Resources
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/events" as={Link} eventKey="4">
                Events
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/donations" as={Link} eventKey="5">
                Donations
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/contact" as={Link} eventKey="6">
                Contact Us
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/help" as={Link} eventKey="7">
                Help
              </Nav.Link>
            </NavItem>
            <NavItem className="px-3">
              <Nav.Link to="/suppliers" as={Link} eventKey="8">
                Supplier
              </Nav.Link>
            </NavItem>

            {role == "admin" || role == "registered_user" ? (
              <NavItem className="px-3 logout-btn" onClick={() => logout()}>
                <Nav.Link>Logout</Nav.Link>
              </NavItem>
            ) : null}
            {!role ? (
              <NavItem className="px-3 px-login logout-btn">
                <Nav.Link to="/login" as={Link} eventKey="9">
                  Login/Register
                </Nav.Link>
              </NavItem>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Row>
        {/* <img src={require( "../../images/Margooy.jpg" )} alt="nav" width="100%" height="120px" className="nav-img" /> */}
      </Row>
    </div>
  );
};

export default NavbarComponent;
