import React from 'react'
import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Home.css'

import ReactPlayer from 'react-player'

// Render a YouTube video player

// import "node_modules/video-react/dist/video-react.css";
import { animalFullData, countryFullData, cultureFullData, familyFullData, weatherFullData } from './data'



import TabsImages from './TabsImages';

import WikiList from './WikiList';
import Footer from '../footer/Footer';

class Language extends Component {
    componentDidMount = () => {
        window.scrollTo( 0, 0 )
    }
    render() {
        return (
            <>
                <div className="root-about">
                    <section id="heroLang">
                        <div className="heroLang-container" >
                            <div className="exploreOne">
                                <p className="typoOne">language</p>
                                <h4 className="explore-typo-lan">Tyalingi</h4>
                            </div>
                        </div>
                    </section>
                    <section id="about">
                        <div style={{ background: "rgb(37,36,36 )", overflow: "hidden" }}>
                            <Row style={{ padding: "0px 7%" }}>
                                <Col sm="12" md="12" xs="12" style={{}}>
                                    <div className="card-body" style={{ textAlign: "justify", color: "white" }}>
                                        <p>
                                            Tati Tati, Wadi Wadi, Mutti Mutti, Latji Latji, Nari Nari and Yita Yita languages are being revived through projects and groups such as Tati Tati Kaiejin and Victorian Aboriginal Corporation for Languages. Reviving our language is essential to Tati Tati peoples as it is how our people have communicated since time immemorial the stories of our lore and histories. For First Nations this is about more than just words and a means of communication, they hold meaning and importance over land, animals, Country, and maintain our connection to our ancestors. </p>
                                        <p>


                                            Wangilatha Wangu nga Kiyawatha (Singing Songs and Telling Stories) is based on a book of songs and stories written and illustrated by Brendan Kennedy in Tati Tati, Mutti Mutti, Latji Latji and Wadi Wadi languages of North Western Victoria around Robinvale, and published by the Victorian Aboriginal Corporation for Languages in 2014.
                                        </p>

                                    </div>
                                </Col>

                            </Row>
                            <Row style={{ padding: "0px 7%" }}>
                                {/* <video src="https://d2ttajsrgve1o9.cloudfront.net/video/Gali.mp4">

                            </video> */}
                                <h1 className="section-header">Wangilatha Wangu nga Kiyawatha</h1>
                                <div className="d-flex justify-content-center">
                                    <ReactPlayer
                                        light={"https://cdn.dev.kaiejin.org.au/static-content/eja/monash.jpg"}
                                        controls={true}
                                        // height="500px"
                                        // width="0%"
                                        url="https://d2ttajsrgve1o9.cloudfront.net/video/Wangilatha%20Wangu%20nga%20Kiyawatha%20-%20english%20subtitles.mp4"
                                    />
                                </div>
                            </Row>
                            <Row style={{ padding: "0px 7%" }}>
                                <h1 className="section-header">Our Languages</h1>
                                <p style={{ color: "white" }}>Click for more info</p>
                                <TabsImages />
                            </Row>
                            <Row style={{ padding: "0px 7%" }}>
                                <h1 className="section-header">Gali (dingo) by Brendan Kennedy</h1>

                                <p style={{ color: "white" }}>Gali (dingo) is an animated short story using Nari Nari language, narrated and illustrated by Brendan Kennedy, and edited by Wiradjuri Condobolin Corporation Language Program (WCCLP). </p>
                                <div className="d-flex justify-content-center">
                                    <ReactPlayer
                                        light={"https://cdn.dev.kaiejin.org.au/static-content/eja/gali.jpg"}
                                        controls={true}

                                        url="https://d2ttajsrgve1o9.cloudfront.net/video/Gali.mp4"
                                    />
                                </div>
                                {/* <Player >
                                <source src="https://d2ttajsrgve1o9.cloudfront.net/video/Gali.mp4" />
                            </Player> */}
                            </Row>
                            <Row style={{ padding: "0px 7%" }}>
                                <h1 className="section-header">Language Wiki</h1>

                            </Row>
                            <Row style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                                <Col md="2" lg="2">
                                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }} className="respCenter">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/LGImages/animal.png'}
                                            atl="Animals"
                                            style={{
                                                width: "45%",
                                                // marginLeft: "85px"
                                            }} />


                                    </div>
                                    <div className="img-head" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>Animals</div>

                                    {<WikiList data={animalFullData.slice( 0, 10 )} id="animal" />}


                                </Col>
                                <Col md="2" lg="2">
                                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }} className="respCenter">

                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/LGImages/country.png'} atl="country" style={{
                                            width: "45%",
                                            // marginLeft: "30%"
                                        }} />


                                    </div>
                                    <div className="img-head" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>Country</div>
                                    {<WikiList data={countryFullData.slice( 0, 10 )} id="country" />}



                                </Col>
                                <Col md="2" lg="2">
                                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }} className="respCenter">

                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/LGImages/culture.png'} atl="culture" style={{
                                            width: "50%",
                                            // marginLeft: "85px"
                                        }} />

                                    </div>
                                    <div className="img-head" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>Culture</div>

                                    {<WikiList data={cultureFullData.slice( 0, 10 )} id="culture" />}


                                </Col>
                                <Col md="2" lg="2">
                                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }} className="respCenter">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/LGImages/family.png'} atl="family" style={{
                                            width: "45%",
                                            // marginLeft: "85px"
                                        }} />


                                    </div>
                                    <div className="img-head" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>Family</div>

                                    {<WikiList data={familyFullData.slice( 0, 10 )} id="family" />}



                                </Col>
                                <Col md="2" lg="2">
                                    <div style={{ padding: '10px 0 0px', display: "flex", alignContent: "center", justifyContent: "center" }} className="respCenter">
                                        <img src={'https://cdn.dev.kaiejin.org.au/static-content/LGImages/weather.png'} atl="weather" style={{
                                            width: "45%",
                                            // marginLeft: "85px"
                                        }} />


                                    </div>
                                    <div className="img-head" style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>Weather</div>

                                    {<WikiList data={weatherFullData.slice( 0, 10 )} id="weather" />}


                                </Col>
                            </Row>
                            {/* <Row style={{ padding: "0px 7%", background: "black" }}>
                            <Col sm="12" md="6" xs="12">
                                <img src={background} width="100%" />
                            </Col>
                            <Col sm="12" md="6" xs="12">
                                <h1 style={{ color: "white" }} className="jt-end">Mapping</h1>
                                <div className="card-body-com">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra, habitant euismod pellentesque scelerisque nunc arcu magna sem. Ornare egestas scelerisque elementum, mauris. Consequat amet velit odio eget magna ut quisque. Adipiscing morbi malesuada rhoncus ut dignissim. Purus porta felis nunc turpis. Ultrices mauris bibendum ullamcorper diam faucibus amet, lacus. Habitasse amet, rhoncus, arcu enim pretium. In tellus diam et adipiscing imperdiet enim, id. Neque lorem suscipit tristique in varius sed potenti consequat. At blandit nec nisl egestas tincidunt sed tortor. Varius facilisis sit non aliquet nulla semper. Vulputate nibh nunc rhoncus sed sit posuere metus tortor. Quis tortor habitant tortor lectus hendrerit faucibus malesuada vestibulum sit. Phasellus massa in amet ut.
                                    </p>


                                </div>
                            </Col>
                            <Col sm="12" md="6" xs="12">


                            </Col>



                        </Row>
                        <Row id="portfolio" className="portfolio exploreOne" style={{ padding: "0px 7%" }}>
                            <Col sm="12" md="6" xs="12" className="ma-t-200">
                                <div className="card-body">
                                    <h3 className="card-title" style={{ color: "white" }}>Indigenous Ranger Program</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra, habitant euismod pellentesque scelerisque nunc arcu magna sem. Ornare egestas scelerisque elementum, mauris. Consequat amet velit odio eget magna ut quisque. Adipiscing morbi malesuada rhoncus ut dignissim. Purus porta felis nunc turpis.
                                    </p>
                                </div>
                            </Col>
                            <Col sm="12" md="6" xs="12" className="ma-t-200">
                                <div className="card-body">
                                    <h3 className="card-title" style={{ color: "white" }}>Seasonal Calendar</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra, habitant euismod pellentesque scelerisque nunc arcu magna sem. Ornare egestas scelerisque elementum, mauris. Consequat amet velit odio eget magna ut quisque. Adipiscing morbi malesuada rhoncus ut dignissim. Purus porta felis nunc turpis. </p>
                                </div>

                            </Col>

                        </Row> */}
                        </div>
                    </section >
                </div >
                <Footer />
            </>
        )
    }
}
export default Language;