export const animalFullData = [
    { "lang": "Dulang", "English": "Crow" },
    { "lang": "Dun", "English": "Snake" },
    { "lang": "Kani", "English": "Snake" },
    { "lang": "Karrwingi", "English": "Emu" },
    { "lang": "Karta", "English": "Cockatoo" },
    { "lang": "Kelela", "English": "Cockatoo, major mitchell" },
    { "lang": "Kenti", "English": "Lizard, bearded dragon" },
    { "lang": "Keranggie", "English": "Cockatoo, white" },
    { "lang": "Kunggo", "English": "Kookaburra" },
    { "lang": "Kunuwar", "English": "Swan" },
    { "lang": "Kuran Kuran", "English": "Parrott" },
    { "lang": "Kurangi", "English": "Kangaroo, grey" },
    { "lang": "Kurtuni", "English": "Brolga" },
    { "lang": "Lawani", "English": "Mallee fowl" },
    { "lang": "Likwil", "English": "Echidna" },
    { "lang": "Manyi", "English": "Fish" },
    { "lang": "Mirki", "English": "Egg" },
    { "lang": "Mokonggomi", "English": "Bee" },
    { "lang": "Ngaki", "English": "Goose" },
    { "lang": "Ngana-ngani", "English": "Duck, fringe-bill" },
    { "lang": "Nganuri", "English": "Goanna" },
    { "lang": "Ngarau", "English": "Bustard, wild turkey" },
    { "lang": "Nino", "English": "Frog" },
    { "lang": "Pandyil", "English": "Cod" },
    { "lang": "Parnairi", "English": "Duck, teal" },
    { "lang": "Pathangal", "English": "Pelican" },
    { "lang": "Patyunmum", "English": "Koala" },
    { "lang": "Perner", "English": "Duck, teal" },
    { "lang": "Pirti", "English": "Fly" },
    { "lang": "Pirwi", "English": "Hawk" },
    { "lang": "Tapi", "English": "Pidgeon" },
    { "lang": "Taramang", "English": "Duck" },
    { "lang": "Tulum", "English": "Duck, black" },
    { "lang": "Tyariyn", "English": "Cockatoo, black - red" },
    { "lang": "Tyipel", "English": "Cray fish" },
    { "lang": "Tyutyiwirt", "English": "Bird" },
    { "lang": "waipilla", "English": "Eaglehawk" },
    { "lang": "walakili", "English": "crow" },
    { "lang": "Walap", "English": "Lizard, blue tongue" },
    { "lang": "Wangi", "English": "Crow" },
    { "lang": "Wilengi", "English": "Possum, Brush Tail" },
    { "lang": "Wilik Wilik", "English": "Galah" },
    { "lang": "Wirak gintha", "English": "Lizard, jew" },
    { "lang": "Wirani", "English": "Cockatoo, black - yellow" },
    { "lang": "Wiringul", "English": "Golden perch" },
    { "lang": "Wirmal", "English": "Owl" },
    { "lang": "Wirpil", "English": "Eagle" },
    { "lang": "Wirrengan", "English": "Dog" },
    { "lang": "Wogwoi", "English": "Possum" },
    { "lang": "Yapi", "English": "Yabbie" },
    { "lang": "Yukun", "English": "Lizard, shingle back" }
]
export const cultureFullData = [
    { "lang": "Lurngi", "English": "Camp" },
    { "lang": "Yungkuwi", "English": "Canoe" },
    { "lang": "Wiriwa", "English": "Dance" },
    { "lang": "Tyakila", "English": "Eat" },
    { "lang": "Tiperai", "English": "Fishing" },
    { "lang": "Thambu", "English": "Grave" },
    { "lang": "Puringa", "English": "Healthy" },
    { "lang": "Lundhal", "English": "Hut, camp" },
    { "lang": "Purpang", "English": "Initiation ceremony" },
    { "lang": "Tyalingi", "English": "Lang" },
    { "lang": "Nhari", "English": "Name" },
    { "lang": "Waranga", "English": "Sing" },
    { "lang": "Tyawi", "English": "Song" },
    { "lang": "Weria", "English": "Speak, to" },
    { "lang": "Ngartangi", "English": "Spirit" },
    { "lang": "Wiriya", "English": "Swim" },
    { "lang": "Yana", "English": "Walk" }
]
export const familyFullData = [
    { "lang": "Kirtapin Kangowu", "English": "Family" },
    { "lang": "Kulingi", "English": "Community" },
    { "lang": "Kulkurn", "English": "Man, Young" },
    { "lang": "Kuluwur", "English": "Woman, Old" },
    { "lang": "Layur", "English": "Woman, Young" },
    { "lang": "Mami", "English": "Father" },
    { "lang": "Mangii", "English": "Daughter" },
    { "lang": "Murungur", "English": "Girl" },
    { "lang": "Ngamaloruwe", "English": "Ancestor" },
    { "lang": "Nyarambin", "English": "Man, Old" },
    { "lang": "Painggu", "English": "Boy" },
    { "lang": "Papu", "English": "Mother" },
    { "lang": "Pupu", "English": "Baby" },
    { "lang": "Wirtiwu", "English": "Son" }
]
export const countryFullData = [
    { "lang": "Tilayn", "English": "Berry, edible" },
    { "lang": "Kurna", "English": "Branch" },
    { "lang": "Walpa", "English": "Burn, to" },
    { "lang": "Wirya", "English": "Far" },
    { "lang": "Wanapi", "English": "Fire" },
    { "lang": "Parnimi", "English": "Food" },
    { "lang": "Wulungi", "English": "Grass" },
    { "lang": "Thangi", "English": "Ground" },
    { "lang": "Karinga", "English": "Grow" },
    { "lang": "Kalkupan", "English": "Gypsum" },
    { "lang": "Purpu", "English": "Hill" },
    { "lang": "Kirinyu", "English": "Leaf" },
    { "lang": "Pilpi", "English": "Mud" },
    { "lang": "Yanap", "English": "Myall (tree)" },
    { "lang": "Murung", "English": "Pine, Murray River (tree)" },
    { "lang": "Wuyndang", "English": "Ponds, chain of" },
    { "lang": "Pithikal", "English": "Quandong" },
    { "lang": "Piyal", "English": "Red Gum (tree)" },
    { "lang": "Parniwaru", "English": "River/Creek" },
    { "lang": "Kuyu parniwaru", "English": "River/Creek - dry" },
    { "lang": "Ngariyalu", "English": "Root, of plant" },
    { "lang": "Pungur", "English": "Rush (plant)" },
    { "lang": "Kawi", "English": "Salt" },
    { "lang": "Kuraki", "English": "Sand" },
    { "lang": "Murndia", "English": "Shrub (plant)" },
    { "lang": "Merkin", "English": "Spring (of water)" },
    { "lang": "Purwalangu", "English": "Stem" },
    { "lang": "Kulk", "English": "Stick" },
    { "lang": "Maki", "English": "Stone" },
    { "lang": "Tulu", "English": "Stump" },
    { "lang": "Totolil", "English": "Swamp/Lake" },
    { "lang": "Mityu", "English": "Tree bark" },
    { "lang": "Lapur", "English": "Tuber (of Water Lily)" },
    { "lang": "Tumtumu", "English": "Valley" },
    { "lang": "Kayani", "English": "Water" },
    { "lang": "Yalam", "English": "Waterhole, well" },
    { "lang": "Kalki", "English": "Wood" },
    { "lang": "Kalki warnawi", "English": "(Fire)Wood" },
    { "lang": "Tumtumu", "English": "Valley" }
]
export const weatherFullData = [
    { "lang": "Paki", "English": "Air" },
    { "lang": "Murnggi", "English": "Cloud" },
    { "lang": "Mirinyuma", "English": "Cold" },
    { "lang": "Terariwil", "English": "Comet" },
    { "lang": "Ngawingi", "English": "Day" },
    { "lang": "Karta", "English": "East" },
    { "lang": "Pityan", "English": "Flood" },
    { "lang": "Pongeru", "English": "Flood" },
    { "lang": "Tenangi", "English": "Frost, ice" },
    { "lang": "Partowi", "English": "Hail" },
    { "lang": "Karti", "English": "Heat" },
    { "lang": "Waingi", "English": "Light" },
    { "lang": "Tuluwi", "English": "Lightning" },
    { "lang": "Putyima", "English": "Meteor" },
    { "lang": "Mityan", "English": "Moon" },
    { "lang": "Murnmurnepu", "English": "Morning" },
    { "lang": "Purungi", "English": "Night, dark" },
    { "lang": "Moiwilak", "English": "North" },
    { "lang": "Mirti", "English": "Rain" },
    { "lang": "Dirawuru", "English": "Rainbow" },
    { "lang": "Tirili", "English": "Sky" },
    { "lang": "Tyalan", "English": "South" },
    { "lang": "Turti", "English": "Stars" },
    { "lang": "Tyarwangi", "English": "Storm" },
    { "lang": "Ngawingi", "English": "Sun" },
    { "lang": "Purong Kutyal", "English": "Sunrise" },
    { "lang": "Purtowen Ngawi", "English": "Sunset" },
    { "lang": "Mundar", "English": "Thunder" },
    { "lang": "Kili Nhawi", "English": "Today" },
    { "lang": "Marnmarnapu", "English": "Tomorrow" },
    { "lang": "Worka - worka", "English": "Twilight" }
    ,
    { "lang": "Nanga", "English": "Warm" },
    { "lang": "Tyalup", "English": "West" },
    { "lang": "Wilangi", "English": "Wind" },
    { "lang": "Karalko", "English": "Yesterday" }
]
