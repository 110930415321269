import Axios from 'axios'
import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, FormGroup, FormControl } from 'react-bootstrap'
import { PayPalButton } from "react-paypal-button-v2";
import {Input}from 'antd'
import PhoneInput from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import './EventReg.css'
import Footer from '../footer/Footer';
const EventRegisteration = () => {
    const [data, setData] = useState( {
        firstName: "",
        lastName: "",
        address: "",
        suburb: "",
        state: "",
        postalCode: 0,
        phone: "",
        email: "",
        isIndigenous: false
    } )
    const location = useLocation()
    const handleSubmit = () => {
        const { id, title } = location.state
        console.log( "id", id, title )
        let dataPrep = {
            first_name: data.firstName,
            last_name: data.lastName,
            address: data.address,
            isIndigenous: data.isIndigenous,
            eventId: id,
            eventTitle: title,
            phone: data.phone,
            email: data.email
        }
        if ( data.phone ) {

            let phonenumber = ""
            if ( data.phone.charAt( 0 ) != 0 ) {
                phonenumber = "+61" + data.phone
            } else {
                phonenumber = "+61" + data.phone.slice( 1 );
            }
            Axios.post( `${ process.env.REACT_APP_API_BASE }/event-registeration`, { ...dataPrep, phone: phonenumber } ).then( rec => {
                if ( rec ) {
                    setData({
                        firstName:"",
                        lastName:"",
                        address:"",
                        phone:"",
                        email:"",
                        isIndigenous:false
                    })
                    alert( "Successfully registered" )
                }
            } ).catch( e => {
                alert( "Error while registering", e )

            } )
        } else {
            alert( "please Enter Phone number" )
        }
        console.log( "e", title )
    }
    const handleChange = ( e ) => {
        const name = e.target.name
        setData( {
            ...data,
            [name]: e.target.value
        } )
    }
    return (
        <>
        <Container>
            <div style={{ width: "100%", padding: "5%" ,minHeight:"100vh"}}>
                <div className="card-body">
                    <h1 className="card-title">Event Registration Form</h1>
                    <Form >
                        <Row className="mb-3">
                            <Col sm="12" md="6" lg="6">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.firstName}
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        onChange={( e ) => handleChange( e )}
                                    // style={{fontFamily:"open sans"}}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={data.lastName}
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <FormGroup className="mb-3" controlId="formGridAddress1">

                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={data.email}
                                        placeholder="Enter Email"
                                        name="email"
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <Form.Group >

                                    <Form.Label
                                        className="label-clr" style={{ fontSize: '20px', letterSpacing: '2px' }}
                                    >Phone Number<span style={{ color: 'red' }}> *</span></Form.Label>
                                    {/* <Form.Control
                    type="number"
                    className="form-control"
                    value={data.phone}
                    onChange={
                        ( e ) => { setData( { ...data, phone: e.target.value } ) }
                    }
                /> */}
                                    <Input
                                        addonBefore="+61"
                                        value={data.phone}
                                        onChange={( e ) => {
                                            if ( e.target.value.length > 10 ) {
                                                alert( "You could not add more then 10 digits" )
                                            } else {
                                                setData( {
                                                    ...data,
                                                    phone: e.target.value
                                                } )
                                            }
                                        }}
                                    />


                                </Form.Group>
                            </Col>
                        </Row>

                        <FormGroup className="mb-6" controlId="formGridAddress1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                placeholder="1234 Main St"
                                value={data.address}
                                name="address"
                                onChange={( e ) => handleChange( e )}
                            />
                        </FormGroup>

                        {/* 
                        <Row className="mb-3">
                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridCity">
                                    <Form.Label>Suburb/Town</Form.Label>
                                    <Form.Control
                                        name="suburb"
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridState">
                                    <Form.Label>State/Territory</Form.Label>
                                    <FormControl
                                        defaultValue="Select State"

                                        as="select"
                                        name="state"
                                        onChange={( e ) => handleChange( e )}
                                    >
                                        <option>Select State</option>
                                        <option>QLD</option>
                                        <option>NT</option>
                                        <option>NSW</option>
                                        <option>SA</option>
                                        <option>VIC</option>
                                        <option>WA</option>

                                    </FormControl>
                                </FormGroup>
                            </Col>

                            <Col sm="12" md="4" xs="12">
                                <FormGroup as={Col} controlId="formGridZip">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        name="postalCode"
                                        onChange={( e ) => handleChange( e )}

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        */}
                        <FormGroup className="mb-3" id="formGridCheckbox" style={{ marginTop: "20px" }}>
                            <Form.Check
                                type="checkbox"
                                label="Indigenous"
                                value={data.isIndigenous}
                                onChange={( e ) => {
                                    setData( { ...data, isIndigenous: e.target.checked } )
                                }}
                            />
                        </FormGroup>
                        {/* {!data.isIndigenous ? <PayPalButton
                            amount="20"
                            shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                            onSuccess={( details, data ) => {
                                console.log( "Transaction completed by " , details,data );

                                // OPTIONAL: Call your server to save the transaction
                                return fetch( "https://localhost/paypal-transaction-complete", {
                                    method: "post",
                                    body: JSON.stringify( {
                                        orderID: data.orderID
                                    } )
                                } );
                            }}
                        /> : null} */}

                        <Button
                            variant="primary"
                            style={{ fontFamily: 'Creamy Coconut', letterSpacing: '2px', fontSize: '25px', width: '25%', background: '#C58151', border: '1px solid #C58151' }}
                            onClick={( e ) => handleSubmit()}
                        >
                            Submit
                        </Button>
                    </Form>

                </div>
            </div>

        </Container>
          <Footer />

          </>
    )
}
export default EventRegisteration